
export const COMPLETElogo = (data) => {
    return({ type: "COMPLETElogo", payload: data });
};

export const COMPLETEicon = (data) => {
    return({ type: "COMPLETEicon", payload: data });
};

export const COMPLETEaddlogo = (data) => {
    return({ type: "COMPLETEaddlogo", payload: data });
};
