import { useState } from "react"
import TasksManag from "./TasksManag"
import PaysManag from "./PaysManag"
import RefReady from "../../global/ref"

const Organizer = () => {

    const [nav, setNav] = useState(0)

    return <div>

        {nav === 0 && <div>
            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>ארגונית אישית</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            
            <div className="ribuha">
                    <div className="inRibuha">רשימת מטלות</div>
                    <div className="inRibuhaDown"><input type="button" value="ערוך" onClick={() => {setNav(1)}} style={{backgroundColor: 'white', minWidth: 'max-content', width:'150px', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor:'pointer', fontWeight:'300', display:'inline-block', marginTop:'3vh' }}/></div>
                </div>

                <div className="ribuha">
                    <div className="inRibuha">ארגון הוצאות</div>
                    <div className="inRibuhaDown"><input type="button" value="ערוך" onClick={() => {setNav(2)}} style={{backgroundColor: 'white', minWidth: 'max-content', width:'150px', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor:'pointer', fontWeight:'300', display:'inline-block', marginTop:'3vh' }}/></div>
                </div>
            </div>}
        {nav === 1 && <TasksManag></TasksManag>}
        {nav === 2 && <PaysManag></PaysManag>}


    </div>
}

export default Organizer