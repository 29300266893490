import { CloseOutlined } from "@ant-design/icons"
import RefReady from "../../ref"
import ButtonRed from "../../button/button"
import { useState } from "react"
import { ChangePassBussiness, SendEmailToBussiness } from "../../../axios/api"
import { useDispatch, useSelector } from "react-redux"
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { CompleteThisBussiness } from "../../../redux/actions/thisBussinessActions"

const EditBussiness = (prop) => {
    const [errorMessage, setErrorMessage] = useState(false)
    const [prevPass, setPrevPass] = useState()
    const [newPass, setNewPass] = useState()
    const s = useSelector((state) => state.thisBussinessReducer)
    const d = useDispatch()

    const handlePassChange = (e, func) => {
        const inputValue = e.target.value;
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            func(inputValue)
        }
    };

    const submit1 = () => {
        debugger
        if (Number(prevPass) === Number(s.code)) {
            setErrorMessage(false)
            ChangePassBussiness(s.categoryCode, s.id, newPass).then((data) => {debugger
                if (data === true) {
                    var b = s
                    b.code = newPass
                    d(CompleteThisBussiness(b))
                    var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום ל${b.name} </p></div><br/> <br/> <b>סיסמתכם החדשה היא: </b> <br/> ${newPass} <br/> <br/>  <b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                    SendEmailToBussiness({ name: "", emailTo: b.mail, emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - שינוי סיסמת מנהל', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                    prop.set(false)
                }
            }).catch((error) => { console.error(error); });
        }
        else {
            setErrorMessage('סיסמה ישנה שגויה, לא ניתן לבצע את הפעולה.')
        }
    }

    return <div id='modal'>
        <div id='formCon' className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close' onClick={() => prop.set(false)} style={{ cursor: 'pointer', position: 'absolute', left: '1.5%', marginTop: '1.5%' }} />

            {prop.num === 1 && <form>
                <div style={{ fontSize: '150%', paddingTop: '2%' }}>שינוי סיסמה</div>
                <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#FF7D7D', marginLeft: 'auto', marginRight: 'auto', marginTop: '-1px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <div style={{ fontSize: '110%', paddingTop: '4%' }}>הכנס סיסמה ישנה</div>
                <input className='input111' style={{ textAlign: 'center' }} autoComplete="on" type="text" value={prevPass} onChange={(e) => handlePassChange(e, setPrevPass)} />
                <div style={{ fontSize: '110%', paddingTop: '2%' }}>הכנס סיסמה חדשה</div>
                <input className='input111' style={{ textAlign: 'center' }} autoComplete="on" type="text" value={newPass} onChange={(e) => {if (e.target.value.length <= 9) {handlePassChange(e, setNewPass)}}} />
                <div onClick={() => submit1()} className='buttonRed' style={{ width: '20%', display: 'inline-block', position: 'absolute', bottom: '-5%', backgroundColor: 'white', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', left: '10%', border: '#FF7D7D 5px solid' }}><ButtonRed text={'אישור'}></ButtonRed></div>
            </form>}


            {/* {prop.num === 2 && <form>
                <div style={{ fontSize: '150%', paddingTop: '2%' }}>{s.reminers ? 'ביטול' : 'אישור'} קבלת תזכורות</div>
                <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#FF7D7D', marginLeft: 'auto', marginRight: 'auto', marginTop: '-1px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <div style={{ position: 'absolute', right: '32%', width: '50%', color: 'white', marginTop: '8%', fontSize: '120%' }}><label className="checkbox-container"><input type="checkbox" className="checkbox1" id='checkboxx' defaultChecked={s.reminders === 1 ? true : false} /><span style={{ width: '30px', height: '30px', padding: '5px' }} className="checkbox-custom">&#10003;</span> &nbsp; &nbsp;  הננו מאשרים קבלת תזכורות למייל.</label></div><br></br>
                <div onClick={() => submit2()} className='buttonRed' style={{ width: '20%', display: 'inline-block', position: 'absolute', bottom: '-5%', backgroundColor: 'white', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', left: '10%', border: '#FF7D7D 5px solid' }}><ButtonRed text={'אישור'}></ButtonRed></div>
            </form>}


            {prop.num === 3 && <form>
                <div style={{ fontSize: '150%', paddingTop: '2%' }}>תאריך האירוסין ותאריך החתונה</div>
                <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#FF7D7D', marginLeft: 'auto', marginRight: 'auto', marginTop: '-1px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <div className='container2'>
                    <div style={{ margin: '5%' }}>
                        <div style={{ marginTop: '5%', padding: '10%' }}>תאריך האירוסין</div>
                        <input className='input4' type="date" style={{ backgroundColor: 'gray', padding: '8%', width: '120%', marginRight: '-10%' }} value={dates.erusinDate} onChange={(e) => { setDates({ ...dates, erusinDate: e.target.value }) }} />
                    </div>
                    <div style={{ margin: '5%' }}>
                        <div style={{ marginTop: '5%', padding: '10%' }}>תאריך החתונה</div>
                        <input className='input4' type="date" style={{ backgroundColor: 'gray', padding: '8%', width: '120%', marginRight: '-10%' }} value={dates.weddingDate} onChange={(e) => { setDates({ ...dates, weddingDate: e.target.value }) }} />
                    </div>
                </div>
                <div onClick={() => submit3()} className='buttonRed' style={{ width: '20%', display: 'inline-block', position: 'absolute', bottom: '-5%', backgroundColor: 'white', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', left: '10%', border: '#FF7D7D 5px solid' }}><ButtonRed text={'אישור'}></ButtonRed></div>
            </form>} */}
            {errorMessage && <p style={{ position: 'absolute', bottom: '2%', width: '20%', right: '40%' }}><div style={{ marginLeft: '2%', color: '#FFC000', display: 'inline-block', marginTop: '-10px' }}><AnnouncementIcon /></div>{errorMessage}</p>}

        </div>
    </div>
}

export default EditBussiness