// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picUp {
    width: 5rem;
    height: 5rem;
    margin-right: auto;
    margin-left: auto;
}

.choozePic {
    height: 3rem;
    /* position: absolute; */
    /* margin-top: 1rem; */
    margin-right: 1rem;
    border-radius: 0%;
    background-color: rgb(230, 227, 227);
    border: none;
}

.choozePic:hover {
    border: #c00000 1px solid;
    color: #c00000;
    color:#820909;
    background-color: rgb(216, 193, 193)
}

.searchRightBlack {
    transition: 1s;
    cursor: pointer;
    z-index: 500;
}

.searchBlack:focus {
    outline: none;
}

.divButton2 {
    margin-right: auto;
    margin-left: auto;
    background-color: #555;
}`, "",{"version":3,"sources":["webpack://./src/components/managger/Managger.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,wBAAwB;IACxB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb;AACJ;;AAEA;IACI,cAAc;IACd,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".picUp {\r\n    width: 5rem;\r\n    height: 5rem;\r\n    margin-right: auto;\r\n    margin-left: auto;\r\n}\r\n\r\n.choozePic {\r\n    height: 3rem;\r\n    /* position: absolute; */\r\n    /* margin-top: 1rem; */\r\n    margin-right: 1rem;\r\n    border-radius: 0%;\r\n    background-color: rgb(230, 227, 227);\r\n    border: none;\r\n}\r\n\r\n.choozePic:hover {\r\n    border: #c00000 1px solid;\r\n    color: #c00000;\r\n    color:#820909;\r\n    background-color: rgb(216, 193, 193)\r\n}\r\n\r\n.searchRightBlack {\r\n    transition: 1s;\r\n    cursor: pointer;\r\n    z-index: 500;\r\n}\r\n\r\n.searchBlack:focus {\r\n    outline: none;\r\n}\r\n\r\n.divButton2 {\r\n    margin-right: auto;\r\n    margin-left: auto;\r\n    background-color: #555;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
