import { styled } from 'styled-components';
// import styled from '@emotion/styled';
import './CardPic.css'
import { useNavigate } from 'react-router-dom';
import ButtonRed from '../button/button';
import RefReady from '../ref';
import { useDispatch } from 'react-redux';
import { CompleteThisBussiness } from '../../redux/actions/thisBussinessActions';
import { useEffect, useState } from 'react';

const ImageDiv = styled.div`
        background-image: url(${props => props.pic}); 
    `;

const CardPic = (props) => {

    const navigate = useNavigate()
    const d = useDispatch()
    const [city, setCity] = useState([])

    useEffect(() => {
        if (props.bussiness.city)
            setCity(props.bussiness.city.split(',.'))
    }, [])


    return <div className='card100'
        style={{ userSelect: 'none', position: 'relative' }}
    // style={{backgroundColor:`${props.i%4==0? '#FFB7B7' : props.i%2==0? 'white' : props.i%3==0? '#FF8989': 'black'}`,color:`${props.i%2==0? 'black' : props.i%3==0? 'black': 'white'}`}}
    >
        <div className='buton text' onClick={() => { d(CompleteThisBussiness(props.bussiness)); navigate(`/bussiness/${props.categoryName}/${props.name}/${props.categoryCode}`) }}>
            <ButtonRed text={props.bussiness.name}></ButtonRed></div>

        <RefReady tag={<span style={{ position: 'relative' }}>
            <ImageDiv pic={`data:image/jpeg;base64,${props.pic}`} onClick={() => { d(CompleteThisBussiness(props.bussiness)); navigate(`/bussiness/${props.categoryName}/${props.name}/${props.categoryCode}`) }} className='image'>פרטים נוספים<div className='border2'></div>
                <div className='border3'></div><div className='black'>פרטים נוספים</div></ImageDiv></span>
        } event={'animate__fadeInDown'} event2={'animate__faster'}></RefReady>

        {/* ---------------------------------------------אולם------------------------------------------------ */}
        {(props.categoryCode == '1' || props.categoryCode == '82') && <RefReady tag={<div className={props.i % 2 === 0 ? 'containeNew con' : 'containeNew'}>
            <div className='center' style={{paddingRight:'5%'}}>{props.bussiness.size}</div>
            <div className='center'>{props.bussiness.place}</div>
            <div className='center'>{props.bussiness.price}</div>
            <div className='center'>{props.bussiness.adventage}</div>
            <div className='border'></div>
        </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

        {/* ---------------------------------------------חנויות------------------------------------------------ */}
        {props.categoryCode !== '1' && props.categoryCode !== '3' && props.categoryCode !== '82' && props.categoryCode !== '2' && props.categoryCode !== '4' && <RefReady tag={<div className={props.i % 2 === 0 ? 'containeNew con' : 'containeNew'}>
            <div className='center' style={{paddingRight:'5%'}}>{props.bussiness.bonus}</div>
            <div className='center'>{city.map((x, i) => (<div key={i}>{x}</div>))}</div>
            <div className='center'>{props.bussiness.openingHours}</div>
            <div className='center'>{props.bussiness.adventage}</div>
            <div className='border'></div>
        </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

        {/* ---------------------------------------------תזמורת------------------------------------------------ */}
        {props.categoryCode == '2' && <RefReady tag={<div className={props.i % 2 === 0 ? 'containeNew con' : 'containeNew'}>
            <div className='center' style={{paddingRight:'5%'}}>{props.bussiness.price} ש"ח</div>
            <div className='center'>{props.bussiness.name}</div>
            <div className='center'>{props.bussiness.area}</div>
            <div className='center'>{props.bussiness.signon}</div>
            <div className='border'></div>
        </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

        {/* ---------------------------------------------קייטרינג------------------------------------------------ */}
        {props.categoryCode == '4' && <RefReady tag={<div className={props.i % 2 === 0 ? 'containeNew con' : 'containeNew'}>
            <div className='center' style={{paddingRight:'5%'}}>מספר מנות סעודה:<br></br> {props.bussiness.rangeDishes}</div>
            <div className='center'>מספר מנות קבלת פנים:<br></br> {props.bussiness.rangeDishesKabalat}</div>
            <div className='center'>טווח מחיר למנה בין:<br></br> {props.bussiness.rangePrice} ש"ח</div>
            <div className='center'>{props.bussiness.area}</div>
            <div className='border'></div>
        </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

        {/* ---------------------------------------------צילום------------------------------------------------ */}
        {props.categoryCode == '3' && <RefReady tag={<div className={props.i % 2 === 0 ? 'containeNew con' : 'containeNew'}>
            <div className='center' style={{paddingRight:'5%'}}>חבילת צילום רגילה:<br></br> {props.bussiness.rangePrice} ש"ח</div>
            <div className='center'>{props.bussiness.rangeDishes}</div>
            <div className='center'>{props.bussiness.area}</div>
            <div className='center'>{props.bussiness.adventage}</div>
            <div className='border'></div>
        </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

        {/* <RefKav tag={<div className='kav'></div>}></RefKav> */}

    </div>
}

export default CardPic

// מאגר הדיגיטל - Pixabay
// אתר התמונות Unsplash
// מאגר התמונות והסרטונים – Pixeles
// אתר StockSnap
// מאגר תמונות Freepik
// האתר PicJumbo
// סטוק תמונות StockVault
// מאגר תמונות להורדה – FreeImages
// אתר Gratisography
// מאגר התמונות החופשי PublicDomainPhoto
// מאגר התמונות Burst מבית Shopify
