import { useEffect, useRef, useState } from "react";
import { AddBussinessCatering, AddBussinessShop, AddBussinessSong, AddBussinessWall, GetAllCategoriesOrDevition, GetBussinessData, GetQuestions, SendEmailToBussiness } from "../../../axios/api";
import './AddToDataBase.css'
import OnScroll from "../../../global/onScroll/OnScroll";
import ButtonRed from "../../../global/button/button";
import { ArrowRightOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { COMPLETEthisBussinessWithoutBussinessData, CompleteBussinessData, CompleteThisBussiness } from "../../../redux/actions/thisBussinessActions";
import Wait from "../../../global/Wait";
import Calender from "../../../global/bussiness/Calender/Calender";
import { HDate } from 'hebcal';
import pic2 from '../../../../pic/logo/444.jpg'
import picError from '../../../../pic/animations/113.gif'
import { COMPLETEaddlogo } from "../../../redux/actions/iconAndLogoActions";
import Alert from "../../../global/alert/Alert";


const AddToDataBase = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const p = useParams()
    const d = useDispatch()
    const n = useNavigate()
    const [wait, setWait] = useState();

    const [address, setAddress] = useState([]);
    const [city, setCity] = useState([]);
    const [finishGood, setFinishGood] = useState(false);


    const [top100Films, setTop100Films] = useState();
    useEffect(() => {
        GetAllCategoriesOrDevition().then((data) => {
            setTop100Films(Array.from(new Set(data.map(obj => obj.name))).map(name => data.find(obj => obj.name === name)))
        }).catch((error) => { console.error(error); });
    }, []);

    const [num, setNum] = useState(0)
    const [egul, setEgul] = useState(0);
    useEffect(() => {
        if (egul < 6 && p.bool === 'false') {
            for (let index = 0; index < 4; index++) {
                document.getElementsByClassName("egul")[index].style.backgroundColor = 'white'
            }
            if (questiuons?.length > 0) {
                for (let index = 4; index >= egul; index--) {
                    document.getElementsByClassName("ckav")[index].classList.remove('glo1')
                    document.getElementsByClassName("egul")[index].classList.remove('glo2')
                }
                for (let index = 0; index < 5; index++) {
                    document.getElementsByClassName("egul")[index].style.backgroundColor = 'white'
                }
            }
            else {
                for (let index = 3; index >= egul; index--) {
                    document.getElementsByClassName("ckav")[index].classList.remove('glo1')
                    document.getElementsByClassName("egul")[index].classList.remove('glo2')
                }
                for (let index = 0; index < 4; index++) {
                    document.getElementsByClassName("egul")[index].style.backgroundColor = 'white'
                }
            }
            if (egul > 0 && egul <= document.getElementsByClassName("egul").length) {
                document.getElementsByClassName("egul")[egul - 1].style.backgroundColor = 'rgb(209, 179, 179)'
                document.getElementsByClassName("ckav")[egul - 1]?.classList.add('glo1')
            }
            setTimeout(() => { document.getElementsByClassName("egul")[egul - 1]?.classList.add('glo2'); }, 200)
        }
    }, [egul]);

    const [newBusiness, setNewBusiness] = useState([
        { code: '', name: '', description: '', categoryCode: '', mainImage: '', adventage: '', bonus: '', openingHours: '', address: '', phone: '', mail: '', logo: '', city: '', priceShipping: -1, notesShipping: '', kishurLahatar: '', phoneToCall: '', howManySeeYou: 0, howManySendEmail: 0, howManyCall: 0, keywords: '' },//חנות רגילה
        { code: '', name: '', description: '', categoryCode: '', mainImage: '', adventage: '', place: '', size: '', price: '', phone: '', mail: '', logo: '', includePrice: '', city: '', kishurLahatar: '', phoneToCall: '', howManySeeYou: 0, howManySendEmail: 0, howManyCall: 0, keywords: '' },//אולם
        { code: '', name: '', description: '', categoryCode: '', mainImage: '', adventage: '', priceList: '', rangeDishes: '', rangeDishesKabalat: '', rangePrice: '', mail: '', logo: '', phone: '', area: '', kishurLahatar: '', phoneToCall: '', howManySeeYou: 0, howManySendEmail: 0, howManyCall: 0, keywords: '' },//קייטריג
        { code: '', name: '', description: '', categoryCode: '', mainImage: '', price: '', priceAdd: '', phone: '', mail: '', logo: '', includePrice: '', signon: '', area: '', kishurLahatar: '', phoneToCall: '', howManySeeYou: 0, howManySendEmail: 0, howManyCall: 0, keywords: '' }//שירים
    ]);

    const [numBusiness, setNumBusiness] = useState(0);
    const [nameCategory, setNameCategory] = useState(0);
    const [allLists, setAllLists] = useState({ options: [], images: [], answers: [], lotOfItems: [], songs: [], dates: [] });
    const [mainImage, setMainImage] = useState()
    const [errorMessage, setErrorMessage] = useState('');
    const [CategoryCode, setCategoryCode] = useState();
    const [moreCategories, setMoreCategories] = useState([]);

    const s = useSelector((state) => state.thisBussinessReducer)
    var numBussiness2 = 0

    useEffect(() => {
        if (p.bool !== 'false' && top100Films && num !== 1) {
            debugger
            setNum(p.bool === '3' ? 3 : p.bool === '4' ? 70 : p.bool === '10' ? 70 : p.bool === '11' ? 70 : p.bool === '5' ? 70 : 1)
            handlePhoneNumberChange(s.categoryCode, 'categoryCode')
            if (numBussiness2 === 0) {
                debugger
                var newSaddress = s.address, newScity = s.city
                var Saddress = s.address.split(',.'), Scity = s.city.split(',.')
                var ad = [], ci = []
                for (let i = 1; i < Saddress.length || i < Scity.length; i++) {
                    ad.push(Saddress[i])
                    ci.push(Scity[i])
                }
                setAddress(ad)
                setCity(ci)
                var newS = s; newS.address = newSaddress; newS.city = newScity;
                setNewBusiness(prev => { const arr = prev; arr[numBussiness2] = s; arr[numBussiness2].address = Saddress[0]; arr[numBussiness2].city = Scity[0]; return arr; })
                d(COMPLETEthisBussinessWithoutBussinessData(newS))
            }
            else
                setNewBusiness(prev => { const arr = prev; arr[numBussiness2] = s; return arr; })

            if (s.bussinessData) {
                setAllLists(s.bussinessData)
                setLotItems(s.bussinessData.lotOfItems)
                setMishlochim(s.priceShipping >= 0 ? true : false)
                setAnswers(s.bussinessData.answers)
                setMainImage(s.mainImage)
                debugger
                if (s.bussinessData.dates.length > 0) {
                    var arr = s.bussinessData.dates
                    // s.bussinessData.dates = []
                    arr.sort((a, b) => a.date1 > b.date1 ? 1 : -1)
                    for (let i = 0; i < arr.length; i++) { arr[i].date1 = arr[i].date1.split('T')[0] }
                    for (let i = 0; i < 4; i++) { arr.push({ date1: '', categoryCode: newBusiness[numBusiness].code, bussinessId: 0 }) }
                    setDates(arr)
                }
            }

        }
    }, [top100Films]);

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 40) {
            if (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') {
                setNewBusiness((prevBusiness) => {
                    const updatedBusiness = [...prevBusiness];
                    updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], name: inputValue };
                    return updatedBusiness;
                });
            }
        }
    };

    const handlePriceAdd = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 100) {
            setNewBusiness((prevBusiness) => {
                const updatedBusiness = [...prevBusiness];
                updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], priceAdd: inputValue };
                return updatedBusiness;
            });
        }
    };

    const handleCityChange = (e) => {
        const inputValue = e.target.value;
        setNewBusiness((prevBusiness) => {
            const updatedBusiness = [...prevBusiness];
            updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], city: inputValue };
            return updatedBusiness;
        });
    };

    const handleChange = (e, field, length) => {
        const inputValue = e.target.value;
        if (inputValue.length <= length) {
            setNewBusiness((prevBusiness) => {
                const updatedBusiness = [...prevBusiness];
                updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], [field]: inputValue };
                return updatedBusiness;
            });
        }
    };

    const handlePhoneNumberChange = (e, field) => {
        var inputValue, buss = numBusiness
        if (field === "categoryCode") {
            var top100 = top100Films.filter(a => a.id == e)[0]
            if (top100.titleId) {
                var arr = top100Films.filter(a => a.code == top100.code)
                setMoreCategories(arr)
            }
            setNameCategory(top100.name)
            setCategoryCode(top100.code)
            top100.code == 1 ? buss = 1 : top100.code == 2 ? buss = 3 : top100.code == 3 ? buss = 2 : top100.code == 4 ? buss = 2 : top100.code == 82 ? buss = 1 : buss = 0;
            setNumBusiness(buss)
            numBussiness2 = buss
            inputValue = top100.code;
        }
        else {
            inputValue = e.target.value;
        }

        if (field !== "phone" && field !== "phoneToCall" && inputValue !== "")
            inputValue = parseInt(inputValue)


        if (field === "code") {
            if (inputValue > 999999999)
                inputValue = newBusiness[numBusiness].code
        }
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setNewBusiness((prevBusiness) => {
                const updatedBusiness = [...prevBusiness];
                updatedBusiness[buss] = { ...updatedBusiness[buss], [field]: inputValue };
                return updatedBusiness;
            });
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        if (/^[א-תA-Za-z@.0-9]+$/.test(inputValue) || inputValue === '') {
            setNewBusiness((prevBusiness) => {
                const updatedBusiness = [...prevBusiness];
                updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], mail: inputValue };
                return updatedBusiness;
            });
        }
    };

    const getBussinessData = () => {
        GetBussinessData(s.id, s.categoryCode).then((data) => {
            for (let i = 0; i < data.options.length; i++) {
                data.options[i].numDates = Math.floor((new Date() - new Date(data.options[i].date)) / (24 * 60 * 60 * 1000))
            }
            for (let i = 0; i < data.answers.length; i++) {
                data.answers[i].questionCon = data.answers[i].answer1.split('.,!')[0];
                data.answers[i].answerCon = data.answers[i].answer1.split('.,!')[1];
            }
            d(CompleteBussinessData(data))
        }).catch((error) => { console.error(error); });
    }

    const handleSubmit = (e) => { debugger
        e.preventDefault();
        setErrorMessage('');
        if (newBusiness[numBusiness].code === '' || newBusiness[numBusiness].name === '' || newBusiness[numBusiness].description === '' || newBusiness[numBusiness].categoryCode === '' || newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].city === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '') {
            setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה');
        } else {

            if (!/^\d{10}$/.test(newBusiness[numBusiness].phone)) {
                setErrorMessage('מספר נייד לא תקין');
                return;
            }

            else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness[numBusiness].mail)) {
                setErrorMessage('כתובת מייל לא תקינה');
                return;
            }

            else {
                setWait(true)
                var b = newBusiness[numBusiness], ppp = [];
                b.mainImage = mainImage;
                const cc = b.categoryCode

                if (CategoryCode === 3) {
                    b.rangePrice = String(b.rangePrice)
                    b.rangeDishesKabalat = String(b.rangeDishesKabalat)

                }

                allLists?.images?.forEach(element => {
                    if (element.picture == undefined)
                        ppp.push({ picture: element, categoryCode: cc, bussinessId: 0 })
                    else
                        ppp.push(element)

                });


                var lot = lotItems
                var answNo = answers, answ = []
                debugger
                if (p.bool !== 'false') {
                    delete b.bussinessData
                    for (let i = 0; i < lot?.length; i++) {
                        delete lot[i].detailsShoppings
                    }
                    for (let i = 0; i < answNo?.length; i++) {
                        debugger
                        if (answNo[i].answerCon !== '' && answNo[i].answerCon !== undefined) {
                            answNo[i].answer1 = answNo[i].answerCon
                            delete answNo[i].answerCon
                            delete answNo[i].questionCon
                            answ.push(answNo[i])
                        }
                    }
                }
                else {
                    var ans = []
                    for (let index = 0; index < answ?.length; index++) {
                        if (answ.answer1) {
                            ans.push({ ...answ[index], categoryCode: cc })
                        }
                    }
                    answ = ans
                }

                for (let index = 0; index < lot?.length; index++) {
                    lot[index].categoryCode = cc
                    lot[index].code = String(lot[index].code)
                    lot[index].price = Number(lot[index].price)
                    lot[index].bussinessId = 0
                }

                var son = allLists?.songs
                for (let index = 0; index < allLists?.songs?.length; index++) {
                    if (numBusiness !== 2)
                        son[index].categoryCode = cc
                    else {
                        if (!allLists.songs[index].song1)
                            son[index] = { song1: allLists.songs[index], bussinessId: 0, categoryCode: cc, name: null }
                    }
                }

                if (p.bool !== 'false') {
                    for (let index = 0; index < dates.length; index++) {
                        dates[index].bussinessId = s.id
                    }
                }

                for (let i = 0; i < address.length; i++) {
                    if (address[i]?.length > 0 || city[i]?.length > 0) {
                        if (b.city.length + city[i].length < 98)
                            b.city = b.city + ',.' + city[i]
                        if (b.address.length + address[i].length < 148)
                            b.address = b.address + ',.' + address[i]
                    }
                }

                debugger
                if (b.logo !== "")
                    d(COMPLETEaddlogo(b.logo))

                var ready = { bussiness: b, all: { options: [], images: ppp, answers: answ, lotOfItems: lot, songs: son, dates: dates.filter(a => a.date1 !== '0000-00-00' && a.date1 !== '') } };

                if (p.bool === '11') {
                    var op = ''
                    if (moreCategories.length > 0)
                        for (let i = 0; i < moreCategories.length; i++) {
                            op += `<b>תחום ${i + 1}:</b> ${moreCategories[i].name}. <br/>`
                        } else { op = categoryName }
                    var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק קיים התחבר למערכת הטלפונית,  <br/> נא לחבר אותו בהקדם - הוא כבר התחיל לשלם. <br/> <br/>  <b>שם העסק: </b> ${newBusiness[numBusiness].name}.<br/>  <b>תחומים: </b> ${op}. <br/> <b>מספר טלפון: </b> ${newBusiness[numBusiness].phone}<br/> <b>כתובת מייל: </b> ${newBusiness[numBusiness].mail}<br/><br/> <b>מספר הטלפון אותו לחבר למערכת הטלפונית: </b><br/> ${newBusiness[numBusiness].phoneToCall}<br/><br/>  לאחר בנית השלוחה - נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר המערכת הטלפונית שלנו + מספר השלוחה שלו  <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                    SendEmailToBussiness({ name: "", emailTo: 'r0527117663@gmail.com', emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - לקוח קיים התחבר למערכת הטלפונית - נא להוסיף', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                }


                if (numBusiness == 0) {
                    AddBussinessShop(ready).then((data) => {
                        var op = ''
                        if (moreCategories.length > 0)
                            for (let i = 0; i < moreCategories.length; i++) {
                                op += `<b>תחום ${i + 1}:</b> ${moreCategories[i].name}. <br/>`
                            } else { op = categoryName }
                        if (p.codeCategory === '-2' || p.codeCategory === '-3') {
                            var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק חדש הצטרף למערכת הטלפונית,  <br/> נא לחבר אותו בהקדם - הוא כבר התחיל לשלם. <br/> <br/>  <b>שם העסק: </b> ${newBusiness[numBusiness].name}.<br/>  <b>תחומים: </b> ${op}. <br/> <b>מספר טלפון: </b> ${newBusiness[numBusiness].phone}<br/> <b>כתובת מייל: </b> ${newBusiness[numBusiness].mail}<br/><br/> <b>מספר הטלפון אותו לחבר למערכת הטלפונית: </b><br/> ${newBusiness[numBusiness].phoneToCall}<br/><br/>  לאחר בנית השלוחה - נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר המערכת הטלפונית שלנו + מספר השלוחה שלו  <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "", emailTo: 'r0527117663@gmail.com', emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - לקוח חדש התחבר למערכת הטלפונית - נא להוסיף', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                        }
                        if (p.bool !== 'false') {
                            d(CompleteThisBussiness({...data, numberToCall: s.numberToCall}))
                            getBussinessData()
                            n(-1)
                        }
                        else {
                            setFinishGood(true)
                            //שליחת מייל לעסק שהצטרף בהצלחה
                            var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;">${newBusiness[numBusiness].name} - הצטרפתם בהצלחה למיזם קול שמחה</p></div><br/> <br/> כעת אתם חלק מהמאגר העצום של כל נותני השירות בתחום האירועים  <br/><br/> <b>קוד ניהול העסק שלכם הוא:<br/> ${newBusiness[numBusiness].code} </b><br/> <div style="width: 50%; margin-right:25%;"> באפשרותכם לערוך את פרטי העסק שלכם, להחליף תמונות, לשנות כיתובים, ועוד...<br/>כנסו לעמוד העסק שלכם ולחצו על הלוגו של העסק בפינה השמאלית למעלה,<br/>לאחר מכן תידרשו להכניס את קוד הניהול, ותתחילו לערוך.. <br/> <br/> הצטרפתם לתחומים הבאים: </div>${op} <br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "", emailTo: newBusiness[numBusiness].mail, emailFrom: 'r0527117663@gmail.com', subject: `${newBusiness[numBusiness].name} - הצטרפתם בהצלחה למיזם קול שמחה`, message: mes }).then(() => { }).catch((error) => { console.error(error); });
                            setTimeout(() => {
                                n(`/allCards/${newBusiness[numBusiness].categoryCode}/${categoryName}/${'הצטרפות לעסקים'}`)
                            }, 20000);
                        }
                    }).catch((error) => { console.error(error); setWait(false); window.scrollTo(0, 0); });
                }
                else if (numBusiness == 1) {
                    AddBussinessWall(ready).then((data) => {
                        var op = ''
                        if (moreCategories.length > 0)
                            for (let i = 0; i < moreCategories.length; i++) {
                                op += `<b>תחום ${i + 1}:</b> ${moreCategories[i].name}. <br/>`
                            } else { op = categoryName }
                        if (p.codeCategory === '-2' || p.codeCategory === '-3') {
                            var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק חדש הצטרף למערכת הטלפונית,  <br/> נא לחבר אותו בהקדם - הוא כבר התחיל לשלם. <br/> <br/>  <b>שם העסק: </b> ${newBusiness[numBusiness].name}.<br/>  <b>תחומים: </b> ${op}. <br/> <b>מספר טלפון: </b> ${newBusiness[numBusiness].phone}<br/> <b>כתובת מייל: </b> ${newBusiness[numBusiness].mail}<br/><br/> <b>מספר הטלפון אותו לחבר למערכת הטלפונית: </b><br/> ${newBusiness[numBusiness].phoneToCall}<br/><br/>  לאחר בנית השלוחה - נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר המערכת הטלפונית שלנו + מספר השלוחה שלו  <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "", emailTo: 'r0527117663@gmail.com', emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - לקוח חדש התחבר למערכת הטלפונית - נא להוסיף', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                        }
                        if (p.bool !== 'false') {
                            d(CompleteThisBussiness({...data, numberToCall: s.numberToCall}))
                            getBussinessData()
                            n(-1)
                        }
                        else {
                            setFinishGood(true)
                            //שליחת מייל לעסק שהצטרף בהצלחה
                            var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;">${newBusiness[numBusiness].name} - הצטרפתם בהצלחה למיזם קול שמחה</p></div><br/> <br/> כעת אתם חלק מהמאגר העצום של כל נותני השירות בתחום האירועים  <br/><br/> <b>קוד ניהול העסק שלכם הוא:<br/> ${newBusiness[numBusiness].code} </b><br/><div style="width: 50%; margin-right:25%;">באפשרותכם לערוך את פרטי העסק שלכם, להחליף תמונות, לשנות כיתובים, ועוד...<br/>כנסו לעמוד העסק שלכם ולחצו על הלוגו של העסק בפינה השמאלית למעלה,<br/>לאחר מכן תידרשו להכניס את קוד הניהול, ותתחילו לערוך.. <br/> הצטרפתם לתחומים הבאים:</div> ${op} <br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "", emailTo: newBusiness[numBusiness].mail, emailFrom: 'r0527117663@gmail.com', subject: `${newBusiness[numBusiness].name} - הצטרפתם בהצלחה למיזם קול שמחה`, message: mes }).then(() => { }).catch((error) => { console.error(error); });
                            setTimeout(() => {
                                n(`/allCards/${newBusiness[numBusiness].categoryCode}/${categoryName}/${'הצטרפות לעסקים'}`)
                            }, 20000);
                        }
                    }).catch((error) => { console.error(error); setWait(false); window.scrollTo(0, 0); });
                }
                else if (numBusiness == 2) {
                    AddBussinessCatering(ready).then((data) => {
                        var op = ''
                        if (moreCategories.length > 0)
                            for (let i = 0; i < moreCategories.length; i++) {
                                op += `<b>תחום ${i + 1}:</b> ${moreCategories[i].name}. <br/>`
                            } else { op = categoryName }
                        if (p.codeCategory === '-2' || p.codeCategory === '-3') {
                            var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק חדש הצטרף למערכת הטלפונית,  <br/> נא לחבר אותו בהקדם - הוא כבר התחיל לשלם. <br/> <br/>  <b>שם העסק: </b> ${newBusiness[numBusiness].name}.<br/>  <b>תחומים: </b> ${op}. <br/> <b>מספר טלפון: </b> ${newBusiness[numBusiness].phone}<br/> <b>כתובת מייל: </b> ${newBusiness[numBusiness].mail}<br/><br/> <b>מספר הטלפון אותו לחבר למערכת הטלפונית: </b><br/> ${newBusiness[numBusiness].phoneToCall}<br/><br/>  לאחר בנית השלוחה - נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר המערכת הטלפונית שלנו + מספר השלוחה שלו  <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "", emailTo: 'r0527117663@gmail.com', emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - לקוח חדש התחבר למערכת הטלפונית - נא להוסיף', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                        }
                        if (p.bool !== 'false') {
                            d(CompleteThisBussiness({...data, numberToCall: s.numberToCall}))
                            getBussinessData()
                            n(-1)
                        }
                        else {
                            setFinishGood(true)
                            //שליחת מייל לעסק שהצטרף בהצלחה
                            var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;">${newBusiness[numBusiness].name} - הצטרפתם בהצלחה למיזם קול שמחה</p></div><br/> <br/> כעת אתם חלק מהמאגר העצום של כל נותני השירות בתחום האירועים  <br/><br/> <b>קוד ניהול העסק שלכם הוא:<br/> ${newBusiness[numBusiness].code} </b><br/><div style="width: 50%; margin-right:25%;">באפשרותכם לערוך את פרטי העסק שלכם, להחליף תמונות, לשנות כיתובים, ועוד...<br/>כנסו לעמוד העסק שלכם ולחצו על הלוגו של העסק בפינה השמאלית למעלה,<br/>לאחר מכן תידרשו להכניס את קוד הניהול, ותתחילו לערוך.. <br/> <br/> הצטרפתם לתחומים הבאים: </div>${op} <br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "", emailTo: newBusiness[numBusiness].mail, emailFrom: 'r0527117663@gmail.com', subject: `${newBusiness[numBusiness].name} - הצטרפתם בהצלחה למיזם קול שמחה`, message: mes }).then(() => { }).catch((error) => { console.error(error); });
                            setTimeout(() => {
                                n(`/allCards/${newBusiness[numBusiness].categoryCode}/${categoryName}/${'הצטרפות לעסקים'}`)
                            }, 20000);
                        }
                    }).catch((error) => { console.error(error); setWait(false); window.scrollTo(0, 0); });
                }
                else {
                    ready.bussiness.price = String(ready.bussiness.price)
                    AddBussinessSong(ready).then((data) => {
                        var op = ''
                        if (moreCategories.length > 0)
                            for (let i = 0; i < moreCategories.length; i++) {
                                op += `<b>תחום ${i + 1}:</b> ${moreCategories[i].name}. <br/>`
                            } else { op = categoryName }
                        if (p.codeCategory === '-2' || p.codeCategory === '-3') {
                            var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק חדש הצטרף למערכת הטלפונית,  <br/> נא לחבר אותו בהקדם - הוא כבר התחיל לשלם. <br/> <br/>  <b>שם העסק: </b> ${newBusiness[numBusiness].name}.<br/>  <b>תחומים: </b> ${op}. <br/> <b>מספר טלפון: </b> ${newBusiness[numBusiness].phone}<br/> <b>כתובת מייל: </b> ${newBusiness[numBusiness].mail}<br/><br/> <b>מספר הטלפון אותו לחבר למערכת הטלפונית: </b><br/> ${newBusiness[numBusiness].phoneToCall}<br/><br/>  לאחר בנית השלוחה - נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר המערכת הטלפונית שלנו + מספר השלוחה שלו  <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "", emailTo: 'r0527117663@gmail.com', emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - לקוח חדש התחבר למערכת הטלפונית - נא להוסיף', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                        }
                        if (p.bool !== 'false') {
                            d(CompleteThisBussiness({...data, numberToCall: s.numberToCall}))
                            getBussinessData()
                            n(-1)
                        }
                        else {
                            setFinishGood(true)
                            //שליחת מייל לעסק שהצטרף בהצלחה
                            var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;">${newBusiness[numBusiness].name} - הצטרפתם בהצלחה למיזם קול שמחה</p></div><br/> <br/> כעת אתם חלק מהמאגר העצום של כל נותני השירות בתחום האירועים  <br/><br/> <b>קוד ניהול העסק שלכם הוא:<br/> ${newBusiness[numBusiness].code} </b><br/> <div style="width: 50%; margin-right:25%;">באפשרותכם לערוך את פרטי העסק שלכם, להחליף תמונות, לשנות כיתובים, ועוד...<br/>כנסו לעמוד העסק שלכם ולחצו על הלוגו של העסק בפינה השמאלית למעלה,<br/>לאחר מכן תידרשו להכניס את קוד הניהול, ותתחילו לערוך.. <br/> <br/> הצטרפתם לתחומים הבאים:</div> ${op} <br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "מיזם קול שמחה", emailTo: newBusiness[numBusiness].mail, emailFrom: 'r0527117663@gmail.com', subject: `${newBusiness[numBusiness].name} - הצטרפתם בהצלחה למיזם קול שמחה`, message: mes }).then(() => { }).catch((error) => { console.error(error); });
                            setTimeout(() => {
                                n(`/allCards/${newBusiness[numBusiness].categoryCode}/${categoryName}/${'הצטרפות לעסקים'}`)
                            }, 20000);
                        }
                    }).catch((error) => { console.error(error); setWait(false); window.scrollTo(0, 0); });
                }




            }
        }
    };


    // ----------------------start ref--------------------------
    const ref2 = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible3')
                }
            });
        });

        if (ref2.current) { observer.observe(ref2.current); }
        return () => {
            if (ref2.current) { observer.unobserve(ref2.current); }
        };
    }, []);
    // ----------------------end ref--------------------------


    // ----------------------begin picture--------------------------

    const [openPic, setOpenPic] = useState(false)

    useEffect(() => {
        if (num == 3) {
            const fileList = document.getElementById("fileList");
            if (!newBusiness[numBusiness].logo)
                fileList.innerHTML = "<p>עדיין לא נבחרה תמונת לוגו!</p>";
            else {
                fileList.innerHTML = "";
                const img = document.createElement("img");
                img.src = `data:image/jpeg;base64,${newBusiness[numBusiness].logo}`
                img.height = 200;
                img.onload = () => {
                    URL.revokeObjectURL(img.src);
                };
                fileList.appendChild(img);
            }

            const fileList1 = document.getElementById("fileList1");
            if (!allLists.images.length && !newBusiness[numBusiness].mainImage)
                fileList1.innerHTML = "<p>עדיין לא נבחרו תמונות!</p>";
            else {
                fileList1.innerHTML = "";
                if (newBusiness[numBusiness].mainImage) {
                    const img = document.createElement("img");
                    img.src = `data:image/jpeg;base64,${newBusiness[numBusiness].mainImage}`;
                    img.height = 200;
                    img.onload = () => {
                        URL.revokeObjectURL(img.src);
                    };
                    fileList1.appendChild(img);
                }
                for (let i = 0; i < allLists.images.length; i++) {
                    const img = document.createElement("img");
                    img.src = p.bool !== 'false' ? `data:image/jpeg;base64,${allLists.images[i].picture}` : `data:image/jpeg;base64,${allLists.images[i]}`;
                    img.height = 200;
                    img.onload = () => {
                        URL.revokeObjectURL(img.src);
                    };
                    fileList1.appendChild(img);
                }
            }

            const fileList2 = document.getElementById("fileList30");
            if (!mainImage && p.bool === 'false')
                fileList2.innerHTML = "<p>עדיין לא נבחרה תמונה!</p>";
            else {
                fileList2.innerHTML = "";
                const img = document.createElement("img");
                img.src = p.bool !== 'false' ? `data:image/jpeg;base64,${newBusiness[numBusiness].mainImage}` : `data:image/jpeg;base64,${mainImage}`
                img.height = 200;
                img.onload = () => {
                    URL.revokeObjectURL(img.src);
                };
                fileList2.appendChild(img);
            }
        }
        if (num == 2 && numBusiness == 2 && CategoryCode !== 3) {
            const fileList1 = document.getElementById("fileList1");
            if (!allLists.songs.length)
                fileList1.innerHTML = "<p>עדיין לא נבחרה תמונה!</p>";
            else {
                fileList1.innerHTML = "";
                for (let i = 0; i < allLists.songs.length; i++) {
                    const img = document.createElement("img");
                    img.src = p.bool !== 'false' ? `data:image/jpeg;base64,${allLists.songs[i].song1}` : `data:image/jpeg;base64,${allLists.songs[i]}`;
                    img.height = 200;
                    img.onload = () => {
                        URL.revokeObjectURL(img.src);
                    };
                    fileList1.appendChild(img);
                }
            }
        }
        if (num == 1 && numBusiness == 2) {
            const fileList1 = document.getElementById("fileList");
            if (newBusiness[numBusiness].priceList.length <= 0)
                fileList1.innerHTML = "<p>עדיין לא נבחרה תמונה!</p>";
            else {
                fileList1.innerHTML = "";
                const img = document.createElement("img");
                img.src = `data:image/jpeg;base64,${newBusiness[numBusiness].priceList}`
                img.height = 200;
                img.onload = () => {
                    URL.revokeObjectURL(img.src);
                };
                fileList1.appendChild(img);
            }
        }
        if (num == 4 && numBusiness == 3) {
            const fileList22 = document.getElementById("fileList1");
            if (!allLists.songs.length) {
                fileList22.innerHTML = "<p>עדיין לא נבחרו שירים!</p>";
            } else {
                fileList22.innerHTML = "";
                for (let i = 0; i < allLists.songs.length; i++) {
                    const div = document.createElement("div");
                    div.classList.add('songs')
                    div.style.width = '60%'

                    const audio = document.createElement("audio");
                    audio.controls = true;
                    audio.src = `data:audio/mp3;base64,${allLists.songs[i].song1}`
                    audio.style.marginTop = 'auto'
                    audio.style.marginBottom = 'auto'

                    audio.onload = () => {
                        URL.revokeObjectURL(audio.src);
                    };

                    const info = document.createElement("span");
                    info.innerHTML = `${allLists.songs[i].name}`;
                    div.appendChild(info);
                    div.appendChild(audio);
                    fileList22.appendChild(div);
                }
            }
        }
    }, [num, openPic]);

    useEffect(() => {
        const fileList12 = document.getElementById("fileList12");
        if (openPic == 1 || openPic == 2) {
            if (!allLists.images.length && !newBusiness[numBusiness].mainImage)
                fileList12.innerHTML = "<p>עדיין לא נבחרו תמונות!</p>";
            else {
                fileList12.innerHTML = "";
                if (newBusiness[numBusiness].mainImage) {
                    const img = document.createElement("img");
                    img.src = `data:image/jpeg;base64,${newBusiness[numBusiness].mainImage}`;
                    img.height = 200;
                    img.onload = () => {
                        URL.revokeObjectURL(img.src);
                    };
                    fileList12.appendChild(img);
                }
                for (let i = 0; i < allLists.images.length; i++) {
                    const img = document.createElement("img");
                    img.src = p.bool !== 'false' ? `data:image/jpeg;base64,${allLists.images[i].picture}` : `data:image/jpeg;base64,${allLists.images[i]}`;
                    img.height = 200;
                    img.name = i
                    if (openPic === 1)
                        img.addEventListener("click", clickMainPic);
                    else
                        img.addEventListener("click", clickDelPic);
                    img.classList.add('remove')
                    img.onload = () => {
                        URL.revokeObjectURL(img.src);
                    };
                    fileList12.appendChild(img);
                }

            }
        }
        else if (openPic == 4) {
            fileList12.innerHTML = "";
            for (let i = 0; i < allLists.songs.length; i++) {
                const img = document.createElement("img");
                img.src = p.bool !== 'false' ? `data:image/jpeg;base64,${allLists.songs[i].song1}` : `data:image/jpeg;base64,${allLists.songs[i]}`;
                img.height = 200;
                img.name = i
                img.addEventListener("click", clickDelPic);
                img.classList.add('remove')
                img.onload = () => {
                    URL.revokeObjectURL(img.src);
                };
                fileList12.appendChild(img);
            }
        }
        else if (openPic == 3) {
            if (!allLists.songs.length)
                fileList12.innerHTML = "<p>עדיין לא נבחרו שירים!</p>";
            else {
                fileList12.innerHTML = "";
                for (let i = 0; i < allLists.songs.length; i++) {
                    const div = document.createElement("div");
                    div.classList.add('songs')
                    div.style.width = '100%'
                    div.name = i
                    div.classList.add('remove2')
                    div.addEventListener("click", clickDelSong);
                    const audio = document.createElement("audio");
                    audio.controls = true;
                    audio.src = `data:audio/mp3;base64,${allLists.songs[i].song1}`
                    audio.style.marginTop = 'auto'
                    audio.style.marginBottom = 'auto'

                    audio.onload = () => {
                        URL.revokeObjectURL(audio.src);
                    };

                    const info = document.createElement("span");
                    info.innerHTML = `${allLists.songs[i].name}`;
                    info.name = i
                    div.appendChild(info);
                    div.appendChild(audio);
                    fileList12.appendChild(div);
                }
            }
        }
    }, [openPic]);

    const clickPic = (e) => {
        const fileSelect = document.getElementById("fileSelect");
        const fileElem = document.getElementById("fileElem");
        fileSelect.addEventListener("click", fileElem.click());
    }

    // const changePic = async (e) => {
    //     const fileList = document.getElementById("fileList");
    //     var field = num == 3 ? "logo" : "priceList";
    //     if (!e.target.files.length) {
    //         fileList.innerHTML = "<p>עדיין לא נבחרה תמונת לוגו!</p>";
    //         setNewBusiness((prevBusiness) => {
    //             const updatedBusiness = [...prevBusiness];
    //             updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], [field]: '' };
    //             return updatedBusiness;
    //         });
    //     } else {
    //         const file = e.target.files[0];
    //         fileList.innerHTML = "";
    //         const img = document.createElement("img");
    //         img.src = URL.createObjectURL(file);

    //         const fileReader = new FileReader();
    //         fileReader.onload = fileLoadedEvent => {
    //             const base64ImageAll = fileLoadedEvent.target.result;
    //             const base64Image = base64ImageAll.split(',')[1]
    //             setNewBusiness((prevBusiness) => {
    //                 const updatedBusiness = [...prevBusiness];
    //                 updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], [field]: base64Image };
    //                 return updatedBusiness;
    //             });
    //         };
    //         fileReader.readAsDataURL(file);

    //         img.height = 200;
    //         img.onload = () => {
    //             URL.revokeObjectURL(img.src);
    //         };
    //         fileList.appendChild(img);
    //     }
    // }

    function resizeImageTo300Pixels(numSize, inputImage, funcKod, field) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image4 = new Image();
        image4.src = URL.createObjectURL(inputImage);
        image4.onload = function () {
            canvas.width = numSize;
            canvas.height = (numSize * image4.height) / image4.width;
            ctx.drawImage(image4, 0, 0, numSize, (numSize * image4.height) / image4.width);
            const resizedImage = canvas.toDataURL('image/png');
            const base64Image = resizedImage.split(',')[1]
            funcKod(field, base64Image)
        };
    }

    const changePic = async (e) => {
        const fileList = document.getElementById("fileList");
        var field = num == 3 ? "logo" : "priceList";
        if (!e.target.files.length) {
            fileList.innerHTML = "<p>עדיין לא נבחרה תמונת לוגו!</p>";
            setNewBusiness((prevBusiness) => {
                const updatedBusiness = [...prevBusiness];
                updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], [field]: '' };
                return updatedBusiness;
            });
        } else {
            const filePrev = e.target.files[0];
            fileList.innerHTML = "";
            const img = document.createElement("img");
            img.src = URL.createObjectURL(filePrev);

            const funcK = (field, base64Image) => {
                setNewBusiness((prevBusiness) => {
                    const updatedBusiness = [...prevBusiness];
                    updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], [field]: base64Image };
                    return updatedBusiness;
                });
            }
            resizeImageTo300Pixels(800, filePrev, funcK, field)

            img.height = 200;
            img.onload = () => {
                URL.revokeObjectURL(img.src);
            };
            fileList.appendChild(img);
        }
    }

    const clickPics = (e) => {
        const fileSelect = document.getElementById("fileSelect1");
        const fileElem = document.getElementById("fileElem1");
        fileSelect.addEventListener("click", fileElem.click()
            // (e) => { if (fileElem) { fileElem.click(); } },
            // false,
        );
    }

    const changePics = (e) => {
        debugger
        const selectedFile = document.getElementById("fileElem1").files;
        const fileList = document.getElementById("fileList1");
        var field = num == 3 ? "images" : "songs";
        if (num == 3 || num == 2) {
            var pics = []
            pics = num == 3 ? allLists.images : allLists.songs;

            if (!selectedFile.length && !pics.length && num == 2) {
                fileList.innerHTML = "<p>עדיין לא נבחרה תמונה!</p>";
                setAllLists({ ...allLists, [field]: undefined })
            }
            else if (!selectedFile.length && !pics.length && num == 3 && !newBusiness[numBusiness].mainImage) {
                debugger
                fileList.innerHTML = "<p>עדיין לא נבחרו תמונות!</p>";
                setAllLists({ ...allLists, [field]: undefined })
            }
            else {
                if (!pics.length) {
                    fileList.innerHTML = "";
                    if (newBusiness[numBusiness].mainImage) {
                        const img = document.createElement("img");
                        img.src = `data:image/jpeg;base64,${newBusiness[numBusiness].mainImage}`;
                        img.height = 200;
                        img.onload = () => {
                            URL.revokeObjectURL(img.src);
                        };
                        fileList.appendChild(img);
                    }
                }
                var length = num == 2 ? 2 : num == 3 && newBusiness[numBusiness].mainImage ? 1 : 0
                for (let i = 0; i < selectedFile.length; i++) {
                    if (pics.length + length + i < 5) {
                        const img = document.createElement("img");
                        img.src = URL.createObjectURL(selectedFile[i]);

                        const funcK = (field, base64Image) => {
                            p.bool !== 'false' ? pics.push({ picture: base64Image }) : pics.push(base64Image)
                        }
                        resizeImageTo300Pixels(800, selectedFile[i], funcK, field)

                        img.height = 200;
                        img.onload = () => {
                            URL.revokeObjectURL(img.src);
                        };
                        fileList.appendChild(img);
                    }
                    setAllLists({ ...allLists, [field]: pics })
                }

            }
        }
        else {
            if (!selectedFile.length && !allLists.songs.length) {
                fileList.innerHTML = "<p>עדיין לא נבחרו שירים!</p>";
                setAllLists({ ...allLists, songs: undefined })
            } else {
                if (!allLists.songs.length) {
                    fileList.innerHTML = "";
                }
                var son = allLists.songs
                for (let i = 0; i < selectedFile.length; i++) {
                    if (allLists.songs.length + i < 3 && selectedFile[i].size < 4150000) {
                        const div = document.createElement("div");
                        div.classList.add('songs')
                        div.style.width = '60%'

                        const audio = document.createElement("audio");
                        audio.controls = true;
                        audio.style.marginTop = 'auto'
                        audio.style.marginBottom = 'auto'

                        const fileReader = new FileReader();
                        fileReader.onload = fileLoadedEvent => {
                            const base64ImageAll = fileLoadedEvent.target.result;
                            const base64Image = base64ImageAll.split(',')[1]
                            son.push({ song1: base64Image, categoryCode: '', bussinessId: 0, name: selectedFile[i].name })
                            audio.src = `data:audio/mp3;base64,${base64Image}`
                        };
                        fileReader.readAsDataURL(selectedFile[i]);

                        audio.onload = () => {
                            URL.revokeObjectURL(audio.src);
                        };

                        const info = document.createElement("span");
                        info.innerHTML = `${selectedFile[i].name}`;
                        div.appendChild(info);
                        div.appendChild(audio);
                        fileList.appendChild(div);
                    }
                    setAllLists({ ...allLists, songs: son })
                }
            }
        }
    }

    var iimmgg = null;

    const clickMainPic = (e) => {
        iimmgg = Number(e.target.name)
        const fileList = document.getElementById("fileList13");
        const img = document.createElement("img");
        img.src = p.bool !== 'false' ? `data:image/jpeg;base64,${allLists.images[iimmgg].picture}` : `data:image/jpeg;base64,${allLists.images[iimmgg]}`;
        img.height = 200;
        img.onload = () => {
            URL.revokeObjectURL(img.src);
        };
        while (fileList.firstChild) {
            fileList.removeChild(fileList.firstChild);
        }
        fileList.appendChild(img);
    }

    const clickDelPic = (e) => {
        var arr = iimmgg
        if (iimmgg == null) {
            arr = []
        }
        if (!arr.includes(Number(e.target.name))) {
            arr.push(Number(e.target.name))
            arr.sort((a, b) => a - b);
            iimmgg = arr
            // setIimmgg([...iimmgg, Number(e.target.name)])
            const fileList = document.getElementById("fileList13");
            const img = document.createElement("img");
            if (openPic === 4)
                img.src = p.bool !== 'false' ? `data:image/jpeg;base64,${allLists.songs[Number(e.target.name)].song1}` : `data:image/jpeg;base64,${allLists.songs[Number(e.target.name)]}`;
            else
                img.src = p.bool !== 'false' ? `data:image/jpeg;base64,${allLists.images[Number(e.target.name)].picture}` : `data:image/jpeg;base64,${allLists.images[Number(e.target.name)]}`;
            img.height = 200;
            img.onload = () => {
                URL.revokeObjectURL(img.src);
            };
            fileList.appendChild(img);
        }
    }

    const chooseMainPic = () => {
        const fileList = document.getElementById("fileList30");
        if (iimmgg !== null) {
            fileList.innerHTML = ""
            var mainPic = p.bool !== 'false' ? allLists.images[iimmgg].picture : allLists.images[iimmgg]
            if (newBusiness[numBusiness].mainImage) {
                var arr = allLists;
                arr.images.push(newBusiness[numBusiness].mainImage)
                setAllLists(arr)
            }
            setMainImage(mainPic);
            setNewBusiness(prev => { const arr = prev; arr[numBusiness].mainImage = mainPic; return arr; })
            var arr = allLists;
            arr.images.splice(iimmgg, 1);
            setAllLists(arr)
            const img = document.createElement("img");
            img.src = `data:image/jpeg;base64,${mainPic}`;
            img.height = 200;
            img.onload = () => {
                URL.revokeObjectURL(img.src);
            };
            // while (fileList.firstChild) {
            //     fileList.removeChild(fileList.firstChild);
            // }
            fileList.appendChild(img);
            iimmgg = null
        }
        // else {
        //     fileList.innerText = 'לא נבחרה תמונה ראשית'
        // }
        setOpenPic(false)
    }

    const chooseDelPic = () => {
        // var list = allLists
        var iii = iimmgg
        // if (p.bool === 'false') {
        //     for (var i = iimmgg.length - 1; i >= 0; i--) {
        //         if (openPic === 4)
        //             allLists.songs.splice(iimmgg[i], 1);
        //         else
        //             allLists.images.splice(iimmgg[i], 1);
        //     }
        // }
        // else {
        setAllLists(prev => {
            const arr = prev;
            for (var i = iii.length - 1; i >= 0; i--) {
                if (openPic === 3 || openPic === 4)
                    arr.songs.splice(iii[i], 1);
                else
                    arr.images.splice(iii[i], 1);
            }
            iii = [];
            return arr;
        })
        // }

        // setAllLists(false)
        // setTimeout(() => {
        //     setAllLists(list)
        // }, 10);
        iimmgg = null
        setOpenPic(false)
    }

    const clickDelSong = (e) => {
        var arr = iimmgg
        if (iimmgg == null) {
            arr = []
        }
        if (!arr.includes(Number(e.target.name))) {
            arr.push(Number(e.target.name))
            arr.sort((a, b) => a - b);
            iimmgg = arr
            // setIimmgg([...iimmgg, Number(e.target.name)])
            const fileList = document.getElementById("fileList13");
            const div = document.createElement("div");
            div.classList.add('songs')
            div.style.width = '15%'
            div.style.height = '10vh'
            div.style.lineHeight = '3vh'
            div.style.display = 'inline-block'
            div.style.marginLeft = '1%'

            const info = document.createElement("span");
            info.innerHTML = `${allLists.songs[Number(e.target.name)].name}`;
            info.style.paddingTop = '20%'
            div.appendChild(info);
            // div.appendChild(audio);
            fileList.appendChild(div);
        }
    }

    // ----------------------end picture--------------------------

    const prev = () => {
        if (num == 6)
            setNum(questiuons?.length > 0 ? 5 : numBusiness === 3 || p.codeCategory === '-3' ? 4 : 3)
        else
            setNum(num - 1)
        setEgul(prev => prev - 1)
    }

    const enter = () => {
        setNum(num + 1)
        setEgul(prev => prev + 1)
    }


    //--------------------------------------------------------------

    const [lotItems, setLotItems] = useState([])

    const clickPicMutsar = (e, num) => {
        const fileSelect = document.getElementsByClassName("fileSelectMutsar")[num];
        const fileElem = document.getElementsByClassName("fileElemMutsar")[num];
        fileSelect.addEventListener("click", fileElem.click());
    }

    const changePicMutsar = async (e, i) => {
        if (!e.target.files.length) {
            const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, picture: '' }; } return item; }); setLotItems(updatedLotItems)
        }
        else {
            const file = e.target.files[0];
            const funcK = (field, base64Image) => {
                const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, picture: base64Image }; } return item; });
                setLotItems(updatedLotItems)
            }
            resizeImageTo300Pixels(800, file, funcK, 1)
        }
    }

    const [questiuons, setQuestions] = useState();
    const [answers, setAnswers] = useState([]);
    useEffect(() => {
        if (num == 2 && !questiuons) {
            var id;
            if (newBusiness[numBusiness].categoryCode === '') {
                setNewBusiness((prevBusiness) => {
                    const updatedBusiness = [...prevBusiness];
                    updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], categoryCode: top100Films[0].code };
                    return updatedBusiness;
                });
                id = top100Films[0].id
            }
            else {
                id = CategoryCode
            }
            GetQuestions(id).then((data) => {
                setQuestions(data)
                if (!answers) {
                    var ans = []
                    data.forEach(element => {
                        ans.push({ answer1: '', questionId: '', categoryCode: '', bussinessId: 0 })
                    });
                    setAnswers(ans)
                }
                if (!answers?.length || answers?.length < data.length) {
                    var ans = answers !== undefined ? answers : []
                    while (ans?.length < data.length) {
                        ans.push({ answer1: '', questionId: '', categoryCode: '', bussinessId: 0 })
                    }
                    setAnswers(ans)
                }
            }).catch((error) => { console.error(error); });
        }
    }, [num]);

    const [dates, setDates] = useState([]);
    const [mishlochim, setMishlochim] = useState(false);


    //--------------------------------------------------------------


    const [resultSearch, setResultSearch] = useState([])
    const [categoryName, setCategoryName] = useState('')

    const searchData = (search) => {
        var result = []
        for (let i = 0; i < top100Films?.length; i++) {
            if (top100Films[i].name.includes(search))
                result.push(top100Films[i])
        }
        setResultSearch(result)
        // setResultSearch(result.filter(c => c.code && c.code.includes(search)) || []);
    }

    const navigate = useNavigate()


    const HebrewDaysNames = ['0', "א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ז'", "ח'", "ט'", "י'", 'י"א', 'י"ב', 'י"ג', 'י"ד', 'ט"ו', 'ט"ז', 'י"ז', 'י"ח', 'י"ט', "כ'", 'כ"א', 'כ"ב', 'כ"ג', 'כ"ד', 'כ"ה', 'כ"ו', 'כ"ז', 'כ"ח', 'כ"ט', "ל'"];

    const fullDatesH = () => {
        debugger
        var fullDatesH = []
        var whatDates = dates ? dates : []
        if (s?.bussinessData?.dates?.length > 0) {
            var arr = s.bussinessData.dates
            // s.bussinessData.dates = []
            arr.sort((a, b) => a.date1 > b.date1 ? 1 : -1)
            for (let i = 0; i < arr.length; i++) { arr[i].date1 = arr[i].date1.split('T')[0] }
            // for (let i = 0; i < 4; i++) { arr.push({ date1: '', categoryCode: newBusiness[numBusiness].code, bussinessId: 0 }) }
            whatDates = arr
            // setDates(arr)
        }
        whatDates.forEach(element => {
            const hebrewDate0 = new HDate(new Date(element.date1))
            const hebrewDateString0 = `${HebrewDaysNames[hebrewDate0.getDate()]} ${hebrewDate0.getMonthName('h')}`;
            fullDatesH.push(hebrewDateString0)
        });
        // setDates(arr)
        debugger
        return fullDatesH;
    }

    const addDate = (date) => {
        setDates([...dates, { date1: date, categoryCode: newBusiness[numBusiness].categoryCode, bussinessId: 0 }])
    }

    const removeDate = (date) => {
        setDates(prev => {
            var dates = prev;
            return dates.filter(a => a.date1 !== date);
        })
    }

    const [alert2, setAlert2] = useState(false)
    const [send, setSend] = useState(false)
    const sendGood = () => {
        setAlert2(false)
        setSend("ההודעה נשלחה בהצלחה !!!")
        setTimeout(() => {
            setSend(false)
        }, 40 * 100);
    }

    return <div>
        <div className='buton1' style={{ width: '15%', position: 'absolute', top: '15%', left: '7%' }} onClick={() => { setAlert2(true) }}><ButtonRed text='תמיכה טכנית'></ButtonRed></div>

        {(p.bool === '10' || p.bool === '11') && <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%' }}>מצטרפים לחבילת קומפלט</p>}
        {p.bool === 'false' && <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%' }}>מצטרפים למאגר של כל ספקי האירועים</p>}
        {p.bool !== 'false' && p.bool !== '10' && p.bool !== '11' && <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%' }}>עריכת פרטי העסק {s.name}</p>}
        <div ref={ref2} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3'></div>
        {p.bool === 'false' && <div style={{ width: '15%', height: '800px', position: 'absolute', right: '0', top: '12%', display: 'inline-block', position: 'fixed' }}>
            <div className="ckav"></div>
            <div className="egul"><div style={{ fontWeight: '400', marginTop: '28%' }}>1</div></div>
            <div className="ckav"></div>
            <div className="egul"><div style={{ fontWeight: '400', marginTop: '28%' }}>2</div></div>
            <div className="ckav"></div>
            <div className="egul"><div style={{ fontWeight: '400', marginTop: '28%' }}>3</div></div>
            <div className="ckav"></div>
            <div className="egul"><div style={{ fontWeight: '400', marginTop: '28%' }}>4</div></div>
            {questiuons !== null && questiuons?.length > 0 && <><div className="ckav"></div>
                <div className="egul"><div style={{ fontWeight: '400', marginTop: '28%' }}>5</div></div></>}
        </div>}

        {p.bool !== 'false' && <div onClick={() => { navigate(`/Kochavit/${categoryName}/${s.categoryCode}`) }} style={{ backgroundColor: 'rgb(218, 166, 166)', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', borderRadius: '4px', cursor: 'pointer', width: '5%', height: '6vh', paddingTop: '10px', position: 'absolute', top: '20%', right: '17%', zIndex: '20' }}><ArrowRightOutlined /></div>}

        <div className='glow' style={{ position: 'relative', width: '70%', height: 'auto', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: `${p.bool === 'false' ? '18%' : '15%'}`, marginTop: '2%' }}>
            <form id='newBusinessForm' onSubmit={handleSubmit} style={{ marginTop: '5%' }}>

                {num == 0 && p.bool === 'false' && <div>
                    <div className='titl' style={{ marginTop: '5%', fontSize: '120%', marginBottom: '7%' }}><div style={{ display: 'inline-block', color: '#c00000' }}>מצטרפים? </div> מלאו את הפרטים</div>

                    <p style={{ fontSize: '110%' }}>בחרו מהו תחום העסק?</p>
                    <input id="search1" type="text" className="input20" value={categoryName} onChange={(e) => { searchData(e.target.value); setCategoryName(e.target.value); setMoreCategories([]) }} onClick={(e) => { searchData(e.target.value) }} onBlur={() => setTimeout(() => { setResultSearch([]) }, 500)}></input>
                    {resultSearch[0] && <div className='resultSearchRound animate__animated animate__fadeInDown' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '30vh', marginTop: '-3%' }}>{resultSearch.map((x, i) => (
                        <div className='resultSearch' key={i} onClick={(e) => { setCategoryName(x.name); searchData(x.name); handlePhoneNumberChange(x.id, 'categoryCode'); setResultSearch([]) }}>{x.name}</div>
                    ))}</div>}

                    {moreCategories.length > 1 && <div>
                        <p style={{ fontWeight: '400' }}>העסק שלכם יכנס אוטומטית גם לקטגוריות הבאות:</p>
                        {moreCategories.map((x, i) => (
                            <p key={i}>{x.name}</p>
                        ))}
                    </div>}

                    {p.codeCategory === '-3' && <p style={{ marginTop: '4%' }}><span style={{ fontWeight: '400' }}>שירות הסליקה מתחיל לפעול כשבועיים לאחר הרישום.</span><br /> עד שיתחיל לפעול שירות הסליקה הזמנות ישלחו למייל העסק - בלי ששולם עליהם. <br />על העסק לגבות את התשלום באופן אחר.</p>}

                    <div style={{ marginBottom: '20%' }}></div>
                    <div className='buton' onClick={() => { if (newBusiness[numBusiness].categoryCode) { setErrorMessage(""); enter() } else { setErrorMessage('עדיין לא נבחר תחום') } }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>
                </div>}

                {num == 1 && (numBusiness == 1 || numBusiness == 2) && <div>
                    {/* <div className='buton' onClick={() => prev()} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div> */}

                    {p.bool === 'false' && <div className='titl' style={{ marginTop: '2%', fontSize: '120%', marginBottom: '7%' }}><div style={{ display: 'inline-block', color: '#c00000' }}>מצטרפים? </div> מלאו את הפרטים</div>}
                    <div style={{ width: '90%', marginRight: '5%', marginTop: '0%' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 5fr)' }}>
                            <div>
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*שם העסק" autoComplete="on" type="text" value={newBusiness[numBusiness].name} onChange={(e) => { if (e.target.value.length <= 40) { handleNameChange(e) } }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*נייד של איש קשר" autoComplete="on" type="text" value={newBusiness[numBusiness].phone} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phone') } }} />
                                {(p.codeCategory === '-2' || p.codeCategory === '-3') && <input className='input20 ii' style={{ width: '70%' }} placeholder="מס' טלפון למערכת הטלפונית" autoComplete="on" type="text" value={newBusiness[numBusiness].phoneToCall} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phoneToCall') } }} />}
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*דואר אלקטרוני" autoComplete="on" type="email" value={newBusiness[numBusiness].mail} onChange={(e) => { if (e.target.value.length <= 254) { handleEmailChange(e) } }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="קישור לאתר" autoComplete="on" type="text" value={newBusiness[numBusiness].kishurLahatar} onChange={(e) => handleChange(e, 'kishurLahatar', 100)} />
                                {numBusiness == 2 && <div>

                                    <select className="custom-select1" onChange={(e) => handleChange(e, 'area', 20)} style={{ marginTop: '7%', marginBottom: '7%' }}>
                                        <option style={{ fontWeight: '400' }} value="" >*בחר איזור פעילות:</option>
                                        <option value="כל הארץ" >כל הארץ</option>
                                        <option value="צפון">צפון</option>
                                        <option value="מרכז">מרכז</option>
                                        <option value="דרום">דרום</option>
                                    </select>

                                    <div style={{ display: 'inline-block', marginTop: '8%' }}>*קובץ תמונה המכיל את {CategoryCode === 3 ? 'המחירון' : 'התפריט והמחירון'}</div><br></br>
                                    <input type="file" id="fileElem" accept="image/*" onChange={changePic} style={{ display: 'none' }} />
                                    <input type="button" className="submit1 input" id="fileSelect" onClick={clickPic} value="העלה תמונה" />
                                    <div id="fileList" style={{ marginBottom: '7%' }}> <p>עדיין לא נבחרה תמונה!</p> </div>
                                </div>}
                            </div>
                            <div>
                                {numBusiness == 1 && <div>
                                    <input className='input20 ii' style={{ width: '70%' }} placeholder="*כתובת" autoComplete="on" type="text" value={newBusiness[numBusiness].place} onChange={(e) => handleChange(e, 'place', 100)} />
                                    <input className='input20 ii' style={{ width: '70%' }} placeholder="*עיר" autoComplete="on" type="text" value={newBusiness[numBusiness].city} onChange={(e) => handleChange(e, 'city', 20)} />
                                    <input className='input20 ii' style={{ width: '70%' }} placeholder="*מספר מקומות" autoComplete="on" type="text" value={newBusiness[numBusiness].size} onChange={(e) => handleChange(e, 'size', 100)} />
                                    <input className='input20 ii' style={{ width: '70%' }} placeholder="*מחיר האולם" autoComplete="on" type="text" value={newBusiness[numBusiness].price} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value < 214748) || e.target.value === '') { handlePhoneNumberChange(e, 'price') } }} />
                                    <input className='input20 ii' style={{ width: '70%' }} placeholder="*מה כולל המחיר?" autoComplete="on" type="text" value={newBusiness[numBusiness].includePrice} onChange={(e) => handleChange(e, 'includePrice', 70)} />
                                </div>}
                                {numBusiness == 2 && <div>
                                    {CategoryCode !== 3 && <div style={{ border: 'black 1px solid', paddingTop: '5%', paddingBottom: '10%' }}>
                                        <p style={{ padding: '6%', paddingBottom: '5%' }}>בשלושת השדות הבאים: <br></br>נא להכניס <span style={{ fontWeight: '400' }}>מספר-מספר</span> ואז ניתן להמשיך לכתוב. <br></br>לדוגמה: <span style={{ fontWeight: '400' }}>200-300</span> מנות, מעבר ל-300 מנות לא תהיה הגשה אישית.</p>
                                        <input className='input20 ii' style={{ width: '70%' }} placeholder="*טווח כמות מנות סעודה" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishes} onChange={(e) => handleChange(e, 'rangeDishes', 60)} />
                                        <input className='input20 ii' style={{ width: '70%' }} placeholder="*טווח כמות מנות קבלת פנים" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishesKabalat} onChange={(e) => handleChange(e, 'rangeDishesKabalat', 25)} />
                                        <input className='input20 ii' style={{ width: '70%' }} placeholder="*טווח מחירים למנת סעודה" autoComplete="on" type="text" value={newBusiness[numBusiness].rangePrice} onChange={(e) => handleChange(e, 'rangePrice', 25)} />
                                    </div>}
                                    <div>
                                        {CategoryCode === 3 && <div style={{ border: 'black 1px solid', paddingTop: '12%', paddingBottom: '10%', margin: '6%' }}>
                                            <input className='input20 ii' style={{ width: '70%' }} placeholder="*מחיר לחבילת צילום רגילה" autoComplete="on" type="text" value={newBusiness[numBusiness].rangePrice} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value.length < 15) || e.target.value === '') { handlePhoneNumberChange(e, 'rangePrice') } }} />
                                            <input className='input20 ii' style={{ width: '70%' }} placeholder="*מה כוללת החבילה?" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishes} onChange={(e) => handleChange(e, 'rangeDishes', 60)} />
                                            <input className='input20 ii' style={{ width: '70%' }} placeholder="*מחיר לכל שעה נוספת" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishesKabalat} onChange={(e) => handlePhoneNumberChange(e, 'rangeDishesKabalat', 25)} />
                                        </div>}

                                        {/* <div style={{ display: 'inline-block', marginTop: '8%' }}>בחר קוד לניהול פרטי העסק אצלינו באתר</div>
                                        <p>עד 9 ספרות</p>
                                        <input className='input20 ii' style={{ width: '70%', marginTop: '3%' }} placeholder="בחר קוד לעסק" autoComplete="on" type="text" value={newBusiness[numBusiness].code} onChange={(e) => handlePhoneNumberChange(e, 'code')} /><br></br> */}
                                    </div>
                                </div>}
                                <div style={{ width: '70%', marginRight: '15%' }}><p style={{ marginTop: '8vh' }}><b>מומלץ: </b>רשום מילות מפתח שכאשר יחפשו אותם יגיעו לעסק שלך:</p>
                                    <input className='input20 ii' style={{ width: '100%' }} placeholder="סמן פסיק בין מילה למילה" autoComplete="on" type="text" value={newBusiness[numBusiness].keywords} onChange={(e) => handleChange(e, 'keywords', 100)} />
                                </div>
                            </div>
                        </div>
                        {/* {numBusiness == 1 && <div>
                            <div style={{ display: 'inline-block', marginTop: '8%' }}>בחר קוד לניהול פרטי העסק אצלינו באתר</div>
                            <p>עד 9 ספרות</p>
                            <input className='input20 ii' style={{ width: '70%', marginTop: '3%' }} placeholder="בחר קוד לעסק" autoComplete="on" type="text" value={newBusiness[numBusiness].code} onChange={(e) => handlePhoneNumberChange(e, 'code')} /><br></br>
                        </div>} */}
                        <div style={{ marginBottom: '16%' }}></div>
                        <div className='buton' onClick={() => {
                            debugger
                            if ((numBusiness == 2) && (newBusiness[numBusiness].priceList === '' || newBusiness[numBusiness].area === '' || newBusiness[numBusiness].rangeDishes === '' || newBusiness[numBusiness].rangeDishesKabalat === '' || newBusiness[numBusiness].rangePrice === '' || newBusiness[numBusiness].name === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '')) { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                            else if (numBusiness == 1 && (newBusiness[numBusiness].includePrice === '' || newBusiness[numBusiness].price === '' || newBusiness[numBusiness].size === '' || newBusiness[numBusiness].city === '' || newBusiness[numBusiness].place === '' || newBusiness[numBusiness].name === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '')) { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                            else {
                                if (!/^\d{10}$/.test(newBusiness[numBusiness].phone)) { setErrorMessage('מספר נייד לא תקין'); return; }
                                else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness[numBusiness].mail)) { setErrorMessage('כתובת מייל לא תקינה'); return; }
                                else { setErrorMessage(''); enter() }
                            }
                        }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>
                    </div>
                </div>}

                {num == 1 && numBusiness == 3 && <div>
                    {/* <div className='buton' onClick={() => prev()} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div> */}

                    {p.bool === 'false' && <div className='titl' style={{ marginTop: '2%', fontSize: '120%', marginBottom: '7%' }}><div style={{ display: 'inline-block', color: '#c00000' }}>מצטרפים? </div> מלאו את הפרטים</div>}
                    <div style={{ width: '90%', marginRight: '5%', marginTop: '0%' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 5fr)', marginBottom: '16%' }}>
                            <div>
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*שם העסק" autoComplete="on" type="text" value={newBusiness[numBusiness].name} onChange={(e) => { if (e.target.value.length <= 40) { handleNameChange(e) } }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*נייד של איש קשר" autoComplete="on" type="text" value={newBusiness[numBusiness].phone} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phone') } }} />
                                {(p.codeCategory === '-2' || p.codeCategory === '-3') && <input className='input20 ii' style={{ width: '70%' }} placeholder="מס' טלפון למערכת הטלפונית" autoComplete="on" type="text" value={newBusiness[numBusiness].phoneToCall} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phoneToCall') } }} />}
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*דואר אלקטרוני" autoComplete="on" type="email" value={newBusiness[numBusiness].mail} onChange={(e) => { if (e.target.value.length <= 254) { handleEmailChange(e) } }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="קישור לאתר / קליפ" autoComplete="on" type="email" value={newBusiness[numBusiness].kishurLahatar} onChange={(e) => handleChange(e, 'kishurLahatar', 100)} />
                                <div style={{ width: '70%', marginRight: '15%' }}><p style={{ marginTop: '8vh' }}><b>מומלץ: </b>רשום מילות מפתח שכאשר יחפשו אותם יגיעו לעסק שלך:</p>
                                    <input className='input20 ii' style={{ width: '100%' }} placeholder="סמן פסיק בין מילה למילה" autoComplete="on" type="text" value={newBusiness[numBusiness].keywords} onChange={(e) => handleChange(e, 'keywords', 100)} />
                                </div>{/* <div>
                                    <div style={{ display: 'inline-block', marginTop: '8%' }}>בחר קוד לניהול פרטי העסק אצלינו באתר</div>
                                    <p>עד 9 ספרות</p>
                                    <input className='input20 ii' style={{ width: '70%', marginTop: '3%' }} placeholder="בחר קוד לעסק" autoComplete="on" type="text" value={newBusiness[numBusiness].code} onChange={(e) => handlePhoneNumberChange(e, 'code')} /><br></br>
                                </div> */}
                            </div>
                            <div>
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*מחיר רגיל" autoComplete="on" type="text" value={newBusiness[numBusiness].price} onChange={(e) => { if (e.target.value.length <= 100) { handlePhoneNumberChange(e, 'price') } }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*מה כולל המחיר ושמות הכלים" autoComplete="on" type="text" value={newBusiness[numBusiness].includePrice} onChange={(e) => handleChange(e, 'includePrice', 70)} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*מחיר לשעות נוספות" autoComplete="on" type="text" value={newBusiness[numBusiness].priceAdd} onChange={(e) => { if (e.target.value.length <= 100) { handlePriceAdd(e) } }} />

                                <select className="custom-select1" onChange={(e) => handleChange(e, 'area', 20)}>
                                    <option style={{ fontWeight: '400' }} value="">*בחר איזור פעילות:</option>
                                    <option value="כל הארץ">כל הארץ</option>
                                    <option value="צפון">צפון</option>
                                    <option value="מרכז">מרכז</option>
                                    <option value="דרום">דרום</option>
                                </select>

                                <select className="custom-select1" onChange={(e) => handleChange(e, 'signon', 20)}>
                                    <option style={{ fontWeight: '400' }} value="">*בחר סגנון מוזיקה:</option>
                                    <option value="חסידי אותנטי">חסידי אותנטי</option>
                                    <option value="חסידי ישיבתי">חסידי ישיבתי</option>
                                    <option value="מוזיקה עדכנית וחדשנית">מוזיקה עדכנית וחדשנית</option>
                                    <option value="ליטאי">ליטאי</option>
                                    <option value='חבד"י'>חבד"י</option>
                                    <option value="מזרחי">מזרחי</option>
                                    <option value="אחר">אחר</option>
                                </select>

                                {/* <div style={{ marginTop: '8%' }}>קובץ תמונה המכיל את המחירון המלא</div><br></br>
                                <input type="file" id="fileElem" accept="image/*" onChange={changePic} style={{ display: 'none' }} />
                                <input type="button" className="submit1 input" id="fileSelect" onClick={clickPic} value="העלה תמונה" />
                                <div id="fileList" style={{ marginBottom: '7%' }}> <p>עדיין לא נבחרה תמונה!</p> </div> */}
                            </div>
                        </div>
                        <div className='buton' onClick={() => {
                            if (newBusiness[numBusiness].signon === '' || newBusiness[numBusiness].area === '' || newBusiness[numBusiness].priceAdd === '' || newBusiness[numBusiness].includePrice === '' || newBusiness[numBusiness].price === '' || newBusiness[numBusiness].name === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                            else {
                                if (!/^\d{10}$/.test(newBusiness[numBusiness].phone)) { setErrorMessage('מספר נייד לא תקין'); return; }
                                else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness[numBusiness].mail)) { setErrorMessage('כתובת מייל לא תקינה'); return; }
                                else { setErrorMessage(''); enter() }
                            }
                        }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>
                    </div>
                </div>}

                {num == 1 && numBusiness == 0 && <div>
                    {/* <div className='buton' onClick={() => prev()} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div> */}

                    {p.bool === 'false' && <div className='titl' style={{ marginTop: '2%', fontSize: '120%', marginBottom: '7%' }}><div style={{ display: 'inline-block', color: '#c00000' }}>מצטרפים? </div> מלאו את הפרטים</div>}
                    <div style={{ width: '90%', marginRight: '5%', marginTop: '0%' }}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 5fr)', marginBottom: '16%' }}>
                            <div>
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*שם העסק" autoComplete="on" type="text" value={newBusiness[numBusiness].name} onChange={(e) => { if (e.target.value.length <= 40) { handleNameChange(e) } }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*נייד של איש קשר" autoComplete="on" type="text" value={newBusiness[numBusiness].phone} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phone') } }} />
                                {(p.codeCategory === '-2' || p.codeCategory === '-3') && <input className='input20 ii' style={{ width: '70%' }} placeholder="מס' טלפון למערכת הטלפונית" autoComplete="on" type="text" value={newBusiness[numBusiness].phoneToCall} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phoneToCall') } }} />}
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*דואר אלקטרוני" autoComplete="on" type="email" value={newBusiness[numBusiness].mail} onChange={(e) => { if (e.target.value.length <= 254) { handleEmailChange(e) } }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="קישור לאתר" autoComplete="on" type="email" value={newBusiness[numBusiness].kishurLahatar} onChange={(e) => handleChange(e, 'kishurLahatar', 100)} />
                            </div>
                            <div>
                                {/* <p style={{ width: '70%', marginRight: '15%' }}>למכירה ביתית שנדרש תיאום מראש, נא לציין זאת בשעות הפתיחה</p> */}
                                {/* <p style={{ width: '70%', marginRight: '15%' }}>כשהכל בתיאום טלפוני בלבד, נא לציין זאת בשעות הפתיחה ובכתובת, ואין צורך לכתוב כתובת ושעות פתיחה</p> */}
                                {/* <p style={{ width: '70%', marginRight: '15%' }}>האם זה מספיק למשל בשביל עיצוב אירועים? או מודעה בעיתון? או צילום או אמרגן?</p> */}
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="שעות פתיחה" autoComplete="on" type="text" value={newBusiness[numBusiness].openingHours} onChange={(e) => handleChange(e, 'openingHours', 40)} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="כתובת" autoComplete="on" type="text" value={newBusiness[numBusiness].address} onChange={(e) => handleChange(e, 'address', 40)} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="*עיר" autoComplete="on" type="text" value={newBusiness[numBusiness].city} onChange={(e) => { if (e.target.value.length <= 100) { handleCityChange(e) } }} />
                                {address.map((x, i) => (<span key={i}>
                                    <div style={{ marginBottom: '6vh' }}></div>
                                    <input className='input20 ii' style={{ width: '70%' }} placeholder="כתובת" autoComplete="on" type="text" value={x} onChange={(e) => { setAddress((p) => { var arr = [...p]; arr[i] = e.target.value; return arr; }) }} />
                                    <input className='input20 ii' style={{ width: '70%' }} placeholder="עיר" autoComplete="on" type="text" value={city[i]} onChange={(e) => { setCity((p) => { var arr = [...p]; arr[i] = e.target.value; return arr; }) }} />
                                </span>))}

                                <input type="button" value='סניף נוסף' className="submit1 input" onClick={() => { setAddress([...address, '']); setCity([...city, '']) }} />

                                <div style={{ width: '70%', marginRight: '15%' }}><p style={{ marginTop: '8vh' }}><b>מומלץ: </b>רשום מילות מפתח שכאשר יחפשו אותם יגיעו לעסק שלך:</p>
                                    <input className='input20 ii' style={{ width: '100%' }} placeholder="סמן פסיק בין מילה למילה" autoComplete="on" type="text" value={newBusiness[numBusiness].keywords} onChange={(e) => handleChange(e, 'keywords', 100)} />
                                </div>
                            </div>
                        </div>
                        <div className='buton' onClick={() => {
                            if (newBusiness[numBusiness].name === '' || newBusiness[numBusiness].city === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                            else {
                                if (!/^\d{10}$/.test(newBusiness[numBusiness].phone)) { setErrorMessage('מספר נייד לא תקין'); return; }
                                else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness[numBusiness].mail)) { setErrorMessage('כתובת מייל לא תקינה'); return; }
                                else { setErrorMessage(''); enter() }
                            }
                        }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>
                    </div>
                </div>}

                {num === 2 && (numBusiness === 1 || numBusiness === 2 || numBusiness === 3) && <div>
                    <div className='buton' onClick={() => prev()} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>
                    <div className='titl' style={{ marginTop: '3%', fontSize: '120%', marginBottom: '7%' }}>פרטים נוספים</div>

                    <textarea style={{ width: '68%', height: '300px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4%', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='*תאר את העסק' value={newBusiness[numBusiness].description} onChange={(e) => handleChange(e, 'description', 800)}></textarea>
                    <p style={{ marginTop: '-50px', float: 'left', marginLeft: '17%', fontSize: '90%' }}>*תיאור העסק יכול להכיל עד 800 תווים</p>

                    {numBusiness !== 3 && <><textarea style={{ width: '68%', height: '80px', marginTop: '6%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4%', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='*יתרון מיוחד הקים בעסק זה' value={newBusiness[numBusiness].adventage} onChange={(e) => handleChange(e, 'adventage', 50)}></textarea>
                        <p style={{ marginTop: '-50px', float: 'left', marginLeft: '17%', fontSize: '90%' }}>*יתרון יכול להכיל עד 50 תווים</p></>}

                    {numBusiness === 2 && CategoryCode !== 3 && <div>
                        <div style={{ marginTop: '10%' }}>*קובץ תמונה המכיל את תעודת הכשרות</div><br></br>
                        <input type="file" id="fileElem1" accept="image/*" onChange={changePics} style={{ display: 'none' }} />
                        {!allLists.songs.length > 0 && <input type="button" className="submit1 input" id="fileSelect1" onClick={clickPics} value="העלה תמונה" />}
                        {allLists.songs.length > 0 && <input type="button" className="submit1 input" id="fileSelect1" onClick={clickPics} value="הוסף תמונה" />}
                        {allLists.songs.length > 0 && <div title="מחק תמונות" style={{ userSelect: 'none' }} className="submit1 input in2" id="fileSelect2" onClick={() => { setOpenPic(4) }}><DeleteOutlined /></div>}
                        <div id="fileList1" style={{ marginBottom: '7%' }}> <p>עדיין לא נבחרה תמונה!</p> </div>
                        {allLists.songs.length > 0 && <p style={{ marginTop: '-60px', float: 'right', marginRight: '17%', fontSize: '90%' }}>*עד 3 תעודות כשרות</p>}

                    </div>}

                    <div style={{ marginBottom: '20%' }}></div>
                    {p.bool === 'false' && <div className='buton' onClick={() => {
                        if (newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else if (numBusiness == 2 && CategoryCode !== 3 && (allLists.songs.length <= 0 || newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '')) { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else { setErrorMessage(''); enter() }
                    }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                    {p.bool === '2' && questiuons?.length <= 0 && numBusiness !== 3 && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיים עריכה'></ButtonRed></button>}
                    {p.bool === '2' && (questiuons?.length > 0 || numBusiness === 3) && <div className='buton' onClick={() => {
                        if (newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else if (numBusiness == 2 && CategoryCode !== 3 && (allLists.songs.length <= 0 || newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '')) { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else { setErrorMessage(''); setNum(numBusiness === 3 ? 4 : 5) }
                    }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                </div>}

                {num == 2 && numBusiness == 0 && <div>
                    <div className='buton' onClick={() => prev()} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>

                    <textarea style={{ width: '68%', height: '300px', marginTop: '8%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4%', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='*תאר את העסק' value={newBusiness[numBusiness].description} onChange={(e) => handleChange(e, 'description', 800)}></textarea>
                    <p style={{ marginTop: '-50px', float: 'left', marginLeft: '17%', fontSize: '90%' }}>*תיאור העסק יכול להכיל עד 800 תווים</p>

                    <textarea style={{ width: '68%', height: '80px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4%', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='*יתרון מיוחד הקים בעסק זה' value={newBusiness[numBusiness].adventage} onChange={(e) => handleChange(e, 'adventage', 50)}></textarea>
                    <p style={{ marginTop: '-50px', float: 'left', marginLeft: '17%', fontSize: '90%' }}>*יתרון יכול להכיל עד 50 תווים</p>

                    <textarea style={{ width: '68%', height: '80px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4%', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='בונוס לבאים דרך קול שמחה' value={newBusiness[numBusiness].bonus} onChange={(e) => handleChange(e, 'bonus', 100)}></textarea>
                    <p style={{ marginTop: '-50px', float: 'left', marginLeft: '17%', fontSize: '90%' }}>*בונוס יכול להכיל עד 100 תווים</p>

                    <div style={{ marginBottom: '20%' }}></div>
                    {p.bool === 'false' && <div className='buton' onClick={() => {
                        if (newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else { setErrorMessage(''); enter() }
                    }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                    {p.bool === '2' && questiuons?.length <= 0 && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיים עריכה'></ButtonRed></button>}
                    {p.bool === '2' && questiuons?.length > 0 && <div className='buton' onClick={() => {
                        if (newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else { setErrorMessage(''); setNum(5) }
                    }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                </div>}

                {(num == 3 || p.bool === '3') && <div>
                    {p.bool === 'false' && <div className='buton' onClick={() => prev()} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>}

                    <input type="file" id="fileElem" accept="image/*" onChange={changePic} style={{ display: 'none' }} />
                    {!newBusiness[numBusiness].logo && <input type="button" className="submit1 input in1" id="fileSelect" onClick={clickPic} value="הכנס לוגו של העסק" />}
                    {newBusiness[numBusiness].logo && <input type="button" className="submit1 input in1" id="fileSelect" onClick={clickPic} value="החלף לוגו של העסק" />}

                    <div id="fileList" style={{ marginBottom: '7%' }}> <p>עדיין לא נבחרה תמונת לוגו!</p> </div>

                    <input type="file" id="fileElem1" accept="image/*" multiple onChange={changePics} style={{ display: 'none' }} />
                    <b>חשוב שהתמונות יהיו לרוחב ולא לאורך</b><br />
                    {!allLists.images.length > 0 && <input style={{ marginRight: '14%', marginLeft: '5%' }} type="button" className="submit1 input in1" id="fileSelect1" onClick={clickPics} value="הכנס תמונות לשווק העסק" />}
                    {allLists.images.length > 0 && <input style={{ marginRight: '14%', marginLeft: '5%' }} type="button" className="submit1 input in1" id="fileSelect1" onClick={clickPics} value="הוסף תמונות לשווק העסק" />}
                    <div title="מחק תמונות" style={{ userSelect: 'none' }} className="submit1 input in2" id="fileSelect2" onClick={() => { setOpenPic(2) }}><DeleteOutlined /></div>

                    <div id="fileList1" style={{ width: '90%', height: '20%', margin: '5%', marginTop: '2%' }}> <p>עדיין לא נבחרו תמונות!</p> </div>
                    <p style={{ marginTop: '-60px', float: 'right', marginRight: '17%', fontSize: '90%' }}>*עד 5 תמונות</p>

                    <p>מתוך תמונות לשיווק העסק</p>
                    <div style={{ userSelect: 'none' }} className="submit1 input in1" id="fileSelect2" onClick={() => setOpenPic(1)}>*בחר תמונה ראשית</div>
                    <div id="fileList30" style={{ width: '90%', height: '20%', margin: '5%', marginTop: '2%' }}> <p>עדיין לא נבחרה תמונה!</p> </div>


                    <div style={{ marginBottom: '15%' }}></div>
                    {p.bool === 'false' && <div className='buton' onClick={() => {
                        if (newBusiness[numBusiness].mainImage === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else { setErrorMessage(''); numBusiness === 3 || p.codeCategory === '-3' ? setNum(4) : questiuons?.length > 0 ? setNum(5) : setNum(numBusiness === 0 && CategoryCode !== 132 && CategoryCode !== 133 ? 7 : 6); setEgul(prev => prev + 1) }
                    }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                    {p.bool === '3' && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיים עריכה'></ButtonRed></button>}

                </div>}

                {((num == 4 && (numBusiness == 0 || numBusiness == 1 || numBusiness == 2)) || p.bool === '10' || p.bool === '11' || p.bool === '5') && <div>
                    {p.bool === 'false' && <div className='buton' onClick={() => prev()} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>}

                    <div>הכנס חבילות / שירותים / מוצרים מדוייקים, כולל תמונה, תיאור ומחיר</div>
                    {p.bool !== '5' && <p>כדי להתחיל לקבל הזמנות ישירות מהאתר</p>}
                    {/* <p>יש למלא פרטים על כל מוצר</p> */}
                    {/* <p>ניתן לדלג על שלב זה ע"י לחיצה על המשך</p> */}

                    <table style={{ marginTop: '7%', width: '91%', marginRight: '5%' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '20%', textAlign: 'center' }}>קוד מוצר / מק"ט</th>
                                <th style={{ width: '20%', textAlign: 'center' }}>שם המוצר</th>
                                <th style={{ width: '20%', textAlign: 'center' }}>תיאור</th>
                                <th style={{ width: '20%', textAlign: 'center' }}>מחיר בשקלים</th>
                                <th style={{ width: '21%', textAlign: 'center' }}>תמונה</th>
                            </tr>
                        </thead>
                        {lotItems && <tbody>{lotItems.map((x, i) => (
                            <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.code} onChange={(e) => { if (e.target.value.length <= 20) { const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, code: e.target.value }; } return item; }); setLotItems(updatedLotItems); } }} defaultValue={x.code}></input></td>
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.name} onChange={(e) => { if (e.target.value.length <= 22) { const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, name: e.target.value }; } return item; }); setLotItems(updatedLotItems); } }} defaultValue={x.name}></input></td>
                                <td><input className='inpu' style={{ width: '100%', height: 'auto', border: 'none' }} autoComplete="on" type="text" value={x.description} onChange={(e) => { if (e.target.value.length <= 140) { const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, description: e.target.value }; } return item; }); setLotItems(updatedLotItems); } }} defaultValue={x.description}></input></td>
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.price} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value < 214748) || e.target.value === '') { const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, price: e.target.value }; } return item; }); setLotItems(updatedLotItems); } }} defaultValue={x.price}></input></td>
                                {x.picture && <img className='picUp' src={`data:image/jpeg;base64,${x.picture}`}></img>}{!x.picture && <div style={{ display: 'inline-block' }}>אין תמונה</div>}
                                <input type="file" className="fileElemMutsar" accept="image/*" onChange={(e) => changePicMutsar(e, i)} style={{ display: 'none' }} />
                                <input type="button" className="choozePic fileSelectMutsar" onClick={(e) => clickPicMutsar(e, i)} value="בחר תמונה" />
                                <td><IconButton onClick={() => setLotItems(lotItems.filter(a => a !== lotItems[i]))} className='IconButton' size="small">
                                    <CloseOutlined />
                                </IconButton></td>
                            </tr>))}
                        </tbody>}
                    </table>
                    <div onClick={() => { setLotItems([...lotItems, { code: '', name: '', description: '', price: '', picture: '' }]) }} className='buton' style={{ marginTop: '0', float: 'left', marginLeft: '5%', width: '12%' }}><ButtonRed text='הוסף מוצר'></ButtonRed></div>
                    {numBusiness == 0 && <div style={{ position: 'relative', marginTop: '18%', border: '#c00000 2px solid', width: '80%', marginRight: '10%', padding: '5%' }}>
                        {/* <p style={{ position: 'absolute', backgroundColor: 'white', top: '-45px' }}>&nbsp;&nbsp;מיועד לעסק שקיים בו שירות משלוחים&nbsp;&nbsp;</p> */}
                        <div style={{ position: 'absolute', right: '5%', width: '50%' }}><label className="checkbox-container"><input type="checkbox" className="checkbox1" checked={mishlochim} value={-1} onClick={(e) => { if (mishlochim) { handlePhoneNumberChange(e, 'priceShipping') }; setMishlochim(!mishlochim) }} /><span className="checkbox-custom">&#10003;</span> &nbsp; האם קיים בעסק שירות משלוחים?</label></div><br></br>
                        {mishlochim && <div><input className='input20 ii' style={{ width: '70%', marginTop: '3%' }} placeholder="מחיר משלוח" autoComplete="on" type="text" value={newBusiness[numBusiness].priceShipping === -1 ? "" : newBusiness[numBusiness].priceShipping} onChange={(e) => { if (e.target.value <= 32767) { handlePhoneNumberChange(e, 'priceShipping') } }} />
                            למשלוח חינם מלאו מחיר משלוח: 0
                            <input className='input20 ii' style={{ width: '70%', marginTop: '3%' }} placeholder="הערות למשלוח: זמן אספקה וכדו'" autoComplete="on" type="text" value={newBusiness[numBusiness].notesShipping} onChange={(e) => { handleChange(e, 'notesShipping', 50) }} /></div>}
                    </div>}
                    <div style={{ marginBottom: '20%' }}></div>
                    {p.bool === '11' && <input className='input20 ii' style={{ width: '70%', marginTop: '-10%', marginBottom: '10%' }} placeholder="מס' טלפון למערכת הטלפונית" autoComplete="on" type="text" value={newBusiness[numBusiness].phoneToCall} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phoneToCall') } }} />}
                    {/* <div className='buton' onClick={() => { questiuons?.length > 0 ? enter() : setNum(6) }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div> */}
                    {p.bool === '5' && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-5%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיים עריכה'></ButtonRed></button>}
                    {(p.bool === '10' || p.bool === '11') && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-5%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיום הצטרפות'></ButtonRed></button>}
                    {p.bool === 'false' && <div className='buton' onClick={() => { questiuons?.length > 0 ? setNum(5) : setNum(numBusiness === 0 && CategoryCode !== 132 && CategoryCode !== 133 ? 7 : 6); setEgul(prev => prev + 1) }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                </div>}

                {num == 4 && numBusiness == 3 && <div>
                    {p.bool === 'false' && <div className='buton' onClick={() => prev()} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>}
                    {p.bool === '2' && <div className='buton' onClick={() => setNum(2)} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>}

                    <p>הכנס שירים שלכם לדוגמא,</p>
                    <p>שיר ארוך יותר מ-4 דקות לא יעלה</p>
                    <p>שם הקובץ יראה גם כן באתר, ולכן יש לשנות את שם הקובץ לשם הרצוי לפני ההעלאתו</p>
                    <input type="file" id="fileElem1" accept="audio/*" multiple onChange={changePics} style={{ display: 'none' }} />
                    <input type="button" style={{ marginTop: '10%', marginRight: '10%' }} className="submit1 input" id="fileSelect1" onClick={clickPics} value="העלאת שירים" />
                    <div title="מחק שירים" style={{ userSelect: 'none', marginRight: '3%' }} className="submit1 input in2" id="fileSelect2" onClick={() => { setOpenPic(3) }}><DeleteOutlined /></div>

                    <div id="fileList1" style={{ width: '90%', height: '20%', margin: '5%', marginTop: '2%' }}> <p>עדיין לא נבחרו שירים!</p> </div>
                    <p style={{ marginTop: '-60px', float: 'right', marginRight: '17%', fontSize: '90%' }}>*עד 3 שירים</p>


                    <div style={{ marginBottom: '28%' }}></div>
                    {(p.bool === 'false' || p.bool === '2' && questiuons?.length > 0) && <div className='buton' onClick={() => { questiuons?.length > 0 ? enter() : setNum(6); setEgul(prev => prev + 1); }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                    {p.bool === '2' && questiuons?.length <= 0 && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיים עריכה'></ButtonRed></button>}

                </div>}

                {num == 5 && <div>
                    {p.bool === '2' && <div className='buton' onClick={() => { setNum(numBusiness === 3 ? 4 : 2) }} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>}
                    {p.bool === 'false' && <div className='buton' onClick={() => { numBusiness === 3 || p.codeCategory === '-3' ? setNum(4) : setNum(3); setEgul(prev => prev - 1) }} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>}
                    <p className="titl" style={{ marginTop: '8%', fontSize: '120%', marginBottom: '2%' }}>שאלות נפוצות לעסק השייך לקטגורית {nameCategory}</p>
                    <p style={{ marginBottom: '8%' }}>מומלץ למלא את התשובות, התשובות הן על פי העסק האישי שלכם: {newBusiness[numBusiness].name}</p>
                    {questiuons && <div>{questiuons.map((x, i) => (
                        <div key={i}><div className='questionCon' style={{ height: '7vh', lineHeight: '7vh' }}>{x.question1}</div>
                            <div className='animate__animated animate__fadeInDownBig'>
                                {p.bool !== 'false' && <input className='inpu' style={{ width: '100%', height: '7vh', border: 'none', marginTop: '1px', marginBottom: '1px' }} autoComplete="on" type="text" value={answers[i].answerCon} onChange={(e) => { if (e.target.value.length <= 200) { const updated = answers.map((item, index) => { if (index === i) { if (item.questionId !== '') { return { ...item, answerCon: e.target.value } } else { return { ...item, answerCon: e.target.value, bussinessId: s.id, categoryCode: s.categoryCode, questionId: x.id } }; } return item; }); setAnswers(updated); } }}></input>}
                                {p.bool === 'false' && answers.length > i && <input className='inpu' style={{ width: '100%', height: '7vh', border: 'none', marginTop: '1px', marginBottom: '1px' }} autoComplete="on" type="text" value={answers[i].answer1} onChange={(e) => { if (e.target.value.length <= 200) { const updated = answers.map((item, index) => { if (index === i) { return { ...item, answer1: e.target.value, questionId: x.id }; } return item; }); setAnswers(updated); } }}></input>}
                            </div>
                        </div>
                    ))}</div>}

                    <div style={{ marginBottom: '15%' }}></div>
                    {p.bool === 'false' && <div className='buton' onClick={() => { setNum(numBusiness === 0 && CategoryCode !== 132 && CategoryCode !== 133 ? 7 : 6); setEgul(prev => prev + 1) }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                    {p.bool === '2' && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיים עריכה'></ButtonRed></button>}

                </div>}

                {((num == 6 && (numBusiness !== 0 || CategoryCode === 132 || CategoryCode === 133)) || p.bool === '4') && <div>
                    {p.bool !== '4' && <div className='buton' onClick={() => { prev() }} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>}
                    <p className="titl" style={{ marginTop: '8%', fontSize: '120%', marginBottom: '2%' }}>ניהול יומן</p>
                    <p>מהיום הלקוחות מסמנים את התאריך אותו הם תפסו, כתאריך מלא - והספק יושב בשקט ומחכה להזמנות...</p>
                    <p>שימו לב במילוי תאריך אחד הלקוחות כבר יוכלו לקבוע אצליכם תאריכים - ולכן יש למלא את כל התאריכים המלאים או לא כלום.</p>
                    <p>וכן בהסרת התאריך האחרון - לקוחות כבר לא יוכלו לקבוע תאריך</p>

                    <p style={{ marginBottom: '8%' }}>מהם התאריכים המלאים ב{newBusiness[numBusiness].name} עד כה:</p>

                    <div style={{ minHeight: '60vh' }}>
                        <Calender fullDatesH={fullDatesH()} categoryCode={newBusiness[numBusiness].categoryCode} bussinessId={0} addDate={addDate} removeDate={removeDate}></Calender>
                    </div>
                    {/* <div onClick={() => { setDates([...dates, { date1: '0000-00-00', categoryCode: newBusiness[numBusiness].code, bussinessId: 0}]) }} className='buton' style={{ marginTop: '0', float: 'left', marginLeft: '5%', width: '12%', marginBottom:'5%' }}><ButtonRed text='הוסף תאריך'></ButtonRed></div> */}

                    <div style={{ marginBottom: '15%' }}></div>
                    {p.bool === 'false' && <div className='buton' onClick={() => { enter() }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
                    {p.bool === '4' && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-13%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיים עריכה'></ButtonRed></button>}

                </div>}

                {num == 7 && <div>
                    <div className='buton' onClick={() => { setNum((numBusiness !== 0 || CategoryCode !== 132 || CategoryCode !== 133) ? 6 : questiuons?.length > 0 ? 5 : numBusiness === 3 || p.codeCategory === '-3' ? 4 : 3); setEgul(prev => prev - 1) }} style={{ position: 'absolute', top: '-2vh', right: '-40%', width: '5%' }}><ButtonRed text='<--'></ButtonRed></div>
                    {p.bool === 'false' && <><p className="titl" style={{ marginTop: '8%', fontSize: '120%', marginBottom: '2%' }}>קוד לניהול העסק</p>
                        <div>
                            <div style={{ display: 'inline-block', marginTop: '1%' }}>בחר קוד לניהול פרטי העסק אצלינו באתר</div>
                            <p>עד 9 ספרות</p>
                            <input className='input20 ii' style={{ width: '70%', marginTop: '3%' }} placeholder="בחר קוד לעסק" autoComplete="on" type="text" value={newBusiness[numBusiness].code} onChange={(e) => { if (e.target.value.length <= 9) { handlePhoneNumberChange(e, 'code') } }} /><br></br>
                        </div></>}

                    {p.bool === '4' && <><p className="titl" style={{ marginTop: '8%', fontSize: '120%', marginBottom: '2%' }}>הגעתם לשלב הסיום</p>
                        <p>סיום עריכת הפרטים</p></>}

                    {p.bool !== 'false' && <button type="submit" className='buton' style={{ width: '20%', marginTop: '12%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיים עריכה'></ButtonRed></button>}
                    {p.bool === 'false' && <button type="submit" className='buton' style={{ width: '20%', marginTop: '-1%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='סיום הצטרפות'></ButtonRed></button>}
                </div>}


                {errorMessage && <p><img src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
                {openPic && <div id='modal2'>
                    <div className='animate__animated animate__zoomIn'>
                        <CloseOutlined className='close2' onClick={() => { setOpenPic(false); iimmgg = null }} style={{ cursor: 'pointer', position: 'absolute', left: '5%', marginTop: '1.5%' }} />
                        <div className='glow' style={{ backgroundColor: 'white', width: '95%', height: '95vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '2.5%', marginTop: '2.5vh' }}>
                            {/* <div className='titl' style={{ marginTop: '8%', fontSize: '120%', marginBottom: '5%' }}>האם ברצונך למחוק {prop.text} זו לצמיתות?</div> */}
                            <div id='fileList12' style={{ marginTop: '5%', width: `${openPic == 3 ? '60%' : '80%'}`, marginRight: 'auto', marginLeft: 'auto', maxHeight: '45vh', overflow: 'auto' }}></div>

                            <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { openPic === 1 ? chooseMainPic() : chooseDelPic() }}><ButtonRed text='אישור'></ButtonRed></div>
                            <div id='fileList13' style={{ marginTop: '2%', maxWidth: '100%', maxHeight: '20vh', overflowX: 'auto', textOrientation: 'landscape' }}></div>
                        </div>
                    </div>
                </div>}

                {wait && <Wait></Wait>}

                <div style={{ position: 'absolute', bottom: '0%', left: '1%', zIndex: '602', position: 'fixed', backgroundColor: 'white', width: '150px', height: '150px' }}></div>


            </form>

        </div >

        {finishGood && <div id='modal' style={{ backgroundImage: `url(${pic2})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}><div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ width: '30%' }}>
                    <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%', borderTopLeftRadius:'10px', borderTopRightRadius:'10px' }}>{newBusiness[numBusiness].name} - הצטרפתם בהצלחה</p>
                    <p className='titl' style={{ marginTop: '20%', width: '70%', marginRight: '15%' }}>כעת אתם חלק מהמאגר העצום של כל נותני השירות בתחום האירועים!</p>
                    <div id='submit30' onClick={() => { n(`/allCards/${newBusiness[numBusiness].categoryCode}/${categoryName}/${'הצטרפות לעסקים'}`) }}><ButtonRed text={'אישור'}></ButtonRed></div>
                </div>
            </div>
        </div></div>}

        {alert2 && <Alert set={setAlert2} sendGood={sendGood} text={"קול שמחה"} text2={"יש לכם שאלה? צריכים עזרה? אנחנו תמיד לשירותכם, נשמח לענות בהקדם"} mail={"r0527117663@gmail.com"}></Alert>}

        <div style={{ margin: '20%' }}></div>
    </div >
}

export default AddToDataBase;