// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../pic/img5.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bottomMenu {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 100%;
    height: 30rem;
    margin-top: 5%;
    position: relative;
    z-index: 220;
    bottom: 0%;
    /* color: white; */
}

#bottomMenu span:hover {
    color: #FF7D7D;
}

.containerBottom {
    margin-right: 7%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;
}

.linkBottom {
    text-decoration: none;
}

#bottomMenu .linkBottom:hover {
    color: #FF7D7D;
}

`, "",{"version":3,"sources":["webpack://./src/components/global/bottomMenu/BottomMenu.css"],"names":[],"mappings":"AAAA;IACI,yDAA4C;IAC5C,WAAW;IACX,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,qCAAqC;IACrC,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["#bottomMenu {\r\n    background-image: url(../../../pic/img5.png);\r\n    width: 100%;\r\n    height: 30rem;\r\n    margin-top: 5%;\r\n    position: relative;\r\n    z-index: 220;\r\n    bottom: 0%;\r\n    /* color: white; */\r\n}\r\n\r\n#bottomMenu span:hover {\r\n    color: #FF7D7D;\r\n}\r\n\r\n.containerBottom {\r\n    margin-right: 7%;\r\n    display: grid;\r\n    grid-template-columns: repeat(7, 1fr);\r\n    grid-gap: 10px;\r\n}\r\n\r\n.linkBottom {\r\n    text-decoration: none;\r\n}\r\n\r\n#bottomMenu .linkBottom:hover {\r\n    color: #FF7D7D;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
