
const bigMenu = {
    
};

const menuReducer = (state = bigMenu, action) => {
  switch (action.type) {
    case "COMPLETE":
        return action.payload;
    //   return state.map((todo) => {
    //     if (todo.id === action.id) {
    //       return { ...todo, complete: !todo.complete };
    //     } else {
    //       return todo;
    //     }
    //   });
    default:
      return state;
  }
};



export default menuReducer;

