import './button.css'

const ButtonRed = (props) => {

    const move = (event) => {
        const elem = event.currentTarget.children[0];   
        var width = 0;
        const frame = () => {
          if (width >= 100) {
            clearInterval(id);
          } else {
            width = width+5; 
            elem.style.width = width + '%'; 
          }}
        var id = setInterval(frame, 10);
      }

      const moveBack = (event) => {
        var elem = event.currentTarget.children[0];   
        var width = 100;
        const frame = () => {
          if (width <= 0) {
            clearInterval(id);
          } else {
            width = width-5; 
            elem.style.width = width + '%'; 
          }}
        var id = setInterval(frame, 10);
      }

    return <div id="button" onMouseOver={(event)=>move(event)} onMouseLeave={(event)=>moveBack(event)} style={{background:`${props.is == this? 'white' : 'linear-gradient(to left, #c00000, #FF7D7D)'}`}}><div style={{ zIndex:'900', height:'45px'}} id="buttonColor"></div><div style={{transform: "translateY(-120%)", zIndex:'900', paddingRight:'10px', paddingLeft:'10px'}}>{props.text}</div></div>
}

export default ButtonRed