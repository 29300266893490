import { useDispatch, useSelector } from "react-redux";
import ButtonRed from "../global/button/button"
import RefReady from "../global/ref"
import { COMmanaggerSTATISTIC } from "../redux/actions/managgerActions";
import { useEffect, useState } from "react";
import { ChangePassBussiness, EditNumToCall, GetBussinessToManaggerStatistic, SendEmailToBussiness } from "../axios/api";
import Search from "./Search";
import { CloseOutlined } from "@ant-design/icons";
import { CompleteThisBussiness } from "../redux/actions/thisBussinessActions";

const Statistics = () => {

    const [statistic, setStatistic] = useState([]);
    const [tempStatistic, setTempStatistic] = useState([]);
    const d = useDispatch()
    const statisticSelector = useSelector(a => a.managgerReducer.statistic)
    const [openShlucha, setOpenShlucha] = useState(false);
    const [shlucha, setShlucha] = useState('');

    const [openCode, setOpenCode] = useState(false);
    const [code, setCode] = useState('');

    const [openDel, setOpenDel] = useState(false);

    useEffect(() => {
        if (statisticSelector.length > 0) {
            setStatistic(statisticSelector)
            setTempStatistic(statisticSelector)
        }
        else {
            GetBussinessToManaggerStatistic().then((data) => {
                setStatistic(data)
                setTempStatistic(data)
                d(COMmanaggerSTATISTIC(data))
            }).catch((error) => { console.error(error); });
        }
    }, []);

    const funcSearch = (inp) => {
        setStatistic(tempStatistic.filter(a => a.name.includes(inp)))
        window.scrollTo(0, 0);
    }

    return <div>
        <Search funcSearch={funcSearch} txt={'בטבלת הסטטיסטיקה'}></Search>
        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', width: '30%', marginRight: '35%' }}>מערכת ניהול וסטטיסטיקה</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>


        {statistic && <table style={{ marginTop: '3%', width: '90%', marginRight: '5%', textAlign: 'center' }} className="animate__animated animate__bounceInUp">
            <thead>
                <tr>
                    <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}>שם העסק</th>
                    <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמה נכנסו</th>
                    <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמה שלחו מייל</th>
                    <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמה התקשרו</th>
                    <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}>טלפון למערכת הטלפונית</th>
                    <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}>מס' שלוחה</th>
                    {/* <th style={{ width: '10%' }}></th> */}
                </tr>
            </thead>
            <tbody>
                {statistic.map((bussiness, i) => (
                    <tr style={{ fontWeight: '300', textAlign: 'center', borderTop: '#c00000 1px solid', backgroundColor: `${bussiness.code === -1 ? '#555' : 'white'}` }} key={i}>
                        <td style={{ textAlign: 'center', backgroundImage: `url(data:image/jpeg;base64,${bussiness.mainImage})` }}><p style={{ backgroundColor: 'white', width: 'max-content', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor: 'pointer' }} onClick={() => { if (bussiness.code !== -1) { setOpenDel({ bussiness: bussiness, i: i }) } }}>{bussiness.name} {bussiness.code === -1 ? '- עסק כבוי' : ''}</p></td>
                        <td style={{ textAlign: 'center' }}>{bussiness.howManySeeYou}</td>
                        <td style={{ textAlign: 'center' }}>{bussiness.howManySendEmail}</td>
                        <td style={{ textAlign: 'center' }}>{bussiness.howManyCall}</td>
                        <td style={{ textAlign: 'center' }}>{bussiness.code !== -1 ? bussiness.phoneToCall : <div className='divButton divButton2' onClick={() => setOpenCode({ bussiness: bussiness, i: i })}>הפעל עסק</div>}</td>
                        <td style={{ textAlign: 'center', direction: 'ltr' }}>{bussiness.numberToCall ? bussiness.numberToCall : bussiness.phoneToCall ? <div className='divButton divButton2' onClick={() => setOpenShlucha({ bussiness: bussiness, i: i })}>נדרש למלא</div> : ''}</td>
                        {/* <td><IconButton align="left" className='IconButton' size="small" onClick={() => { if (open.includes(i)) { setOpen(open.filter((x) => x != i)); document.getElementsByTagName("tr")[num - 1].classList.remove("open"); document.getElementsByTagName("tr")[num - 1].classList.add("class3") } else setOpen([...open, i]) }}>
                            {open.includes(i) ? <CloseOutlined /> : <PlusOutlined />}
                        </IconButton></td> */}
                    </tr>
                ))}
            </tbody>
        </table>}


        {openShlucha && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenShlucha(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '50vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
                    <div className='titl' style={{ marginTop: '6%', fontSize: '120%', marginBottom: '5%' }}>מהי מספר השלוחה?</div>
                    <input className='input20 ii' style={{ width: '60%' }} autoComplete="on" type="text" value={shlucha} onChange={(e) => { if (/^[0-9 >]*$/.test(e.target.value) || e.target.value === '') { setShlucha(e.target.value) } }} />
                    <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { EditNumToCall(openShlucha.bussiness.categoryCode, openShlucha.bussiness.id, shlucha).then((data) => { var arr = statistic; arr[openShlucha.i].numberToCall = shlucha; setStatistic(arr); d(COMmanaggerSTATISTIC(arr)); setOpenShlucha(false); }).catch(error => console.log(error)) }}><ButtonRed text='אישור'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {openCode && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenCode(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '50vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
                    <div className='titl' style={{ marginTop: '6%', fontSize: '120%', marginBottom: '5%' }}>הכנס קוד לניהול העסק</div>
                    <input className='input20 ii' style={{ width: '60%' }} autoComplete="on" type="text" value={code} onChange={(e) => { if (/^[0-9]*$/.test(e.target.value) || e.target.value === '') { setCode(e.target.value) } }} />
                    <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { if (code !== '') { ChangePassBussiness(openCode.bussiness.categoryCode, openCode.bussiness.id, code).then((data) => { if (data === true) { var b = openCode.bussiness; b.code = code; d(CompleteThisBussiness(b)); var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום ל${b.name} </p></div><br/><br/> הצטרפתם מחדש למעכת קול שמחה <br/> <br/><b>סיסמתכם החדשה היא: </b> <br/> ${code} <br/> <br/>  <b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`; SendEmailToBussiness({ name: "", emailTo: b.mail, emailFrom: 'r0527117663@gmail.com', subject: 'הצטרפתם מחדש למיזם קול שמחה', message: mes }).then(() => { }).catch((error) => { console.error(error); }); setOpenCode(false) } }).catch((error) => { console.error(error); }); } }}><ButtonRed text='אישור'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {openDel && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenDel(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
                    <div className='titl' style={{ marginTop: '6%', fontSize: '120%', marginBottom: '2%' }}>האם אתה בטוח שברצונך למחוק עסק זה?</div>
                    <div className='buton1' style={{ width: '20%', marginRight: 'auto', marginLeft: '1%', marginTop: '4%', display:'inline-block' }} onClick={() => { setOpenDel(false) }}><ButtonRed text='ביטול מחיקה'></ButtonRed></div>
                    <div className='buton1' style={{ width: '20%', marginRight: '1%', marginLeft: 'auto', marginTop: '4%', display:'inline-block' }} onClick={() => { ChangePassBussiness(openDel.bussiness.categoryCode, openDel.bussiness.id, -1).then((data) => { if (data === true) { var b = openDel.bussiness; b.code = -1; d(CompleteThisBussiness(b)); setOpenDel(false) }}).catch((error) => { console.error(error); }); } }><ButtonRed text='אישור מחיקה'></ButtonRed></div>
                </div>
            </div>
        </div>}

    </div>
}

export default Statistics