// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ----------------------------start top button---------------------------- */
#topBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 800;
    font-size: 18px;
    border: none;
    outline: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    background-color: #C00000;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }
  
  #topBtn:hover {
    background-color: black;
  }
  
  /* ----------------------------end top button---------------------------- */`, "",{"version":3,"sources":["webpack://./src/components/global/onScroll/OnScroll.css"],"names":[],"mappings":"AAAA,6EAA6E;AAC7E;IACI,aAAa;IACb,eAAe;IACf,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,aAAa;IACb,yCAAyC;IACzC,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,uBAAuB;EACzB;;EAEA,2EAA2E","sourcesContent":["/* ----------------------------start top button---------------------------- */\r\n#topBtn {\r\n    display: none;\r\n    position: fixed;\r\n    bottom: 20px;\r\n    right: 30px;\r\n    z-index: 800;\r\n    font-size: 18px;\r\n    border: none;\r\n    outline: none;\r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);\r\n    background-color: #C00000;\r\n    color: white;\r\n    cursor: pointer;\r\n    padding: 15px;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  #topBtn:hover {\r\n    background-color: black;\r\n  }\r\n  \r\n  /* ----------------------------end top button---------------------------- */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
