import axios from 'axios';

// const Api = () => {
// const baseUrl = 'https://localhost:44339/api';
const baseUrl = 'https://api.kolsimcha.co.il/api';

//-----------------------------------------------------------------------------------
//                                    התחבר / הירשם
//-----------------------------------------------------------------------------------

export const getUser = (tz, pass) => {
    return axios.get(`${baseUrl}/User/GetUser?tz=${tz}&pass=${pass}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const getUserByTz = (tz) => {
    return axios.get(`${baseUrl}/User/getUserByTz?tz=${tz}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetUserById = (id) => {
    return axios.get(`${baseUrl}/User/GetUserById?id=${id}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const addTwoUsers = (users) => {
    return axios.post(`${baseUrl}/User/AddTwoUsers`, users).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddNewUser = (user) => {
    return axios.post(`${baseUrl}/User/AddNewUser`, user).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangeUserPass = (userId, newPass) => {
    return axios.put(`${baseUrl}/User/ChangeUserPass?userId=${userId}&newPass=${newPass}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangeUserReminders = (userId, reminder) => {
    return axios.put(`${baseUrl}/User/ChangeUserReminders?userId=${userId}&reminder=${reminder}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangeUserDates = (userId, newErusinDate, newWeddingDate) => {
    return axios.put(`${baseUrl}/User/ChangeUserDates?userId=${userId}&newErusinDate=${newErusinDate}&newWeddingDate=${newWeddingDate}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const UpdateUser = (userId, user) => {
    return axios.put(`${baseUrl}/User/UpdateUser?userId=${userId}`, user).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};
//-----------------------------------------------------------------------------------
//                                    תפריט
//-----------------------------------------------------------------------------------

export const getAllBigMenu = () => {
    return axios.get(`${baseUrl}/TitleTitle/GetAllBigMenu`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllPicForMenu = () => {
    return axios.get(`${baseUrl}/TitleTitle/GetAllPicForMenu`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllIcons = () => {
    return axios.get(`${baseUrl}/Menu/GetAllIcons`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllCategoryWithDevition = () => {
    return axios.get(`${baseUrl}/Menu/GetAllCategoryWithDevition`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBussinessForCategory = (categoryCode) => {
    return axios.get(`${baseUrl}/Categories/GetBussinessForCategory?categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllLogo = () => {
    return axios.get(`${baseUrl}/Bussiness/GetAllLogo`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    עסק מסוים
//-----------------------------------------------------------------------------------

export const GetBussinessData = (bussinessId, categoryCode) => {
    return axios.get(`${baseUrl}/Bussiness/GetBussinessData?bussinessId=${bussinessId}&categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddNewOption = (newOption) => {
    return axios.post(`${baseUrl}/Options/AddNewOption`, newOption).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddBussinessShop = (newB) => {
    return axios.post(`${baseUrl}/Bussiness/AddBussinessShop`, newB, { headers: { 'Content-Type': 'application/json' }}).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddBussinessWall = (newB) => {
    return axios.post(`${baseUrl}/Bussiness/AddBussinessWall`, newB, { headers: { 'Content-Type': 'application/json' }}).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddBussinessCatering = (newB) => {
    return axios.post(`${baseUrl}/Bussiness/AddBussinessCatering`, newB, { headers: { 'Content-Type': 'application/json' }}).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddBussinessSong = (newB) => {
    return axios.post(`${baseUrl}/Bussiness/AddBussinessSong`, newB, { headers: { 'Content-Type': 'application/json' }}).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetQuestions = (categoryCode) => {
    return axios.get(`${baseUrl}/Bussiness/GetQuestions?categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllCategoriesOrDevition = () => {
    return axios.get(`${baseUrl}/Categories/GetAllCategoriesOrDevition`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddCatchDate = (date) => {
    return axios.post(`${baseUrl}/Bussiness/AddCatchDate`, date).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const FinishBuy = (finishBuy) => {
    return axios.post(`${baseUrl}/Bussiness/FinishBuy`, finishBuy).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetPayId = (categoryId) => {
    return axios.get(`${baseUrl}/Categories/GetPayId?categoryId=${categoryId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangePassBussiness = (codeCategory, bussinessId, newCode) => {
    return axios.put(`${baseUrl}/Bussiness/ChangePassBussiness?codeCategory=${codeCategory}&bussinessId=${bussinessId}&newCode=${newCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetOneBussiness = (codeCategory, bussinessId) => {
    return axios.get(`${baseUrl}/Bussiness/GetOneBussiness?categoryCode=${codeCategory}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPhoneToCall = (bussinessId, categoryCode, phone) => {
    return axios.post(`${baseUrl}/Bussiness/AddPhoneToCall?bussinessId=${bussinessId}&categoryCode=${categoryCode}&phone=${phone}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelOption = (optionId) => {
    return axios.delete(`${baseUrl}/Bussiness/DelOption?optionId=${optionId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    ארגונית אישית
//-----------------------------------------------------------------------------------
export const GetComesAddress = (userId) => {
    return axios.get(`${baseUrl}/User/GetComesAddress?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetPays = (userId) => {
    return axios.get(`${baseUrl}/User/GetPays?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetPaysInit = (chatanOrKala) => {
    return axios.get(`${baseUrl}/User/GetPaysInit?chatanOrKala=${chatanOrKala}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetFinallTasks = (userId) => {
    return axios.get(`${baseUrl}/User/GetFinallTasks?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetShopBussiness = (userId) => {
    return axios.get(`${baseUrl}/User/GetShopBussiness?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPersonalTask = (dateE, dateW, personalTask) => {
    return axios.post(`${baseUrl}/User/AddPersonalTask?dateE=${dateE}&dateW=${dateW}`, personalTask).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddShopping = (shopping) => {
    return axios.post(`${baseUrl}/User/AddShopping`, shopping).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DoTask = (did) => {
    return axios.post(`${baseUrl}/User/DoTask`, did).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelPersonalTask = (id) => {
    return axios.delete(`${baseUrl}/User/DelPersonalTask?dt=${id}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const NotDoTask = (task) => {
    return axios.post(`${baseUrl}/User/NotDoTask`, task).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelGlobalTask = (taskId, userId) => {
    return axios.delete(`${baseUrl}/User/DelGlobalTask?taskId=${taskId}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPersonalPay = (pay) => {
    return axios.post(`${baseUrl}/User/AddPersonalPay`, pay).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelPersonalPay = (ppId, userId) => {
    return axios.delete(`${baseUrl}/User/DelPersonalPay?ppId=${ppId}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPersonalPaid = (pay) => {
    return axios.post(`${baseUrl}/User/AddPersonalPaid`, pay).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const UpdatePersonalPaid = (paysId, pp, userId) => {
    return axios.put(`${baseUrl}/User/UpdatePersonalPaid?paysId=${paysId}&pp=${pp}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetpersonalPriceIdBy = (paysId, userId) => {
    return axios.get(`${baseUrl}/User/GetpersonalPriceIdBy?paysId=${paysId}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddAddress = (address) => {
    return axios.post(`${baseUrl}/User/AddAddress`, address).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const CopyAddress = (userId, address) => {
    return axios.post(`${baseUrl}/User/CopyAddress?userId=${userId}`, address).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelAddress = (id, userId) => {
    return axios.delete(`${baseUrl}/User/DelAddress?id=${id}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const UpdateAddress = (id, address) => {
    return axios.put(`${baseUrl}/User/UpdateAddress?id=${id}`, address).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangeReminders = (userId, reminders) => {
    return axios.put(`${baseUrl}/User/ChangeReminders?userId=${userId}&reminders=${reminders}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddShoppingCart = (lotOfItemsId, shopping) => {
    return axios.post(`${baseUrl}/Bussiness/AddShoppingCart?lotOfItemsId=${lotOfItemsId}`, shopping).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetShopCartBussiness = (userId) => {
    return axios.get(`${baseUrl}/User/GetShopCartBussiness?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    מנהל מערכת
//-----------------------------------------------------------------------------------
export const updateCategory = (categoryId, category) => {
    return axios.put(`${baseUrl}/Categories/updateCategory?categoryId=${categoryId}`, category).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const deleteCategory = (categoryId) => {
    return axios.delete(`${baseUrl}/Categories/deleteCategory?categoryId=${categoryId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBussinessToManagger = () => {
    return axios.get(`${baseUrl}/Bussiness/GetBussinessToManagger`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBussinessToManaggerStatistic = () => {
    return axios.get(`${baseUrl}/Bussiness/GetBussinessToManaggerStatistic`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllOptions = () => {
    return axios.get(`${baseUrl}/Bussiness/GetAllOptions`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllPays = () => {
    return axios.get(`${baseUrl}/User/GetAllPays`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditPayManagger = (pay) => {
    return axios.post(`${baseUrl}/User/EditPayManagger`, pay).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPayManagger = (pay) => {
    return axios.put(`${baseUrl}/User/AddPayManagger`, pay).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DeletePayManagger = (payId) => {
    return axios.delete(`${baseUrl}/User/DeletePayManagger?payId=${payId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditTaskManagger = (task) => {
    return axios.post(`${baseUrl}/User/EditTaskManagger`, task).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddTaskManagger = (task) => {
    return axios.put(`${baseUrl}/User/AddTaskManagger`, task).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DeleteTaskManagger = (taskId) => {
    return axios.delete(`${baseUrl}/User/DeleteTaskManagger?taskId=${taskId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};
//-----------------------------------------------------------------------------------
//                                    מיונים וסינונים
//-----------------------------------------------------------------------------------
export const GetCatchDate = (date, categoryCode) => {
    return axios.get(`${baseUrl}/Bussiness/GetCatchDate?date=${date}&categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    שליחת מיילים
//-----------------------------------------------------------------------------------
export const SendEmailNewBussiness = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailNewBussiness`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailForNewUser = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailForNewUser`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailToChoozeDate = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailToChoozeDate`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailToBussiness = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailToBussiness`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    EditHowMany
//-----------------------------------------------------------------------------------
export const EditHowManySeeYou = (codeCategory, bussinessId) => {
    return axios.post(`${baseUrl}/Bussiness/EditHowManySeeYou?codeCategory=${codeCategory}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditHowManyCall = (codeCategory, bussinessId, howManyCall) => {
    return axios.post(`${baseUrl}/Bussiness/EditHowManyCall?codeCategory=${codeCategory}&bussinessId=${bussinessId}&howManyCall=${howManyCall}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditHowManySendEmail = (codeCategory, bussinessId) => {
    return axios.post(`${baseUrl}/Bussiness/EditHowManySendEmail?codeCategory=${codeCategory}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditNumToCall = (codeCategory, bussinessId, numToCall) => {
    return axios.post(`${baseUrl}/Bussiness/EditNumToCall?codeCategory=${codeCategory}&bussinessId=${bussinessId}&numToCall=${numToCall}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditPhoneToCall = (codeCategory, bussinessId, phoneToCall) => {
    return axios.post(`${baseUrl}/Bussiness/EditPhoneToCall?codeCategory=${codeCategory}&bussinessId=${bussinessId}&phoneToCall=${phoneToCall}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    Mehorasim
//-----------------------------------------------------------------------------------
export const GetAllMehorasim = () => {
    return axios.get(`${baseUrl}/MehorasimControler/GetAllMehorasim`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddMehorasim = (mehorasim) => {
    return axios.put(`${baseUrl}/MehorasimControler/AddMehorasim`, mehorasim).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditMehorasim = (mehorasim) => {
    return axios.post(`${baseUrl}/MehorasimControler/EditMehorasim`, mehorasim).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};
