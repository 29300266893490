import './TaskList.css';
import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ButtonRed from '../../global/button/button';
import BottomMenu from '../../global/bottomMenu/BottomMenu';
import { useNavigate } from 'react-router-dom';
import Alert from '../../global/alert/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { GetShopBussiness } from '../../axios/api';
import { IconButton } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';
import { COMPLETEorders } from '../../redux/actions/ordersActions';


const ChoozedBussness = () => {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  const navigate = useNavigate()
  const s = useSelector(a => a.userReducer)
  const [weddingDate, setWeddingDate] = useState(false);
  const [shopBussiness, setShopBussiness] = useState();
  const [plus, setPlus] = useState([]);
  const d = useDispatch()
  const orders = useSelector(a => a.ordersReducer.orders)


  useEffect(() => {
    setWeddingDate(Math.ceil((new Date(s.weddingDate) - new Date()) / (24 * 60 * 60 * 1000)));
  }, [s]);


  useEffect(() => {
    if (orders.length > 0) {
      setShopBussiness(orders)
    }
    else {
      GetShopBussiness(s.id).then((data) => {
        setShopBussiness(data)
        d(COMPLETEorders(data))
      }).catch((error) => { console.error(error); });
    }
  }, [s]);

  // -----------------------start ref-----------------------------
  const [isVisible, setIsVisible] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, // Triggers the animation only once
    threshold: 0.1, // Percentage of visibility needed to trigger the animation
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  // -----------------------end ref-----------------------------

  const [alert, setAlert] = useState(false)

  return <div>
    <p ref={ref}
      style={{
        fontSize: '150%', fontWeight: '500', marginTop: '8%',
        transform: isVisible ? 'translateY(0)' : 'translateY(500px)', // Animating the 'Y' position of the div
        opacity: isVisible ? 1 : 0, // Fading in the div
        transition: 'transform 0.5s, opacity 0.5s', // Adding smooth transition
      }}>נותני השירות שבחרתם</p>

    <div ref={ref} style={{ transition: 'width 0.5s ease-in-out' }} className={`underline3 ${isVisible ? "visible3" : ""} `}></div>

    {s.id && weddingDate > 0 && <div style={{ position: 'fixed', left: '5%', marginTop: '-8%', backgroundColor: 'white' }}>{s.chatanOrKala}, בעוד {weddingDate} ימים החתונה!</div>}

    <div style={{ width: '17%', position: 'fixed', marginTop: '0' }}>
      <div style={{ width: '90%', marginRight: '10%' }}>
        <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/taskList`)}><ButtonRed text='רשימת מטלות'></ButtonRed></div>
        <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/choozedBussness`)}><ButtonRed is='this' text='ההזמנות שלי'></ButtonRed></div>
        <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/howManyToPay`)}><ButtonRed text='ארגון הוצאות'></ButtonRed></div>
        {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth <= 1300 && <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed text='רשימת כתובות'></ButtonRed></div>}
        {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth > 1300 && <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed text='רשימת מוזמנים וכתובות'></ButtonRed></div>}
        <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/PersonalArea`)}><ButtonRed text='איזור אישי'></ButtonRed></div>
      </div>
    </div>

    <div className='buton1' style={{ width: '15%', position: 'absolute', top: '21%', left: '4.8%' }} onClick={() => navigate(`/Shopping`)}><ButtonRed text='סל הקניות שלי'></ButtonRed></div>


    <table style={{ marginTop: '2%', width: '70%' }}>
      <thead>
        <tr style={{ textAlign: 'center' }}>
          <th style={{ width: '15%' }}>תאריך ההזמנה</th>
          <th style={{ width: '20%' }}>נותן השירות</th>
          <th style={{ width: '35%' }}>פרטי ההזמנה</th>
          <th style={{ width: '15%' }}>יצירת קשר</th>
          {/* <th style={{ width: '20%' }}>קיבלתי הטבה</th> */}
          <th style={{ width: '15%' }}>סכום לתשלום</th>
          {/* <th style={{ width: '20%' }}>האם שולם?</th> */}
        </tr>
      </thead>
      {shopBussiness && <tbody>
        {shopBussiness.map((x, i) => (
          <Fragment key={i}><tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
            <td>{x.date.split('T')[0]}</td>
            <td>{x.nameBussiness}</td>
            {x.detailsShopping.length > 0 && <td>
              <IconButton align="left" className='IconButton' size="big" onClick={() => { if (plus.includes(i)) { setPlus(plus.filter((x) => x != i)); } else setPlus([...plus, i]) }}>
                {plus.includes(i) ? <CloseOutlined /> : 'הצג פרטים'}
              </IconButton>
              {plus.includes(i) && <div style={{ marginTop: '5%' }}>{x.detailsShopping.map((y, j) => (
                <div key={j} className='proAll' style={{ border: 'rgb(128, 14, 14) 2px solid', marginTop: '1%', position: 'relative', width: '100%', padding: '0', minHeight: 'fit-content' }}>
                  {/* <div style={{ height: '11.6vh', display: 'inline-block', width: '25%', textAlign: 'center', paddingTop: '4%', float: 'right', cursor:'auto' }}>{y.name}</div> */}
                  <div style={{ height: '12vh', display: 'inline-block', marginRight: '5%', textAlign: 'right', width: '75%', lineHeight: '5px', fontWeight: '300' }} className='prudu2'><p>{y.name}</p><b>מחיר: </b>{y.price} ש"ח <p><b>כמות: </b>{y.amount}</p></div>
                  <img src={`data:image/jpeg;base64,${y.picture}`} className='proPic2'></img>
                </div>
              ))}</div>}
            </td>}
            {x.detailsShopping.length === 0 && <td>
              אין פרטים
            </td>}
            {/* {plus[i] && <td>{x.detailsShopping.length > 0 && <>{x.detailsShopping.map((y, j) => (
              <div key={j} style={{ border: 'rgb(128, 14, 14) 2px solid', height: '12vh', marginTop: '1%', position: 'relative', width: '90%' }}>
                <div className='added' style={{ height: '11.6vh', display: 'inline-block', width: '25%', textAlign: 'center', paddingTop: '4%', float: 'right' }}>{y.name}</div>
                <div style={{ height: '12vh', display: 'inline-block', marginRight: '2%', textAlign: 'center', width: '50%' }} className='prudu2'>{y.name}<br></br>{y.description}<br></br>{x.price} ש"ח</div>
                <img src={`data:image/jpeg;base64,${y.picture}`} className='proPic2'></img>
              </div>
            ))}</>}</td>} */}
            <td className='kishur' style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => setAlert(x)}>שלח הודעה</td>
            <td>{x.sum} ש"ח</td>
          </tr>
            {/* <table style={{width:'100vh'}}>
              <thead>
                <tr>
                  <th>dsg</th>
                  <th>fdsghf</th>
                  <th>gfshfg</th>
                </tr>
              </thead>
            </table>
            <tbody> */}

            {/* </tbody> */}

          </Fragment>
        ))}
      </tbody>}
    </table>

    {alert && <Alert set={setAlert} text={alert.nameBussiness} mail={alert.mailBussiness}></Alert>}

    <div style={{ width: '100%', height: '800px' }}></div>



    <BottomMenu></BottomMenu>
  </div>
}

export default ChoozedBussness