const tasks = {
    allTasks: [],
    tasksYes: 0,

};

const tasksReducer = (state = tasks, action) => {
    switch (action.type) {
        case "COMPLETEallTasks":
            return { ...state, allTasks: action.payload };
        case "COMPLETEtasksYes":
            return { ...state, tasksYes: action.payload };
        default:
            return state;
    }
};



export default tasksReducer;
