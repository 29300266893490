import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import React from 'react';
import { GetAllCategoryWithDevition, deleteCategory, updateCategory } from '../axios/api';
import OnScroll from '../global/onScroll/OnScroll';
import BottomMenu from '../global/bottomMenu/BottomMenu';
import AlertDelete from '../global/alert/AlertDelete';
import ButtonRed from '../global/button/button';
import './Managger.css'
import Wait from '../global/Wait';
import Search from './Search';

const Categories = () => {

    const [categories, setCategories] = useState()
    const [tempCategories, setTempCategories] = useState()
    const [wait, setWait] = useState(true);

    useEffect(() => {
        GetAllCategoryWithDevition().then((data) => {
            setCategories(data)
            setTempCategories(data)
            setWait(false)
        }).catch((error) => { console.error(error); });
    }, []);

    // -----------------------start ref-----------------------------
    const [isVisible, setIsVisible] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: true, // Triggers the animation only once
        threshold: 0.1, // Percentage of visibility needed to trigger the animation
    });

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);
    // -----------------------end ref-----------------------------

    //--------------------------------התחלת תמונות----------------------------
    const clickPic = (e) => {
        const fileSelect = document.getElementById("fileSelect");
        const fileElem = document.getElementById("fileElem");
        fileSelect.addEventListener("click", fileElem.click()
            // (e) => { if (fileElem) { fileElem.click(); } },
            // false,
        );
    }

    const clickPic2 = (e) => {
        const fileSelect = document.getElementById("fileSelect2");
        const fileElem = document.getElementById("fileElem2");
        fileSelect.addEventListener("click", fileElem.click()
            // (e) => { if (fileElem) { fileElem.click(); } },
            // false,
        );
    }



    const changePic = async (e, pict, which) => {
        if (!e.target.files.length) {
            setCurrentCate({ ...currentCate, [which]: pict })
        } else {
            const file = e.target.files[0];
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const image4 = new Image();
            image4.src = URL.createObjectURL(file);
            image4.onload = function () {
                canvas.width = 300;
                canvas.height = (300 * image4.height) / image4.width;
                ctx.drawImage(image4, 0, 0, 300, (300 * image4.height) / image4.width);
                const resizedImage = canvas.toDataURL('image/png');
                const base64Image = resizedImage.split(',')[1]
                setCurrentCate({ ...currentCate, [which]: base64Image });
            };
            // const fileReader = new FileReader();
            // fileReader.onload = fileLoadedEvent => {
            //     const base64ImageAll = fileLoadedEvent.target.result;
            //     const base64Image = base64ImageAll.split(',')[1]
            //     setCurrentCate({ ...currentCate, [which]: base64Image });
            // };
            // fileReader.readAsDataURL(file);
        }
    }

    //--------------------------------סיום תמונות----------------------------

    const [currentCate, setCurrentCate] = useState({ code: "", name: "", titleId: "", pic: "", icon: "" })
    const [open, setOpen] = useState(false)
    const [updateAd, setUpdateAd] = useState(false);
    const [delAd, setDelAd] = useState(false);
    const navigate = useNavigate()

    const dellCategory = () => {
        deleteCategory(delAd).then((data) => {
            setCategories(categories.filter(c => c.id != delAd))
            setTempCategories(categories.filter(c => c.id != delAd))
        }).catch((error) => { console.error(error); });
        setDelAd(false)
    }

    const funcSearch = (inp) => {
        setCategories(tempCategories.filter(a => a.name.includes(inp)))
        window.scrollTo(0, 0);
    }

    return <div>
        <Search funcSearch={funcSearch}></Search>
        <p ref={ref}
            style={{
                fontSize: '150%', fontWeight: '500', marginTop: '8%',
                transform: isVisible ? 'translateY(0)' : 'translateY(500px)', // Animating the 'Y' position of the div
                opacity: isVisible ? 1 : 0, // Fading in the div
                transition: 'transform 0.5s, opacity 0.5s', // Adding smooth transition
            }}>כל הקטגוריות</p>

        <div ref={ref} style={{ transition: 'width 0.5s ease-in-out' }} className={`underline3 ${isVisible ? "visible3" : ""} `}></div>


        <div style={{ width: '15%', position: 'fixed', marginTop: '0' }}>
            <div style={{ width: '80%', marginRight: '10%' }}>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/taskList`)}><ButtonRed text='כל הקטגוריות'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/choozedBussness`)}><ButtonRed text='כל החלוקות'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/howManyToPay`)}><ButtonRed text='קטגוריות עם חלוקות'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed is='this' text='מחולק לפי כותרות'></ButtonRed></div>
            </div>
        </div>

        <table style={{ marginTop: '2%' }}>
            <thead>
                <tr>
                    <th style={{ width: '30%', textAlign: 'center' }}>שם הקטגוריה</th>
                    <th style={{ width: '30%', textAlign: 'center' }}>תמונה</th>
                    <th style={{ width: '30%', textAlign: 'center' }}>אייקון</th>
                </tr>
            </thead>
            <tbody>{categories && <>
                {categories.map((x, i) => (
                    <tr key={i} style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        {x.id !== updateAd.id && <Fragment>
                            <td style={{ textAlign: 'center', height: '200%' }}>{x.name}</td>
                            <td style={{ textAlign: 'center', height: '200%' }}>{x.pic && <img className='logo' src={`data:image/jpeg;base64,${x.pic}`}></img>}{!x.pic && <div>אין תמונה</div>}</td>
                            <td style={{ textAlign: 'center', height: '200%' }}>{x.icon && <img className='logo' src={`data:image/jpeg;base64,${x.icon}`}></img>}{!x.icon && <div>אין אייקון</div>}</td>
                            <td><IconButton onClick={() => { setUpdateAd(x); setCurrentCate(x) }} className='IconButton' size="small">
                                <EditOutlined />
                            </IconButton></td>
                            <td>{x.code !== 1 && x.code !== 82 && x.code !== 2 && x.code !== 3 && x.code !== 4 && <IconButton onClick={() => setDelAd(x.id)} className='IconButton' size="small">
                                <CloseOutlined />
                            </IconButton>}</td>
                        </Fragment>}
                        {updateAd.id == x.id && <Fragment>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={currentCate.name} onChange={(e) => setCurrentCate({ ...currentCate, name: e.target.value })} defaultValue={x.name}></input></td>
                            <td style={{ position: 'relative', textAlign: 'center' }}>
                                {currentCate.pic && <img className='picUp' src={`data:image/jpeg;base64,${currentCate.pic}`}></img>}{!currentCate.pic && <div style={{ display: 'inline-block' }}>אין תמונה</div>}
                                <input type="file" id="fileElem" accept="image/*" onChange={(e) => changePic(e, x.pic, 'pic')} style={{ display: 'none' }} />
                                <input type="button" className="choozePic" id="fileSelect" onClick={clickPic} value="בחר תמונה" />
                            </td>
                            <td style={{ position: 'relative', textAlign: 'center' }}>
                                {currentCate.icon && <img className='picUp' src={`data:image/jpeg;base64,${currentCate.icon}`}></img>}{!currentCate.icon && <div style={{ display: 'inline-block' }}>אין אייקון</div>}
                                <input type="file" id="fileElem2" accept="image/*" onChange={(e) => changePic(e, x.icon, 'icon')} style={{ display: 'none' }} />
                                <input type="button" className="choozePic" id="fileSelect2" onClick={clickPic2} value="בחר תמונה" />
                            </td>
                            <div onClick={() => { updateCategory(x.id, { code: x.code, name: currentCate.name, titleId: x.titleId, pic: currentCate.pic, icon: currentCate.icon }).then(data => { setCategories(prev => { const updatedCategories = prev.map(category => { if (category.id === x.id) { return { ...category, name: currentCate.name, pic: currentCate.pic, icon: currentCate.icon } } else { return category } }); return updatedCategories; }); setTempCategories(prev => { const updatedCategories = prev.map(category => { if (category.id === x.id) { return { ...category, name: currentCate.name, pic: currentCate.pic, icon: currentCate.icon } } else { return category } }); return updatedCategories; }) }).catch(error => console.error(error)); setCurrentCate({ name: '', pic: '', icon: '' }); setUpdateAd(false) }} style={{ width: '150%', marginTop: '32%', marginRight: '10%' }}><ButtonRed text='אישור'></ButtonRed></div>
                        </Fragment>}

                    </tr>
                ))}</>}

                {/* {open && <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={currentCate.name} onChange={(e) => setMan({ ...currentCate, name: e.target.value })}></input></td>
                    <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={currentCate.address} onChange={(e) => setMan({ ...currentCate, address: e.target.value })} ></input></td>
                    <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={currentCate.city} onChange={(e) => setMan({ ...currentCate, city: e.target.value })} ></input></td>
                    <td><IconButton onClick={() => { AddAddress({ name: currentCate.name, address1: currentCate.address, city: currentCate.city, userId: s.id }).then(data => { setAllComes(data) }).catch(error => console.error(error)); setMan({ name: '', address: '', city: '' }) }} className='IconButton' size="small">
                        <CheckOutlined />
                    </IconButton>
                        <IconButton onClick={() => setOpen(false)} className='IconButton' size="small">
                            <CloseOutlined />
                        </IconButton></td>
                </tr>} */}
            </tbody>
        </table>
        <div onClick={() => setOpen(true)} className='buton' style={{ marginTop: '0', float: 'left', marginLeft: '20%', width: '9%' }}><ButtonRed text='הוסף קטגוריה'></ButtonRed></div>



        {delAd && <AlertDelete set={setDelAd} func={dellCategory} text={'קטגוריה'}></AlertDelete>}
        {/* {updateAd && <AlertAddAddress set={setUpdateAd} func={setAllComes}></AlertAddAddress>} */}
        {wait && <Wait></Wait>}

    </div>
}

export default Categories
