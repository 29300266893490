import './Carousel.css'
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
// import { MobileStepper } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { CloseOutlined } from '@ant-design/icons';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


function Carousel(prop) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = prop.pics.length;

  const handleNext = () => {
    if (activeStep >= prop.pics.length - 1) {
      setActiveStep(0)
    }
    else
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      setActiveStep(prop.pics.length - 1)
    }
    else
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };


  const elementRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // if(entry.target.classList.contains('k1'))
          entry.target.classList.add('k11')
          // if(entry.target.classList.contains('k2'))
          // entry.target.classList.add('k22')
        }
      });
    }, { threshold: 0.8 });

    if (elementRef.current) { observer.observe(elementRef.current); }

    return () => { if (elementRef.current) { observer.unobserve(elementRef.current); } };
  }, []);

  const [imageZoom, setImageZoom] = React.useState(-1)

  return (
    <div style={{ direction: 'rtl', marginTop:'-3%' }}>
      <div>
        <Box>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'ltr' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {prop.pics.map((step, index) => (
              <div key={index}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <div style={{ direction: 'ltr' }}>
                    <div style={{ width: '24%', marginRight: '1%', height: '30vh', display: 'inline-block', backgroundColor: 'white', opacity: '0.4', zIndex: '100' }}><Box
                      component="img"
                      sx={{
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        width: '100%',
                        marginTop: '30vh'
                      }}
                      src={`data:image/jpeg;base64,${prop.pics[index < prop.pics.length - 1 ? index + 1 : 0].picture}`}
                      alt={index < prop.pics.length - 1 ? index + 1 : 0}
                    /></div>
                    <Box onClick={()=>{setImageZoom(index)}}
                      component="img"
                      sx={{
                        height: '60vh',
                        // maxHeight: '800px',
                        display: 'inline-block',
                        // maxWidth: '60%',
                        overflow: 'hidden',
                        width: '50%',
                        // objectFit: 'contain',
                      }}
                      src={`data:image/jpeg;base64,${step.picture}`}
                      alt={index}
                    />
                    <div style={{ width: '24%', marginLeft: '1%', height: '30vh', display: 'inline-block', backgroundColor: 'white', opacity: '0.4', zIndex: '100' }}><Box
                      component="img"
                      sx={{
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        width: '100%',
                        marginTop: '30vh'
                      }}
                      src={`data:image/jpeg;base64,${prop.pics[index > 0 ? index - 1 : prop.pics.length - 1].picture}`}
                      alt={index > 0 ? index - 1 : prop.pics.length - 1}
                    /></div></div>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={<div className='nextButton' onClick={handleNext}>&#10219;</div>}
            backButton={<div className='backButton' onClick={handleBack}>&#10218;</div>}
          />
        </Box></div>

        {imageZoom !== -1 && <div id='modal2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <div style={{color:'white'}}>
                <CloseOutlined onClick={() => setImageZoom(-1)} style={{ cursor: 'pointer', position: 'absolute', left: '5%', marginTop: '1.5%', color: 'white' }} />
                <div onClick={() => {setImageZoom((prev) => { return prev < prop.pics.length - 1 ? prev + 1 : 0})}} style={{position:'absolute', top:'40%', left:'4%', fontSize:'200%', cursor:'pointer'}}>&#10219;</div>
                <img className='animate__animated animate__zoomIn' style={{ height: '90vh', width: 'auto', display: 'inline-block', marginTop: '5vh' }} src={`data:image/jpeg;base64,${prop.pics[imageZoom].picture}`}></img>
                <div onClick={() => {setImageZoom((prev) => { return prev > 0 ? prev - 1 : prop.pics.length - 1})}} style={{position:'absolute', top:'40%', right:'4%', fontSize:'200%', cursor:'pointer'}}>&#10218;</div>
            </div>
        </div>}
    </div>
  );
}

export default Carousel;