import { CloseOutlined } from '@ant-design/icons';
import './Option.css'
import { useEffect, useRef, useState } from 'react';
import ButtonRed from '../../button/button';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ReactStars from 'react-stars'
import React from 'react'
import { AddNewOption } from '../../../axios/api';
import { useDispatch, useSelector } from 'react-redux';
// import { message } from 'antd';
// import { CompleteOptionsBussiness } from '../../../redux/actions/thisBussinessActions';
import Connection from '../../../pages/connection/Connection';
import Login from '../../../pages/login/Login';

//----------------------------צריך לטפל שורות 10 17 51 104 ---------------------------
const AddOption = (prop) => {
    const [mail, setMail] = useState({ kochavim: '', time: '', name: '', massage: '' });
    const [errorMessage, setErrorMessage] = useState('');
    // const [messageApi, contextHolder] = message.useMessage();
    const [opUser, setOpUser] = useState(false)
    const [login, setLogin] = useState(false);
    const [connection, setConnection] = useState(false);

    const d = useDispatch()
    const user = useSelector((state) => state.userReducer)

    const formatDate = (date) => {
        return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    };

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Only letters allowed
        if(inputValue.length <= 20)
            if (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') {
                setMail({ kochavim: mail.kochavim, time: mail.time, name: inputValue, massage: mail.massage });
            }
    };

    const handleMassageChange = (e) => {
        const inputValue = e.target.value;
        if(inputValue.length <= 180)
            setMail({ kochavim: mail.kochavim, time: mail.time, name: mail.name, massage: inputValue });
    };

    const handleRatingChange = (newRating) => {
        setMail({ kochavim: newRating, time: mail.time, name: mail.name, massage: mail.massage });
    };

    const handleSubmit = (e) => {
        setErrorMessage('');

        // Check if all fields are filled
        if (mail.kochavim === '' || mail.name === '' || mail.massage === '') {
            setErrorMessage('כל השדות הן חובה');
        } else {
            if (user.id == undefined) {
                // messageApi.open({
                //     type: 'error',
                //     content: 'עדיין לא התחברת למערכת, לא ניתן לקבל חוות דעת ממשתמש לא מזוהה',
                // });
                setOpUser(true)
            }
            else {
                const op = { date: new Date().toISOString(), name: mail.name, description: mail.massage, kochvim: mail.kochavim, categoryCode: prop.categoryCode, bussinessId: prop.bussinessId, userId: user.id }
                debugger
                AddNewOption(op).then((data) => {
                    // d(CompleteOptionsBussiness(data))
                    prop.setOP(data)
                    debugger
                }).catch((error) => { console.error(error); });
                prop.set(false)
            }
        }
    };




    const ref2 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible3')
                }
            });
        });

        if (ref2.current) { observer.observe(ref2.current); }

        return () => {
            if (ref2.current) { observer.unobserve(ref2.current); }
        };
    }, []);



    return <div id='modal'>
        <div id='formCon' className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close' onClick={() => prop.set(false)} style={{ cursor: 'pointer', position: 'absolute', left: '1.5%', marginTop: '1.5%' }} />
            <div style={{ fontSize: '140%', paddingTop: '4%', fontWeight: '300', paddingBottom: '5px' }}>מהי חוות דעתך על נותן השירות?</div>
            <div ref={ref2} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3 bottom5'></div>
            <div style={{ paddingLeft: '46.8%' }}><ReactStars onChange={handleRatingChange} value={mail.kochavim} count={5} half={false} size={30} color2={'#FF7D7D'} /></div>
            <input className='input input2 input4' placeholder="שם" autoComplete="on" type="text" value={mail.name} onChange={handleNameChange} style={{ width: '15%', marginRight: '42.5%', marginTop: '20px' }} />
            <textarea onChange={handleMassageChange} value={mail.massage} style={{ width: '700px', height: '100px', marginTop: '3%', padding: '0.5%' }} placeholder='מה דעתך על נותן השירות?'></textarea>

            {errorMessage && <p style={{ position: 'absolute', bottom: '20%', width: '20%', right: '40%' }}><p style={{ marginLeft: '2%', color: '#FFC000', display: 'inline-block', marginTop: '-10px' }}><AnnouncementIcon /></p>{errorMessage}</p>}

            <div onClick={() => handleSubmit()} className='buttonRed' style={{ width: '20%', display: 'inline-block', position: 'absolute', backgroundColor: 'white', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', left: '40%', border: '#FF7D7D 5px solid', bottom: '-25px' }}><ButtonRed text={'שליחה'}></ButtonRed></div>
        </div>
        {/* {contextHolder} */}
        {opUser && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpUser(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
                    <div className='titl' style={{ marginTop: '8%', fontSize: '120%', marginBottom: '5%' }}>עדיין לא התחברת למערכת, לא ניתן לקבל חוות דעת ממשתמש לא מזוהה</div>
                    <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setLogin(true)}><ButtonRed text='התחבר'></ButtonRed></div>
                    <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setConnection(true)}><ButtonRed text='הירשם'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {login && <Login setLogin={(bool)=>{setLogin(false); setOpUser(false)}}></Login>}
        {connection && <Connection set={(bool)=>{setConnection(false); setOpUser(false)}}></Connection>}
    </div>
}

export default AddOption

