import { useDispatch, useSelector } from "react-redux";
import { styled } from 'styled-components';
import './Bussiness.css'
import RefReady from "../ref";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import EditBussiness from "./ZEditBussiness/EditBussiness";
import BottomMenu from "../bottomMenu/BottomMenu";

import icon1 from "../../../pic/animations/115.gif";
import icon2 from "../../../pic/animations/16.gif";
import icon3 from "../../../pic/animations/135.gif";
import icon4 from "../../../pic/animations/137.gif";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import ButtonRed from "../button/button";
import pic2 from '../../../pic/logo/444.jpg'
import picError from '../../../pic/animations/113.gif'
import { AddPhoneToCall, SendEmailToBussiness } from "../../axios/api";
import { CompleteUser } from "../../redux/actions/userActions";
import { COMPLETEphone, CompleteBussiness } from "../../redux/actions/bussinessActions";





const ImageDiv = styled.div`
        background-image: url(${props => props.pic}); 
    `;

const Kochavit = () => {
    const s = useSelector((state) => state.thisBussinessReducer)
    const navigate = useNavigate()
    const [numForChange, setNumForChange] = useState(false)
    const p = useParams()
    const [numUsersGood, setNumUsersGood] = useState(0)
    const [addZahav, setAddZahav] = useState(false)
    const [error, setError] = useState(false)
    const [phone, setPhone] = useState("")
    const d = useDispatch()

    useEffect(() => {
        navigate(`/Kochavit/${p.categoryName}/${p.cc}`)
    }, [numForChange])

    useEffect(() => {
        var sum = 0
        s.bussinessData?.options.forEach(element => {
            if (element.kochvim > 3)
                sum += 1
        });
        setNumUsersGood(sum)
    }, [])

    // const startWorker = () => {
    //     if(typeof(Worker) !== "undefined") {
    //       if(typeof(w) == "undefined") {
    //         w = new Worker("demo_workers.js");
    //       }
    //       w.onmessage = function(event) {
    //         document.getElementById("result").innerHTML = event.data;
    //       };
    //     } else {
    //       document.getElementById("result").innerHTML = "Sorry, your browser does not support Web Workers...";
    //     }
    //   }


    return <div>
        <div id="picBussinessOut">
            <div style={{ position: 'relative' }}>
                <ImageDiv id="picBussiness" pic={`data:image/jpeg;base64,${s.mainImage}`} style={{ width: '100%', height: '40vh', backgroundSize: 'cover', transition: '15s' }}></ImageDiv>
                <div style={{ position: 'absolute', backgroundColor: '#9a6a6a7d', width: '100%', height: '100%', top: '0', paddingTop: '26vh', fontSize: '200%', fontWeight: '400', WebkitTextStrokeColor: 'white', WebkitTextStrokeWidth: '1.1px' }}>ניהול העסק {s.name}</div>
            </div>

            <div onClick={() => { navigate(`/bussiness/${p.categoryName}/${s.name}/${s.categoryCode}`) }} style={{ backgroundColor: 'rgb(218, 166, 166)', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', borderRadius: '4px', cursor: 'pointer', width: '5%', height: '6vh', paddingTop: '10px', position: 'absolute', top: '41vh', right: '1vh' }}><ArrowRightOutlined /></div>

            {/* להוסיף בשלב שני */}
            {/* <div style={{ marginTop: '5%', width: '80%', marginRight: '10%' }}>
                <div className="ribuha">
                    <div className="inRibuha">מספר המבקרים בעמוד השיווק</div>
                    <div id="ribuha1" className="inRibuhaDown" style={{ fontSize: '200%', paddingTop: '2%' }} onClick={(e) => { var num = 0; const div = document.getElementById('ribuha1'); for (let i = 0; i < 42; i++) { setTimeout(() => { num = num + 1; div.innerText = num }, 10000); } }}>{s.howManySeeYou}</div>
                </div>

                <div className="ribuha">
                    <div className="inRibuha">מספר הפניות במייל</div>
                    <div className="inRibuhaDown" style={{ fontSize: '200%', paddingTop: '2%' }}>{s.howManySendEmail}</div>
                </div>

                <div className="ribuha">
                    <div className="inRibuha">מספר הפניות הטלפוניות (אולי קישור לימות המשיח)</div>
                    <div className="inRibuhaDown" style={{ fontSize: '200%', paddingTop: '2%' }}>{s.howManyCall}</div>
                </div>

                <div className="ribuha">
                    <div className="inRibuha">מספר לקוחות מרוצים</div>
                    <div className="inRibuhaDown" style={{ fontSize: '200%', paddingTop: '2%' }}>{numUsersGood}</div>
                </div>
            </div> */}
        </div>

        <div style={{ width: '100%', backgroundColor: 'rgb(245, 238, 238)', marginTop: '4%', marginBottom: '3%', paddingTop:'4%' }}>

            {/* <p style={{ fontSize: '150%', fontWeight: '500', paddingTop: '5%', width: '30%', marginRight: '35%' }}>עריכת פרטי העסק</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady> */}

            <div>
                <div className="iconGlobal1" onClick={() => { setNumForChange(1) }}>
                    <img src={icon1} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                    <p style={{ height: '8vh' }}>שינוי סיסמת מנהל</p>
                </div>
                <div className="iconGlobal1" onClick={() => { navigate(`/AddToDataBase/${2}/${s.categoryCode}`) }}>
                    <img src={icon2} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                    <p style={{ height: '8vh' }}>שינוי פרטי העסק</p>
                </div>
                <div className="iconGlobal1" onClick={() => { navigate(`/AddToDataBase/${3}/${s.categoryCode}`) }}>
                    <img src={icon3} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                    <p style={{ height: '8vh' }}>החלפת תמונות השייכות לעסק</p>
                </div>
                {(p.cc === "1" || p.cc === "82" || p.cc === "2" || p.cc === "3" || p.cc === "4" || p.cc === "132" || p.cc === "133") &&
                    <div className="iconGlobal1" onClick={() => { navigate(`/AddToDataBase/${4}/${s.categoryCode}`) }}>
                        <img src={icon4} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                        <p style={{ height: '8vh' }}>הוספה והסרה של תאריכים מלאים</p>
                    </div>}
                {/* <div className="iconGlobal1" onClick={() => { navigate(`/AddToDataBase/${4}/${s.categoryCode}`) }}>
                    <img src={icon4} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                    <p style={{ height: '8vh' }}>שינוי פרטים נוספים</p>
                </div> */}
            </div>
        </div>

        <div style={{ marginBottom: '5%' }}>
            <p style={{ fontSize: '150%', fontWeight: '500', paddingTop: '3%', width: '30%', marginRight: '35%' }}>ניהול המערכת הטלפונית</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {!s.phoneToCall && <div style={{ marginTop: '3%' }}>
                <p>הינכם רשומים כעת לחבילת קול ששון.</p>
                <p style={{ fontWeight: '400' }}>כדי שנבנה עבורכם שלוחות מידע ויצירת קשר במערכת הטלפונית ותתחילו לקבל טלפונים מלקוחות -</p>
                <p>הירשמו לחבילת קול שמחה - בעלות נוספת של 70 ש"ח בחודש</p>
                <div className='buton1' style={{ width: '20%', marginRight: '40%' }} onClick={() => { setAddZahav(true) }}><ButtonRed text='הצטרפות לחבילת קול שמחה'></ButtonRed></div>
            </div>}

            {s.phoneToCall && <div style={{ marginTop: '3%' }}>
                <div className='buton1' style={{ width: '30%', marginRight: '35%' }} onClick={() => { setPhone(s.phoneToCall); setAddZahav(true); }}><ButtonRed text='ערוך את מספר הטלפון למערכת הטלפונית'></ButtonRed></div>
            </div>}
        </div>

        <hr style={{ backgroundColor: '#555' }}></hr>

        <div>
            <p style={{ fontSize: '150%', fontWeight: '500', paddingTop: '2%', width: '30%', marginRight: '35%' }}>ניהול מערכת הזמנות דרך האתר</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {s.bussinessData?.lotOfItems && s.bussinessData?.lotOfItems.length <= 0 && s.phoneToCall && <div style={{ marginTop: '3%' }}>
                <p>הינכם רשומים כעת לחבילת קול שמחה</p>
                <p style={{ fontWeight: '400' }}>כדי להתחיל למכור דרך האתר חבילות / שירותים / מוצרים - </p>
                <p>הירשמו לחבילת קול מצהלות - בעלות נוספת של 280 ש"ח בחודש</p>
                <div className='buton1' style={{ width: '20%', marginRight: '40%' }} onClick={() => { navigate(`/AddToDataBase/${10}/${s.categoryCode}`) }}><ButtonRed text='הצטרפות לחבילת קול מצהלות'></ButtonRed></div>
            </div>}

            {s.bussinessData?.lotOfItems && s.bussinessData?.lotOfItems.length <= 0 && !s.phoneToCall && <div style={{ marginTop: '3%' }}>
                <p>הינכם רשומים כעת לחבילת קול ששון</p>
                <p style={{ fontWeight: '400' }}>כדי שנבנה עבורכם שלוחות מידע ויצירת קשר במערכת הטלפונית ותתחילו לקבל טלפונים מלקוחות</p>
                <p style={{ fontWeight: '400' }}>וכדי להתחיל למכור דרך האתר חבילות / שירותים / מוצרים - </p>
                <p>הירשמו לחבילת קול מצהלות - בעלות נוספת של 350 ש"ח בחודש</p>
                <div className='buton1' style={{ width: '20%', marginRight: '40%' }} onClick={() => { navigate(`/AddToDataBase/${11}/${s.categoryCode}`) }}><ButtonRed text='הצטרפות לחבילת קול מצהלות'></ButtonRed></div>
            </div>}

            {s.bussinessData?.lotOfItems && s.bussinessData?.lotOfItems.length > 0 && <div style={{ marginTop: '3%' }}>
                <div className='buton1' style={{ width: '30%', marginRight: '35%' }} onClick={() => { navigate(`/AddToDataBase/${5}/${s.categoryCode}`) }}><ButtonRed text='ערוך חבילות / שירותים / מוצרים למכירה'></ButtonRed></div>
            </div>}
        </div>



        <BottomMenu></BottomMenu>

        {numForChange && <EditBussiness num={numForChange} set={setNumForChange}></EditBussiness>}

        {addZahav && <>
            <img style={{ position: 'absolute', top: '0', right: '0', width: '100%', height: 'auto', zIndex: '800' }} src={pic2}></img>
            <div id='modal2'>
                <div className='animate__animated animate__zoomIn'>
                    <div className='glow' style={{ width: '30%', position: 'relative' }}>
                        <CloseOutlined className='close2' onClick={() => setAddZahav(false)} style={{ cursor: 'pointer', position: 'absolute', left: '4%', top: '2%', zIndex: '300', color: 'white' }} />
                        <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%' }}>חבילת קול שמחה</p>
                        <p className='titl' style={{ marginTop: '20%' }}>הכניסו מספר טלפון למערכת הטלפונית</p>
                        <input className='input20' id='passManag' style={{ width: '300px' }} onChange={(e) => { if (/^[0-9]*$/.test(e.target.value)) { setPhone(e.target.value) } }} onClick={(e) => e.target.style.width = '90%'} onBlur={(e) => e.target.style.width = '50%'} placeholder="מספר טלפון" type="text" value={phone} />
                        <div id='submit30' onClick={() => {
                            if (phone.length != 10 && phone.length != 9) { setError('מספר טלפון לא תקין') } else {
                                setError(""); AddPhoneToCall(s.id, s.categoryCode, phone).then((data) => {
                                    d(COMPLETEphone(phone)); s.phoneToCall = phone; setAddZahav(false);
                                    var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק קיים התחבר למערכת הטלפונית,  <br/> נא לחבר אותו בהקדם - הוא כבר התחיל לשלם. <br/> <br/>  <b>שם העסק: </b> ${s.name}.<br/>  <b>תחום: </b> ${s.categoryCode}. <br/> <b>מספר טלפון: </b> ${s.phone}<br/> <b>כתובת מייל: </b> ${s.mail}<br/><br/> <b>מספר הטלפון אותו לחבר למערכת הטלפונית: </b><br/> ${phone}<br/><br/>  לאחר בנית השלוחה - נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר הטלפון שלנו + מספר השלוחה שלו  <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                                    SendEmailToBussiness({ name: "", emailTo: 'r0527117663@gmail.com', emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - לקוח קיים התחבר למערכת הטלפונית - נא להוסיף', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                                }).catch((error) => { console.error(error); });
                            }
                        }}><ButtonRed text={'אישור'}></ButtonRed></div>
                        {error && <p> <img src={picError} style={{ width: '35px', height: '35px' }}></img> {error}</p>}
                    </div>
                </div>
            </div>
        </>}
    </div>
}

export default Kochavit