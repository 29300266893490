import { useEffect, useState } from "react";
import { CopyAddress, GetComesAddress } from "../../axios/api";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import ButtonRed from "../../global/button/button";
import { useDispatch, useSelector } from "react-redux";
import { COMPLETEaddress } from "../../redux/actions/addressActions";

const ComesFromPrevChild = (prop) => {

    const [comes, setComes] = useState()
    const [add, setAdd] = useState([])
    const s = useSelector(a => a.userReducer)
    const d = useDispatch()

    useEffect(() => {
        GetComesAddress(prop.userId).then((data) => {
            setComes(data)
        }).catch((error) => { console.error(error); });
    }, [])

    const submit = () => {
        CopyAddress(s.id, add).then((data) => {
            prop.setComes(data)
            prop.setLogin2(false)
            d(COMPLETEaddress(data))
        }).catch((error) => { console.error(error); });
    }

    const chAll = () => {
        var arr = []
        for (let index = 0; index < comes.length; index++) {
            arr.push(comes[index].id)
        }
        setAdd(arr)
    }

    return <div id='modal2'>
        <div className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close2' onClick={() => prop.setLogin(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />

            <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '90vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '5vh' }}>
                <div className='titl' style={{ marginTop: '5%', fontSize: '120%', marginBottom: '5%' }}>בחר שורות להעתקה</div>
                <div id='submit30' style={{ position: 'absolute' }} onClick={() => chAll()}><ButtonRed text={'בחר הכל'}></ButtonRed></div>
                <div id='submit30' style={{ position: 'absolute', marginTop: '13vh' }} onClick={() => setAdd([])}><ButtonRed text={'הסר הכל'}></ButtonRed></div>

                <table style={{ marginTop: '2%', textAlign: 'center' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '30%', textAlign: 'center' }}>שם</th>
                            <th style={{ width: '30%', textAlign: 'center' }}>כתובת</th>
                            <th style={{ width: '26%', textAlign: 'center' }}>עיר</th>
                            <th style={{ width: '14%', textAlign: 'center' }}>הוסף</th>
                        </tr>
                    </thead>
                </table>
                <div style={{ maxHeight: '40vh', overflow: 'auto', width: '60%', marginRight: '20%' }}>
                    <table style={{ width: '100%', marginRight: '0' }}>
                        <tbody>{comes && <>
                            {comes.map((x, i) => (
                                <tr key={i} style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                                    <td style={{ textAlign: 'center', height: '200%', width: '30%' }}>{x.name}</td>
                                    <td style={{ textAlign: 'center', height: '200%', width: '30%' }}>{x.address1}</td>
                                    <td style={{ textAlign: 'center', height: '200%', width: '30%' }}>{x.city}</td>
                                    {!add.includes(x.id) && <td><IconButton style={{ padding: '9px' }} onClick={() => setAdd([...add, x.id])} className='IconButton' size="small"><PlusOutlined /></IconButton></td>}
                                    {add.includes(x.id) && <td><IconButton style={{ padding: '9px' }} onClick={() => setAdd(add.filter(a => a != a))} className='IconButton' size="small"> <CheckOutlined /> </IconButton></td>}
                                    {/* ✔ */}
                                </tr>
                            ))}</>}
                        </tbody>
                    </table>
                </div>


                <div id='submit30' onClick={() => submit()}><ButtonRed text={'אישור'}></ButtonRed></div>


                {/* <form style={{ display: 'block', marginTop: '3%' }}>
                    {!prop.fromPrevChild && <input className='input20' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder='מספר ת"ז של החתן או הכלה' autoComplete="on" type="text" value={user.tz} onChange={handleTzChange} />}
                    {prop.fromPrevChild && <input className='input20' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder='מספר ת"ז של הילד הקודם' autoComplete="on" type="text" value={user.tz} onChange={handleTzChange} />}
                    <input className='input20' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder="סיסמה" autoComplete="on" type="password" value={user.pass} onChange={handlePassChange} />
                    <div id='submit30' onClick={() => handleSubmit()}><ButtonRed text={'אישור'}></ButtonRed></div>
                    {errorMessage && <p>{errorMessage}</p>}
                    {link && <div className='errorPass' onClick={() => { setAlert(true); debugger }}>שיחזור סיסמה</div>}
                    {alert && <ErrorPass set={setAlert} userCurrent={userCurrent} good={good}></ErrorPass>}
                </form> */}
            </div>
        </div>
    </div>
}

export default ComesFromPrevChild