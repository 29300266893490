
const storeBussiness = {

};

const bussinessReducer = (state = storeBussiness, action) => {
  switch (action.type) {
    case "COMPLETEB":
      return action.payload;
    case "COMPLETEphone":
      return { ...state, phoneToCall: action.payload };
    default:
      return state;
  }
};



export default bussinessReducer;

