import { useEffect, useState } from "react"
import ButtonRed from "../global/button/button"
import RefReady from "../global/ref"
import { GetAllOptions, GetBussinessToManagger } from "../axios/api"
import Options from "../global/bussiness/options/Options"
import Search from "./Search"

const OptionsManagger = () => {

    const [resultSearch, setResultSearch] = useState([])
    const [allBussiness, setAllBussiness] = useState([])
    const [bussinessName, setBussinessName] = useState()
    const [bussiness, setBussiness] = useState()
    const [options, setOptions] = useState([])
    const [opionsToBussiness, setOpionsToBussiness] = useState([])
    const [tempOptions, setTempOptions] = useState([])
    const [navRight, setNavRight] = useState(1)
    const [is, setIs] = useState(true)

    useEffect(() => {
        GetBussinessToManagger().then((data) => {
            setAllBussiness(data)
        }).catch((error) => { console.error(error); });
        GetAllOptions().then((data) => {
            for (let i = 0; i < data.length; i++) {
                data[i].numDates = Math.floor((new Date() - new Date(data[i].date)) / (24 * 60 * 60 * 1000))
            }
            setOptions(data)
            setTempOptions(data)
        }).catch((error) => { console.error(error); });
    }, [])

    const searchData = (search) => {
        var result = []
        for (let i = 0; i < allBussiness?.length; i++) {
            if (allBussiness[i].name.includes(search))
                result.push(allBussiness[i])
        }
        setResultSearch(result)
        // setResultSearch(result.filter(c => c.code && c.code.includes(search)) || []);
    }

    const funcSearch = (inp) => {
        debugger
        var arr = options.filter(a => a.name.includes(inp) || a.description.includes(inp))
        setIs(false)
        setTempOptions(arr)
        setTimeout(() => {
            setIs(true)
        }, 200);
        window.scrollTo(0, 0);
    }

    

    return <div>

        <div style={{ width: '15%', position: 'fixed', marginTop: '0' }}>
            <div style={{ width: '80%', marginRight: '10%' }}>
                <div className='buton1' style={{ width: '100%' }} onClick={() => { setNavRight(1) }}><ButtonRed text='לפי שם עסק'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => { setNavRight(2) }}><ButtonRed text='כל חוות הדעת'></ButtonRed></div>
            </div>
        </div>

        {navRight === 1 && <div>
            {bussiness && <div className="manag"><img style={{ width: '20vh', height: '20vh' }} src={`data:image/jpeg;base64,${bussiness?.logo}`} alt='אין תמונת לוגו לעסק'></img></div>}

            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', width: '30%', marginRight: '35%' }}>הסרה או שינוי של חוות דעת</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            <p style={{ fontSize: '110%', marginTop: '4vh' }}>בחרו שם עסק</p>
            <input id="search1" type="text" className="input20" value={bussinessName} onChange={(e) => { searchData(e.target.value); setBussinessName(e.target.value); setBussiness(); setOpionsToBussiness([]) }} onClick={(e) => { searchData(e.target.value) }} onBlur={() => setTimeout(() => { setResultSearch([]) }, 500)}></input>
            {resultSearch[0] && <div className='resultSearchRound animate__animated animate__fadeInDown' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '30vh', marginTop: '-3%' }}>{resultSearch.map((x, i) => (
                <div className='resultSearch' key={i} onClick={(e) => { setBussinessName(x.name); setBussiness(x); searchData(x.name); setResultSearch([]); setOpionsToBussiness(options.filter(a => a.bussinessId === x.id && a.categoryCode === x.categoryCode)); }}>{x.name}</div>
            ))}</div>}

            {opionsToBussiness.length > 0 && <Options options={opionsToBussiness} managger={true}></Options>}
            {opionsToBussiness.length === 0 && bussiness && <p>אין חוות דעת לעסק זה</p>}

        </div>}

        {navRight === 2 && <div>
            {/* {bussiness && <div className="manag"><img style={{ width: '20vh', height: '20vh' }} src={`data:image/jpeg;base64,${bussiness?.logo}`} alt='אין תמונת לוגו לעסק'></img></div>} */}
            <Search funcSearch={funcSearch}></Search>

            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', width: '30%', marginRight: '35%' }}>הסרה או שינוי של חוות דעת</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {tempOptions.length > 0 && is && <Options options={tempOptions} managger={true}></Options>}
            {tempOptions.length === 0 && <p style={{ margin: '7%' }}>לא נמצאו חוות דעת מתאימות</p>}

        </div>}



    </div>
}

export default OptionsManagger