import ButtonRed from "../global/button/button"
import RefReady from "../global/ref"

const KavInPhone = () => {
    return <div>
        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', width: '30%', marginRight: '35%' }}>ניהול המערכת הטלפונית</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
        
        <div className='buton1' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', marginTop: '3%' }} onClick={() => window.open('https://nakdan.dicta.org.il/nikudedit')}><ButtonRed text='נקדן אוטומטי'></ButtonRed></div>
        <div className='buton1' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', marginTop: '3%' }} onClick={() => window.open('https://ttsmaker.com/he')}><ButtonRed text='המרת טקסט לדיבור'></ButtonRed></div>
        <div className='buton1' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', marginTop: '3%' }} onClick={() => window.open('https://private.call2all.co.il/yemot-admin-g1/#/login')}><ButtonRed text='ימות המשיח - בנית השלוחות בקו'></ButtonRed></div>
        <p>מערכת: <b>023137663</b> , סיסמה: <b>91088638</b></p>

        <div style={{ width: '80%', marginRight: '12%', marginTop: '5%', textAlign: 'right' }}>
            <b>בנית שלוחה חדשה לעסק שהצטרף למערכת הטלפונית:</b><br />
            <p><b>1. </b>יש לפתוח את ימות המשיח - בנית השלוחות בקו, ולהכנס עם מספר מערכת וסיסמה.</p>
            <p><b>2. </b>לעבור בתפריט בצד ימין לשלוחות במערכת.</p>
            <p><b>3. </b>יש להכנס לשלוחה המתאימה - לפי תחום העסק.</p>
            <p><b>4. </b>לשכפל שלוחה של עסק קיים בתחום הרצוי.</p>
            <p><b>5. </b>לשנות את הפרטים בהתאם לעסק החדש - כולל קטעי קריינות, מידע ומספר הטלפון.</p>
            <p><b>6. </b>פעמים רבות הקריינות האוטומטית לא נכונה, אי לכך במקרים אלו נדרש לנקד את הטקסט - ניתן להיעזר בנקדן אוטומטי.</p>
            <p><b>7. </b>יש לחייג לשלוחה שנוספה ולהאזין לכל המידע כדי לוודא שהכל תקין.</p>
            <p><b>8. </b>נא למלא בטבלת הסטטיסטיקה את מספר השלוחה של העסק.</p>
            <p><b>9. </b>להוסיף בשלוחה Hash - השלוחה של חיפוש עסק, בקובץ menu_voice.ini, את שם העסק - יחד עם השלוחה המתאימה.</p>
            <p><b>10. </b>לסיום, נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר המערכת הטלפונית שלנו + מספר השלוחה שלו.</p>
        </div>

    </div>
}

export default KavInPhone