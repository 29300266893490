
const address = [];

const addressReducer = (state = address, action) => {
    switch (action.type) {
        case "COMPLETEaddress":
            return action.payload;
        default:
            return state;
    }
};



export default addressReducer;
