const mehorasim = [];

const mehorasimReducer = (state = mehorasim, action) => {
    switch (action.type) {
        case "COMPLETEmehorasim":
            return action.payload;
        default:
            return state;
    }
};

export default mehorasimReducer;
