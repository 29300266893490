// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal {
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.334);
}

#formCon111 {
    width: 100%;
    background-color: black;
    height: 64vh;
    margin-top: -2vh;
    color: white;
}

.bottom5 {
    background-color: #FF7D7D;
    margin-top: 25px;
}

.input111 {
    border-radius: 0px 0px 0px 0px;
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: #FF7D7D 2px solid;
    display: block;
    width: 300px;
    margin-top: 1%;
    padding: 5px;
    margin-right: auto;
    margin-left: auto;
    transition: 1s;
}

.input111:hover {
    /* border-radius: 5px 5px 5px 5px; */
    border-bottom: white 2px solid;
}

.input111:focus {
    outline: none;
    /* border: #FF7D7D 2px solid; */
    width: 40%;
}

.close:hover {
    color: #FF7D7D;
    font-size: large;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/login/ErrorPass.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,OAAO;IACP,MAAM;IACN,WAAW;IACX,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,gCAAgC;IAChC,cAAc;IACd,YAAY;IACZ,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,+BAA+B;IAC/B,UAAU;AACd;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":["#modal {\r\n    position: fixed;\r\n    z-index: 800;\r\n    left: 0;\r\n    top: 0;\r\n    width: 100%;\r\n    height: 100vh;\r\n    background-color: rgba(0, 0, 0, 0.334);\r\n}\r\n\r\n#formCon111 {\r\n    width: 100%;\r\n    background-color: black;\r\n    height: 64vh;\r\n    margin-top: -2vh;\r\n    color: white;\r\n}\r\n\r\n.bottom5 {\r\n    background-color: #FF7D7D;\r\n    margin-top: 25px;\r\n}\r\n\r\n.input111 {\r\n    border-radius: 0px 0px 0px 0px;\r\n    background-color: transparent;\r\n    color: white;\r\n    border: none;\r\n    border-bottom: #FF7D7D 2px solid;\r\n    display: block;\r\n    width: 300px;\r\n    margin-top: 1%;\r\n    padding: 5px;\r\n    margin-right: auto;\r\n    margin-left: auto;\r\n    transition: 1s;\r\n}\r\n\r\n.input111:hover {\r\n    /* border-radius: 5px 5px 5px 5px; */\r\n    border-bottom: white 2px solid;\r\n}\r\n\r\n.input111:focus {\r\n    outline: none;\r\n    /* border: #FF7D7D 2px solid; */\r\n    width: 40%;\r\n}\r\n\r\n.close:hover {\r\n    color: #FF7D7D;\r\n    font-size: large;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
