import { useDispatch } from 'react-redux';
import { getUserByTz } from '../../axios/api';
import ButtonRed from '../../global/button/button';
import ErrorPass from './ErrorPass';
import './Login.css'
import { useState } from 'react';
import { CompleteUser } from '../../redux/actions/userActions';
import { CloseOutlined } from '@ant-design/icons';
import ComesFromPrevChild from './ComesFromPrevChild';
import picError from '../../../pic/animations/113.gif'

const Login = (prop) => {

    const d = useDispatch()
    const [user, setUser] = useState({ tz: '', pass: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [userCurrent, setUserCurrent] = useState();
    const [alert, setAlert] = useState(false);
    const [link, setLink] = useState(false);
    const [comes, setComes] = useState(false)


    const handleTzChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Numeric input and valid phone number format
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setUser({ tz: inputValue, pass: user.pass });
        }
    };

    const handlePassChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Numeric input and valid phone number format
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setUser({ tz: user.tz, pass: inputValue });
        }
    };

    const handleSubmit = (e) => {
        setErrorMessage('');

        // Check if all fields are filled
        if (user.tz === '' || user.pass === '') {
            setErrorMessage('כל השדות הן חובה');
        } else {
            // Reset error message
            setErrorMessage('');

            // Validate phone number
            if (!/^\d{9}$/.test(user.tz)) {
                setErrorMessage('מספר ת"ז לא תקין');
                return;
            }
        }

        getUserByTz(user.tz).then((data) => {
            setUserCurrent(data);
            if (data == []) {
                setErrorMessage('אינך קיים במערכת')
            }
            else {
                data.forEach(element => {
                    if (element.pass === user.pass) {
                        if(!prop.fromPrevChild)
                            good(element)
                        else {
                            setComes(element.id)
                        }
                    }
                });
                setErrorMessage('סיסמה שגויה')
                setLink(true)
            }

        }).catch((error) => { console.error(error); });

    };

    const good = (data) => {
        d(CompleteUser(data))
        prop.setLogin(false);
    }

    return <div>
        {!comes && <div id='modal2'>
        <div className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close2' onClick={() => prop.setLogin(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />

            <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '60vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '20vh' }}>
                {!prop.fromPrevChild && <div className='titl' style={{ marginTop: '5%', fontSize: '120%', marginBottom: '5%' }}>התחברות</div>}
                {prop.fromPrevChild && <div className='titl' style={{ marginTop: '5%', fontSize: '120%', marginBottom: '5%' }}>התחברות לרשימת המוזמנים של הילד הקודם</div>}

                {/* {prop.menu && <form style={{ display: 'inline-block', marginRight: '30px', position: 'absolute', left: '1%', top: '-30px' }}><div style={{ width: '29rem', height: '100%', position: 'relative' }}>
                    <div style={{ position: 'absolute', right: '-10px', top: '35px' }}>
                        <input className=' input2' style={{ width: '230px' }} placeholder='מספר ת"ז של החתן או הכלה' autoComplete="on" type="text" value={user.tz} onChange={handleTzChange} />
                        <input className=' input2' style={{ width: '70px' }} placeholder="סיסמה" autoComplete="on" type="password" value={user.pass} onChange={handlePassChange} />
                    </div>
                    <div id='submit3' onClick={() => handleSubmit()}><ButtonRed text={'אישור'}></ButtonRed></div>
                    {errorMessage && <p>{errorMessage}</p>}
                    {link && <div className='errorPass' onClick={() => { setAlert(true); debugger }}>שיחזור סיסמה</div>}
                    {alert && <ErrorPass set={setAlert} userCurrent={userCurrent} good={good}></ErrorPass>}
                </div></form>} */}

                <form style={{ display: 'block', marginTop: '3%' }}>
                    {!prop.fromPrevChild && <input className='input20' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder='מספר ת"ז של החתן או הכלה' autoComplete="on" type="text" value={user.tz} onChange={handleTzChange} />}
                    {prop.fromPrevChild && <input className='input20' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder='מספר ת"ז של הילד הקודם' autoComplete="on" type="text" value={user.tz} onChange={handleTzChange} />}
                    <input className='input20' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder="סיסמה" autoComplete="on" type="password" value={user.pass} onChange={handlePassChange} />
                    <div id='submit30' onClick={() => handleSubmit()}><ButtonRed text={'אישור'}></ButtonRed></div>
                    {errorMessage && <p><img src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
                    {link && <div className='errorPass' onClick={() => { setAlert(true); debugger }}>שיחזור סיסמה</div>}
                    {alert && <ErrorPass set={setAlert} userCurrent={userCurrent} good={good}></ErrorPass>}
                </form>
            </div>
        </div>
    </div>}
    {comes && <ComesFromPrevChild setLogin2={prop.setLogin2} setComes={prop.setComes} userId={comes}></ComesFromPrevChild>}
    </div>
}

export default Login