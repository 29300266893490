import React, { useEffect, useRef, useState } from 'react';
import './HomePage2.css';
// import pic from '../../../pic/998.png';
import pic from '../../../pic/logo/56.jpg';
import pic2 from '../../../pic/logo/11.jpg';
import picKol from '../../../pic/logo/logo.jpg';

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useInView } from 'react-intersection-observer';
import 'animate.css';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import BottomMenu from '../../global/bottomMenu/BottomMenu';
import FormNewBusiness from '../../global/forms/FormNewBusiness';
import { useNavigate } from 'react-router-dom';
import { GetAllIcons } from '../../axios/api';
import ButtonRed from '../../global/button/button';
import Wait from '../../global/Wait';
import { useDispatch, useSelector } from 'react-redux';
import { COMPLETEicon } from '../../redux/actions/iconAndLogoActions';


const HomePage2 = () => {

  useEffect(() => { window.scrollTo(0, 0); }, []);

  const d = useDispatch()
  const s = useSelector(a => a.iconAndLogoReducer.icon)
  // const element = 
  library.add(faEnvelope);
  // ReactDOM.render(element, document.body)
  const [isHovered, setIsHovered] = useState(0)
  const [wait, setWait] = useState(true);


  const [icons, setIcons] = useState()
  useEffect(() => {
    if (s.length <= 0) {
      GetAllIcons().then((data) => {
        setIcons(data)
        d(COMPLETEicon(data))
        // const logFile = new Blob(['שלום זה עובד ברוך ה'], { type: 'text/plain' });
        // const downloadLink = URL.createObjectURL(logFile);
        // const a = document.createElement('a');
        // a.href = downloadLink;
        // a.download = 'log.txt';
        // a.click();
        // URL.revokeObjectURL(downloadLink);

        setWait(false)
        window.scrollTo(0, 0);
      }).catch((error) => { console.error(error); });
    } else { setIcons(s); setWait(false); window.scrollTo(0, 0); }
  }, []);



  // ------------------------start ref-------------------------
  const [isVisible, setIsVisible] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, // Triggers the animation only once
    threshold: 0.1, // Percentage of visibility needed to trigger the animation
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  // ------------------------end ref-------------------------

  // ------------------------start second ref-------------------------
  const divRef1 = useRef(null);
  const divRef2 = useRef(null);
  const divRef3 = useRef(null);
  const divRef4 = useRef(null);
  const divRef5 = useRef(null);
  const divRef6 = useRef(null);
  const divRef7 = useRef(null);
  const divRef8 = useRef(null);
  const divRef9 = useRef(null);
  const divRef10 = useRef(null);
  const divRef11 = useRef(null);
  const divRef12 = useRef(null);
  const divRef13 = useRef(null);
  const divRef14 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (Array.from(document.getElementsByClassName("animate")).indexOf(entry.target) % 2)
            entry.target.classList.add('animate__bounceInRight')
          else
            entry.target.classList.add('animate__bounceInLeft')
        }
      });
    });
    // if (picRef.current) { observer.observe(picRef.current); }
    if (divRef1.current) { observer.observe(divRef1.current); }
    if (divRef2.current) { observer.observe(divRef2.current); }
    if (divRef3.current) { observer.observe(divRef3.current); }
    if (divRef4.current) { observer.observe(divRef4.current); }
    if (divRef5.current) { observer.observe(divRef5.current); }
    if (divRef6.current) { observer.observe(divRef6.current); }
    if (divRef7.current) { observer.observe(divRef7.current); }
    if (divRef8.current) { observer.observe(divRef8.current); }
    if (divRef9.current) { observer.observe(divRef9.current); }
    if (divRef10.current) { observer.observe(divRef10.current); }
    if (divRef11.current) { observer.observe(divRef11.current); }
    if (divRef12.current) { observer.observe(divRef12.current); }
    if (divRef13.current) { observer.observe(divRef13.current); }
    if (divRef14.current) { observer.observe(divRef14.current); }

    return () => {
      if (divRef1.current) { observer.unobserve(divRef1.current); }
      if (divRef2.current) { observer.unobserve(divRef2.current); }
      if (divRef3.current) { observer.unobserve(divRef3.current); }
      if (divRef4.current) { observer.unobserve(divRef4.current); }
      if (divRef5.current) { observer.unobserve(divRef5.current); }
      if (divRef6.current) { observer.unobserve(divRef6.current); }
      if (divRef7.current) { observer.unobserve(divRef7.current); }
      if (divRef8.current) { observer.unobserve(divRef8.current); }
      if (divRef9.current) { observer.unobserve(divRef9.current); }
      if (divRef10.current) { observer.unobserve(divRef10.current); }
      if (divRef11.current) { observer.unobserve(divRef11.current); }
      if (divRef12.current) { observer.unobserve(divRef12.current); }
      if (divRef13.current) { observer.unobserve(divRef13.current); }
      if (divRef14.current) { observer.unobserve(divRef14.current); }
    };
  }, []);
  // ------------------------end second ref-------------------------


  const navigate = useNavigate()

  return (
    <div className="homepage">

      {/* <svg className='pic1' style={{ marginTop: '-60%' }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 200 200">
      <defs>
        <pattern id="imagePattern" patternUnits="userSpaceOnUse" width="100%" height="100%">
          <image href={pic1} width="100%" height="100%" />
        </pattern>
      </defs>
      <path d="M0,130 L190,130 L190,190 Q150,180 100,190 Q50,200 0,180 Z" fill="url(#imagePattern)" stroke="black" />
    </svg> */}

      {/*     
      <svg className='pic1' style={{marginTop:'-70%'}} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 200 200">
        <path d="M0,130 L190,130 L190,190 Q150,180 100,190 Q50,200 0,180 Z" fill="none" stroke="black" />
      </svg> */}

      <div className='pic'>
        <img className='animate__animated animate__zoomInDown inPic' style={{ marginTop: '10%', WebkitUserDrag: 'none' }} src={pic}></img>
        <p className='animate__animated animate__zoomInDown' style={{ animationDelay: '0.4s', fontSize: '120%', marginTop: '0%', fontWeight: '400', color: 'white' }} onLoad={(event) => setTimeout((event) => { event.currentTarget.style.transform = 'translateY(500px)'; }, 200)}>היומן האישי שיסדר לכם את כל צרכי החתונה</p>
      </div>

      <div style={{ position: 'absolute', top: '50%', width: '10%', right: '2%' }}>
        <img style={{ width: '100%' }} src={pic2}></img>
        <b>02-3137663</b>
      </div>
      <a href='#get' className='what' style={{ border: 'black 2px solid', backgroundColor: 'white', position: 'absolute', textDecoration: 'none', width: '200px' }}><ButtonRed text='מה מרוויחים?'></ButtonRed></a>



      {icons && <div style={{ width: '70%', marginRight: 'auto', marginLeft: 'auto', marginTop: '2%', display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gridGap: '10px' }}>

        {icons.map((x, i) => (
          <div key={i} onClick={() => navigate(`/allCards/${x.code}/${x.name}/${'קול שמחה'}`)} className='card animate__animated animate__zoomIn card123' onMouseOver={() => setIsHovered(1)}>
            <img src={`data:image/jpeg;base64,${x.icon}`} style={{ width: '70px', height: '70px' }} className='animate__animated' onMouseEnter={(event) => event.currentTarget.classList.add('animate__headShake')} onMouseLeave={(event) => event.currentTarget.classList.remove('animate__headShake')}></img>
            <div>{x.name}</div>
            <div className={`underline3 ${isHovered == 1 ? "visible2" : ""}`}></div>
          </div>
        ))}
      </div>}

      <div className='divNitshad' style={{ width: '100%', height: '35%', backgroundColor: 'rgb(223, 209, 209)', paddingBottom: '5%' }}>
        <div style={{ fontSize: '170%', color: 'black', paddingTop: '9vh', marginBottom: '4vh' }}>נצעד איתכם שלב שלב כדי להגיע מוכנים לאירוע</div>
        <div className='divNitshad1' style={{ width: '300px', height: '7vh', lineHeight: '6vh', cursor: 'pointer', border: 'black 4px solid', backgroundColor: 'white', marginRight: 'auto', marginLeft: 'auto' }} onClick={() => navigate('/איך זה עובד?/0')}>איך זה עובד?</div>
      </div>

      <p id='get' style={{ fontSize: '150%', fontWeight: '500', paddingTop: '4%' }}>מה מרוויחים?</p>
      <div ref={ref} style={{ transition: 'width 0.5s ease-in-out' }} className={`underline3 ${isVisible ? "visible3" : ""} `}></div>

      <div style={{ position: 'relative' }}>
        {/* <div id='long' style={{ backgroundColor: '#c00000', width: '5px', height: '3360px', marginRight: '50%', transform: 'translateY(100%)', marginTop: '-3300px', zIndex: '0' }}></div> */}
        <div id='long' style={{ position: 'absolute', backgroundColor: '#c00000', width: '5px', height: '100%', right: '50%', top: '0px', zIndex: '0' }}></div>


        <div style={{ fontSize: '110%' }}>
          <div ref={divRef1} className='animate' style={{ backgroundColor: 'black', color: 'white' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextStrokeWidth: '0.7px', WebkitTextStrokeColor: '#c00000' }}>כל המידע על מגש של זהב</p>
            <p>חסל סדר חיפושים, בירורים וטלפונים.<br /> כל המידע לחתנים וכלות מרוכז ומפורט ביעילות במקום אחד.</p>
          </div>
          <div style={{ position: 'absolute', left: '49%', marginTop: '-10%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>


          <div ref={divRef2} className='animate p1' style={{ marginTop: '-2%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >מחירון מדויק של כל הספקים</p>
            <p style={{ fontWeight: '400', }}>מחירונים מפורטים בכל תחום כולל תמונות. <br /> לדוגמה: באולמות - מחירון מפורט לפי כמות וסוגי מנות, צלמים - פירוט חבילות + שעות נוספות.</p>
          </div>
          <div className='p1' style={{ position: 'absolute', left: '47%', marginTop: '-5%', border: '#c00000 6px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%', fontWeight: 'bolder' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ paddingLeft: '40%', paddingTop: '30%' }} /></div>


          <div ref={divRef3} className='animate p2' style={{ marginTop: '4%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#c00000', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >השוואות בין עסקים</p>
            <p style={{ fontWeight: '400', }}>אפשרות נוחה למיונים לפי מחיר מזול ליקר, גודל, עיר, סיגנון מוזיקה ועוד...</p>
          </div>
          <div className='p2' style={{ position: 'absolute', left: '48%', marginTop: '-9.5%', border: 'black 4px solid', width: '90px', height: '90px', borderRadius: '50%', color: 'black', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' style={{ marginLeft: '-42%', paddingTop: '30%' }} /></div>


          <div ref={divRef4} className='animate' style={{ backgroundColor: 'black', color: 'white', marginTop: '1%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextStrokeWidth: '0.7px', WebkitTextStrokeColor: '#c00000' }} >מגוון עצום של נותני שירות</p>
            <p>אולמות אירועים, צלמים, צלמי וידאו, קייטרינג, תזמורת לחתונה, נדוניה, מצעים, ביגוד לחתן ולכלה, מתנות לחתן ולכלה ועוד... - הכל במקום אחד</p>
          </div>
          <div style={{ position: 'absolute', left: '47%', marginTop: '-11%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ marginLeft: '-20%', paddingTop: '10%' }} /></div>


          <div ref={divRef5} className='animate p1' style={{ marginTop: '-2%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >קניות ישירות אונליין מתוך המערכת</p>
            <p style={{ fontWeight: '400', }}>ניתן בקלות להזמין ולשלם ישיר, בלי לצאת מהבית - מתוך מבחר העסקים שבחרו באופציה זו.</p>
          </div>
          <div className='p1' style={{ position: 'absolute', left: '49%', marginTop: '-6%', backgroundColor: 'black', border: '#c00000 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>


          <div ref={divRef6} className='animate p2' style={{ marginTop: '3%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#c00000', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }}>יומן תאריכים פנויים באולמות ועוד</p>
            <p style={{ fontWeight: '400', }}>יומן חכם שמסונכרן עם בתי העסק: האולמות הזמרים ונותני השירות. <br />תוכלו לראות את בעלי המקצוע הפנויים בתאריך שלכם, ולתפוס תאריך פנוי אונליין.<br />חסל סדר בירורים ושאלות "האם אתם פנויים בתאריך..."</p>
          </div>
          <div className='p2' style={{ position: 'absolute', left: '47%', marginTop: '-6%', border: 'black 4px solid', width: '90px', height: '90px', borderRadius: '50%', color: 'black', fontSize: '200%' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ marginLeft: '-42%', paddingTop: '30%' }} /></div>


          <div ref={divRef7} className='animate' style={{ backgroundColor: 'black', color: 'white' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextStrokeWidth: '0.7px', WebkitTextStrokeColor: '#c00000' }}>חוסכים זמן</p>
            <p>כל המידע הנצרך מונגש בבהירות וחוסך את זמן הבירור</p>
          </div>
          <div style={{ position: 'absolute', left: '49%', marginTop: '-10%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>


          <div ref={divRef8} className='animate p1' style={{ marginTop: '-2%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >חוסכים אנרגיה כח וטרחה</p>
            <p style={{ fontWeight: '400' }}>כל צרכי החתונה במקום אחד ובלחיצת כפתור, בלי להתרוצץ ולהזיע</p>
          </div>
          <div className='p1' style={{ position: 'absolute', left: '47%', marginTop: '-5%', border: '#c00000 6px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%', fontWeight: 'bolder' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ paddingLeft: '40%', paddingTop: '30%' }} /></div>


          <div ref={divRef9} className='animate p2' style={{ marginTop: '4%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#c00000', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >חוסכים אלפי שקלים</p>
            <p style={{ fontWeight: '400' }}>ע"י השוואת מחירים בין כל האולמות, התזמורות, הצלמים וכל צרכי החתונה</p>
          </div>
          <div className='p2' style={{ position: 'absolute', left: '48%', marginTop: '-9.5%', border: 'black 4px solid', width: '90px', height: '90px', borderRadius: '50%', color: 'black', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' style={{ marginLeft: '-42%', paddingTop: '30%' }} /></div>


          <div ref={divRef10} className='animate' style={{ backgroundColor: 'black', color: 'white', marginTop: '1%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextFillColor: 'white', WebkitTextStrokeWidth: '0.5px', WebkitTextStrokeColor: '#c00000' }} >סדר בראש</p>
            <p>מקבלים רשימת מטלות מתאימה לצרכים המדויקים שלכם. <br />המכילה את כל המטלות הדרושות לארגון חתונה בצורה הכי טובה שיש.<br />רשימה נפרדת מותאמת לצד החתן / לצד הכלה.</p>
          </div>
          <div style={{ position: 'absolute', left: '47%', marginTop: '-11%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ marginLeft: '-20%', paddingTop: '10%' }} /></div>


          <div ref={divRef11} className='animate p1' style={{ marginTop: '-2%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >מקבלים תזכורות</p>
            <p>קבלו למייל תזכורות בזמן אמת לפני החתונה על כל שלב שצריך להתכונן</p>
          </div>
          <div className='p1' style={{ position: 'absolute', left: '49%', marginTop: '-6%', backgroundColor: 'black', border: '#c00000 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>


          <div ref={divRef12} className='animate p2' style={{ marginTop: '3%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#c00000', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }}>פירסום ומידע על מאורסים חינם</p>
            <p style={{ fontWeight: '400', }}>תוכלו לקבל מידע חינמי על מאורסים ולפרסם מודעות מאורסים חינם</p>
          </div>
          <div className='p2' style={{ position: 'absolute', left: '47%', marginTop: '-6%', border: 'black 4px solid', width: '90px', height: '90px', borderRadius: '50%', color: 'black', fontSize: '200%' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ marginLeft: '-42%', paddingTop: '30%' }} /></div>


          <div ref={divRef13} className='animate' style={{ backgroundColor: 'black', color: 'white' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextStrokeWidth: '0.7px', WebkitTextStrokeColor: '#c00000' }}>מקבלים כלים רוחניים להגיע לשמחה האמיתית "כמשוש חתן על כלה"</p>
            <p>שמות ספרים ומאמרים מומלצים לקריאה לחתן, לכלה ולמחותנים. <br /> כולל מידע היכן ניתן להשיג כל ספר.</p>
          </div>
          <div style={{ position: 'absolute', left: '49%', marginTop: '-10%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>

          <div ref={divRef14} className='animate p1' style={{ marginTop: '-2%' }}>
            <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >הנחות ומבצעים מיוחדים</p>
            <p style={{ fontWeight: '400' }}>למזמינים דרך<img src={picKol} style={{ width: '16%', userSelect: 'none', marginTop: '-4%', objectFit: 'cover', transform: 'scale(1.1)' }}></img></p>
          </div>
          <div className='p1' style={{ position: 'absolute', left: '47%', marginTop: '-5%', border: '#c00000 6px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%', fontWeight: 'bolder' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ paddingLeft: '40%', paddingTop: '30%' }} /></div>

        </div>
      </div>


      <FormNewBusiness new={true}></FormNewBusiness>
      <BottomMenu></BottomMenu>
      {wait && <Wait></Wait>}
    </div>
  );
};

export default HomePage2;