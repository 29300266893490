
const managger = {
    statistic: [],
    categories: [],
    options: [],
    tasks: [],
    pays: { allPays: [], sum: 0 }

};

const managgerReducer = (state = managger, action) => {
    switch (action.type) {
        case "COMmanaggerSTATISTIC":
            return { ...state, statistic: action.payload };
        case "COMmanaggerCATEGORIES":
            return { ...state, categories: action.payload };
        case "COMmanaggerOPTIONS":
            return { ...state, options: action.payload };
        case "COMmanaggerTASKS":
            return { ...state, tasks: action.payload };
        case "COMmanaggerPAYS":
            return { ...state, pays: { ...state.pays, allPays: action.payload } };
        case "COMmanaggerSUMPAYS":
            return { ...state, pays: { ...state.pays, sum: action.payload } };
        default:
            return state;
    }
};



export default managgerReducer;
