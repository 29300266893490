import { useNavigate } from "react-router-dom"
import ButtonRed from "../../global/button/button"
import RefReady from "../../global/ref"
import BottomMenu from "../../global/bottomMenu/BottomMenu"
import { useEffect, useState } from "react"
import Connection from "./Connection"
import './Connection.css'
import pass from '../../../pic/pass1.jpg'
import vi from '../../../pic/vi.jpg'
import change from '../../../pic/change.jpg'
import calander from '../../../pic/calander.jpg'
import { useSelector } from "react-redux"
import Login from "../login/Login"
import { CloseOutlined } from "@ant-design/icons"

const PersonalArea = (prop) => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const navigate = useNavigate()
    const [op, setOp] = useState(false)
    const [login, setLogin] = useState(false)
    const s = useSelector(a => a.userReducer)
    const [openGlobal, setOpenGlobal] = useState(false)
    const [connection, setConnection] = useState(false);

    return <div>

        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '9.5%', marginBottom:'5px' }}>איזור אישי</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '0%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>


        <div style={{ width: '17%', position: 'fixed', marginTop: '0' }}>
            <div style={{ width: '90%', marginRight: '10%' }}>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/taskList`)}><ButtonRed text='רשימת מטלות'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/choozedBussness`)}><ButtonRed text='ההזמנות שלי'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/howManyToPay`)}><ButtonRed text='ארגון הוצאות'></ButtonRed></div>
                {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth <= 1300 && <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed text='רשימת כתובות'></ButtonRed></div>}
                {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth > 1300 && <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed text='רשימת מוזמנים וכתובות'></ButtonRed></div>}
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/PersonalArea`)}><ButtonRed is='this' text='איזור אישי'></ButtonRed></div>
            </div>
        </div>

        {/* <p style={{ marginTop: '5%' }}>פרטיכם שמורים במערכת, לשינוי פרטי משתמש</p>
        <div className='buton1' style={{ width: '30%', marginRight: '35%', marginBottom: '8%' }} onClick={() => setOp(true)}><ButtonRed text='לחץ כאן'></ButtonRed></div> */}
        <div className="containe250">
            <div className="iconGlobal" onClick={() => s.id ? setOp(1) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' })}>
                <img src={pass} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                <p style={{ height: '10vh' }}>שינוי סיסמה</p>
            </div>
            <div className="iconGlobal" onClick={() => s.id ? setOp(2) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' })}>
                <img src={vi} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                <p style={{ height: '10vh' }}>{s.reminers ? 'ביטול' : 'אישור'} קבלת תזכורות</p>
            </div>
            <div className="iconGlobal" onClick={() => s.id ? setOp(3) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' })}>
                <img src={calander} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                <p style={{ height: '10vh' }}>שינוי / הכנסת תאריך האירוסין / החתונה</p>
            </div>
            <div className="iconGlobal" onClick={() => s.id ? setOp(4) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' })}>
                <img src={change} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                <p style={{ height: '10vh' }}>שינוי פרטי משתמש</p>
            </div>
        </div>



        {op && <Connection set={setOp} change={op}></Connection>}
        {login && <Login setLogin={setLogin}></Login>}
        {connection && <Connection set={setConnection}></Connection>}

        {openGlobal && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenGlobal(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
                    <div className='titl' style={{ marginTop: '4%', fontSize: '120%', marginBottom: '5%' }}>{openGlobal.text}</div>
                    <div style={{ marginTop: '-2%' }}>{openGlobal.text2}</div>
                    <div style={{ width: '10%', display: 'inline-block', marginTop: '4%' }} onClick={() => { setLogin(true); setOpenGlobal(false) }}><ButtonRed text='התחבר'></ButtonRed></div>
                    <div style={{ width: '10%', display: 'inline-block' }} onClick={() => { setConnection(true); setOpenGlobal(false) }}><ButtonRed text='הירשם'></ButtonRed></div>
                </div>
            </div>
        </div>}

        <BottomMenu></BottomMenu>
    </div>
}

export default PersonalArea

