
export const COMmanaggerTASKS = (data) => {
    return ({ type: "COMmanaggerTASKS", payload: data });
};

export const COMmanaggerOPTIONS = (data) => {
    return ({ type: "COMmanaggerOPTIONS", payload: data });
};

export const COMmanaggerCATEGORIES = (data) => {
    return ({ type: "COMmanaggerCATEGORIES", payload: data });
};

export const COMmanaggerSTATISTIC = (data) => {
    return ({ type: "COMmanaggerSTATISTIC", payload: data });
};

export const COMmanaggerPAYS = (data) => {
    return ({ type: "COMmanaggerPAYS", payload: data });
};

export const COMmanaggerSUMPAYS = (data) => {
    return ({ type: "COMmanaggerSUMPAYS", payload: data });
};