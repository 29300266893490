import './TaskList.css';
import './ListAddress.css';
import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ButtonRed from '../../global/button/button';
import BottomMenu from '../../global/bottomMenu/BottomMenu';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { CheckOutlined, CloseOutlined, EditOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { AddAddress, DelAddress, GetComesAddress, UpdateAddress } from '../../axios/api';
import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import AlertDelete from '../../global/alert/AlertDelete';
import Login from '../login/Login';
import example from '../../../pic/71.png'
import Connection from '../connection/Connection';
import Search from '../../managger/Search';
import { COMPLETEaddress } from '../../redux/actions/addressActions';

const ListAddress = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const navigate = useNavigate()
    const s = useSelector(a => a.userReducer)
    const [weddingDate, setWeddingDate] = useState(false);
    const d = useDispatch()
    const addressSelector = useSelector(a => a.addressReducer)

    const [openGlobal, setOpenGlobal] = useState(false)
    const [login, setLogin] = useState(false);
    const [connection, setConnection] = useState(false);

    useEffect(() => {
        setWeddingDate(Math.ceil((new Date(s.weddingDate) - new Date()) / (24 * 60 * 60 * 1000)));
    }, [s]);

    const [comes, setComes] = useState([])
    const [tempComes, setTempComes] = useState([])
    var totalPages;
    const [pages, setPages] = useState([]);
    const [tur, setTur] = useState(4);
    const [shura, setShura] = useState(10);
    var t = 4, sh = 10;
    const [itemsPerPage, setitemsPerPage] = useState();
    const [nul, setNul] = useState();
    const [login2, setLogin2] = useState(false);

    const setAllComes = (data) => {
        setComes(data)
        setTempComes(data)
        setitemsPerPage(tur * shura)
        totalPages = Math.ceil(data.length / (tur * shura));
        var arr = []
        for (let index = 0; index < totalPages; index++) {
            arr.push(index + 1)
        }
        setPages(arr)
    }




    useEffect(() => {
        if(addressSelector.length > 0) {
            setAllComes(addressSelector)
        }
        else if (s.id) {
            GetComesAddress(s.id).then((data) => {
                setAllComes(data)
                d(COMPLETEaddress(data))
            }).catch((error) => { console.error(error); });
        }
    }, [s]);

    // -----------------------start ref-----------------------------
    const [isVisible, setIsVisible] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: true, // Triggers the animation only once
        threshold: 0.1, // Percentage of visibility needed to trigger the animation
    });

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);
    // -----------------------end ref-----------------------------

    const [man, setMan] = useState({ relatives: '', name: '', description: '', address1: '', city: '' })
    const [open, setOpen] = useState(false)
    const [openMadbekot, setOpenMadbekot] = useState(false)


    // -----------------------BEGIN PDF---------------------------
    const PAGE_WIDTH = 595.28; // A4 page width in points
    const PAGE_HEIGHT = 841.89; // A4 page height in points

    // const divRef = useRef(null);

    const convertToPdf = () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [PAGE_WIDTH, PAGE_HEIGHT],
        });

        const divElements = document.querySelectorAll('.a4');

        const pdfPromises = Array.from(divElements).map((div, index) => {
            return new Promise((resolve) => {
                html2canvas(div, {
                    scale: 2, // Increase the scale to improve image resolution
                    useCORS: true, // Enable CORS if the content is hosted on a different domain
                    dpi: 1200,
                }).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');

                    if (index !== 0) {
                        pdf.addPage();
                    }

                    pdf.addImage(imgData, 'PNG', 0, 0, PAGE_WIDTH, PAGE_HEIGHT);

                    resolve();
                });
            });
        });

        Promise.all(pdfPromises).then(() => {
            pdf.save('divsToPdf.pdf');
        });
    };

    // -----------------------END PDF-----------------------------

    const changePages = () => {
        setitemsPerPage(t * sh)
        totalPages = Math.ceil(comes.length / (t * sh));
        var arr = []
        for (let index = 0; index < totalPages; index++) {
            arr.push(index + 1)
        }
        setPages(arr)
    }


    const [delAddress, setDelAddress] = useState(false);
    const DellAddress = () => {
        DelAddress(delAddress, s.id).then(data => {setAllComes(data); d(COMPLETEaddress(data))}).catch(error => console.error(error))
        setDelAddress(false)
    }

    const [updateAd, setUpdateAd] = useState(false);

    const funcSearch = (inp) => {
        setComes(tempComes.filter(a => a.name !== null && a.name.includes(inp) || a.description !== null && a.description.includes(inp) || a.address1 !== null && a.address1.includes(inp) || a.city !== null && a.city.includes(inp) || a.relatives !== null && a.relatives.includes(inp)))
        window.scrollTo(0, 0);
    }

    return <div>
        {tempComes.length > 0 && <Search funcSearch={funcSearch} txt={'ברשימת מוזמנים וכתובות'}></Search>}

        <p ref={ref}
            style={{
                fontSize: '150%', fontWeight: '500', marginTop: '8%',
                transform: isVisible ? 'translateY(0)' : 'translateY(500px)', // Animating the 'Y' position of the div
                opacity: isVisible ? 1 : 0, // Fading in the div
                transition: 'transform 0.5s, opacity 0.5s', // Adding smooth transition
            }}>רשימת מוזמנים וכתובות</p>

        <div ref={ref} style={{ transition: 'width 0.5s ease-in-out' }} className={`underline3 ${isVisible ? "visible3" : ""} `}></div>

        {s.id && weddingDate > 0 && <div style={{ position: 'absolute', left: '4%', marginTop: '-6%', backgroundColor: 'white', padding: '1%', zIndex: '50' }}>{s.chatanOrKala}, בעוד {weddingDate} ימים החתונה!</div>}

        <div style={{ width: '17%', position: 'fixed', marginTop: '0' }}>
            <div style={{ width: '90%', marginRight: '10%' }}>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/taskList`)}><ButtonRed text='רשימת מטלות'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/choozedBussness`)}><ButtonRed text='ההזמנות שלי'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/howManyToPay`)}><ButtonRed text='ארגון הוצאות'></ButtonRed></div>
                {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth <= 1300 && <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed is='this' text='רשימת כתובות'></ButtonRed></div>}
                {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth > 1300 && <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed is='this' text='רשימת מוזמנים וכתובות'></ButtonRed></div>}
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/PersonalArea`)}><ButtonRed text='איזור אישי'></ButtonRed></div>
            </div>
        </div>

        <div id='example' style={{ marginTop: '2%', width: '75%', position: 'relative', marginRight: '5%' }}>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ width: '18%', textAlign: 'center' }}>קירבה<p style={{ fontWeight: '300', fontSize: '85%', lineHeight: '20px', marginTop: '2px', marginBottom: '-10px', wordBreak: 'break-word' }}>(מצד האב / מצד האם / שכנים / חברים)</p></th>
                        <th style={{ width: '18%', textAlign: 'center' }}>שם</th>
                        <th style={{ width: '18%', textAlign: 'center' }}>כינוי להדפסה</th>
                        <th style={{ width: '18%', textAlign: 'center' }}>כתובת</th>
                        <th style={{ width: '18%', textAlign: 'center' }}>עיר</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        <td style={{ textAlign: 'center', height: '200%' }}>חבר מהשכונה</td>
                        <td style={{ textAlign: 'center', height: '200%' }}>יצחק לוי</td>
                        <td style={{ textAlign: 'center', height: '200%' }}>לכבוד הרב לוי שליט"א</td>
                        <td style={{ textAlign: 'center', height: '200%' }}>בן איש חי 30</td>
                        <td style={{ textAlign: 'center', height: '200%' }}>בית שמש</td>
                        <td></td><td></td>
                    </tr>
                </tbody>
            </table>
            <img src={example} style={{ position: 'absolute', top: '0%', left: '-17%', width: '70px', height: '100%', backgroundColor: 'white' }}></img>
            <CloseOutlined style={{ position: 'absolute', top: '5%', left: '-18%', width: '7px', height: 'auto', backgroundColor: 'white' }} onClick={() => {document.getElementById('example').style.marginRight = '100%'; setTimeout(() => {document.getElementById('tableAddress').style.marginTop = '-170px'}, 400) }} />
        </div>

        <img src={example} style={{ position: 'absolute', top: '28%', left: '-0.7%', width: '70px', height: '23%', backgroundColor: 'white' }} onClick={() => {document.getElementById('tableAddress').style.marginTop = '5%'; setTimeout(() => {document.getElementById('example').style.marginRight = '5%'}, 220) }}/>


        <table id='tableAddress' style={{ marginTop: '5%', width: '75%' }}>
            <thead>
                <tr>
                    <th style={{ width: '20%', textAlign: 'center' }}>קירבה<p style={{ fontWeight: '300', fontSize: '85%', lineHeight: '20px', marginTop: '2px', marginBottom: '-10px', wordBreak: 'break-word' }}>(מצד האב / מצד האם / שכנים / חברים)</p></th>
                    <th style={{ width: '20%', textAlign: 'center' }}>שם</th>
                    <th style={{ width: '20%', textAlign: 'center' }}>כינוי להדפסה</th>
                    <th style={{ width: '20%', textAlign: 'center' }}>כתובת</th>
                    <th style={{ width: '20%', textAlign: 'center' }}>עיר</th>
                </tr>
            </thead>
            <tbody>{comes && <>
                {comes.map((x, i) => (
                    <tr key={i} style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        {x.id !== updateAd.id && <Fragment>
                            <td style={{ textAlign: 'center', height: '200%' }}>{x.relatives}</td>
                            <td style={{ textAlign: 'center', height: '200%' }}>{x.name}</td>
                            <td style={{ textAlign: 'center', height: '200%' }}>{x.description}</td>
                            <td style={{ textAlign: 'center', height: '200%' }}>{x.address1}</td>
                            <td style={{ textAlign: 'center', height: '200%' }}>{x.city}</td>
                            {/* <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.name}></input></td>
                            <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.address1}></input></td>
                            <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.city}></input></td> */}
                            <td><IconButton onClick={() => { setUpdateAd(x); setMan({ relatives: x.relatives, name: x.name, description: x.description, address1: x.address1, city: x.city }); }} className='IconButton' size="medium">
                                <EditOutlined />
                            </IconButton></td>
                            <td><IconButton onClick={() => setDelAddress(x.id)} className='IconButton' size="medium">
                                <CloseOutlined />
                            </IconButton></td>
                        </Fragment>}
                        {updateAd.id == x.id && <Fragment>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.relatives} onChange={(e) => { if (e.target.value.length <= 40) { setMan({ ...man, relatives: e.target.value }) } }} defaultValue={x.relatives}></input></td>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.name} onChange={(e) => { if (e.target.value.length <= 20) { setMan({ ...man, name: e.target.value }) } }} defaultValue={x.name}></input></td>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.description} onChange={(e) => { if (e.target.value.length <= 50) { setMan({ ...man, description: e.target.value }) } }} defaultValue={x.description}></input></td>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.address1} onChange={(e) => { if (e.target.value.length <= 40) { setMan({ ...man, address1: e.target.value }) } }} defaultValue={x.address1}></input></td>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.city} onChange={(e) => { if (e.target.value.length <= 30) { setMan({ ...man, city: e.target.value }) } }} defaultValue={x.city}></input></td>
                            <div onClick={() => { UpdateAddress(x.id, { relatives: man.relatives, name: man.name, description: man.description, address1: man.address1, city: man.city, userId: s.id }).then(data => {setAllComes(data); d(COMPLETEaddress(data))}).catch(error => console.error(error)); setMan({ relatives: '', name: '', description: '', address1: '', city: '' }); setUpdateAd(false) }} style={{ width: '150%', marginTop: '18%', marginRight: '10%' }}><ButtonRed text='אישור'></ButtonRed></div>
                        </Fragment>}

                    </tr>
                ))}</>}

                {open && <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.relatives} onChange={(e) => { if (e.target.value.length <= 40) { setMan({ ...man, relatives: e.target.value }) } }}></input></td>
                    <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.name} onChange={(e) => { if (e.target.value.length <= 20) { setMan({ ...man, name: e.target.value }) } }}></input></td>
                    <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.description} onChange={(e) => { if (e.target.value.length <= 50) { setMan({ ...man, description: e.target.value }) } }}></input></td>
                    <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.address1} onChange={(e) => { if (e.target.value.length <= 40) { setMan({ ...man, address1: e.target.value }) } }} ></input></td>
                    <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={man.city} onChange={(e) => { if (e.target.value.length <= 30) { setMan({ ...man, city: e.target.value }) } }} ></input></td>
                    <td><IconButton onClick={() => { AddAddress({ relatives: man.relatives, name: man.name, description: man.description, address1: man.address1, city: man.city, userId: s.id }).then(data => { setAllComes(data); d(COMPLETEaddress(data)) }).catch(error => console.error(error)); setMan({ relatives: '', name: '', description: '', address1: '', city: '' }) }} className='IconButton' size="medium">
                        <CheckOutlined />
                    </IconButton></td>
                    <td>  <IconButton onClick={() => setOpen(false)} className='IconButton' size="medium">
                        <CloseOutlined />
                    </IconButton></td>
                </tr>}
            </tbody>
        </table>
        <div onClick={() => s.id ? setOpen(true) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' })} className='buton' style={{ marginTop: '0', float: 'left', marginLeft: '5%', width: 'fit-content' }}><ButtonRed text='הוסף מוזמן'></ButtonRed></div>


        <div style={{ width: '87%', marginRight: '13%', textAlign: 'center' }}>

            {comes.length === 0 && tempComes.length > 0 && <div style={{ marginTop: '2%', width: '20%', marginRight: 'auto', marginLeft: 'auto' }}>לא נמצאו תוצאות...</div>}

            <div onClick={() => s.id ? setLogin2(true) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' })} className='buton' style={{ marginTop: '10%', width: 'fit-content', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='העתקת מוזמנים מילד קודם השמורים במערכת'></ButtonRed></div>
            {login2 && <Login setLogin2={setLogin2} setComes={setAllComes} fromPrevChild={true}></Login>}

            <p style={{ marginTop: '5%' }}>ניתן להפוך את טבלת המוזמנים לעיצוב מדבקות להדבקה על ההזמנות בלחיצת כפתור</p>
            <div className='buton' onClick={() => { setNul(false); comes ? setOpenMadbekot(true) : setNul(true); }} style={{ marginTop: '1%', width: 'fit-content', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='הפוך למדבקות'></ButtonRed></div>
            {nul && <div>רשימת המוזמנים ריקה !</div>}

            <p style={{ marginTop: '2%' }}>מומלץ להדפיס את המדבקות על דפי מדבקה בצבע שקוף</p>
        </div>

        {openMadbekot && <div>
            <p style={{ marginTop: '1%' }}>מספר טורים <input type='number' max={5} min={2} value={tur} onChange={(e) => { setTur(e.target.value); t = e.target.value; changePages() }} onKeyDown={(e) => e.preventDefault()} ></input>
                {/* מספר שורות <input type='number' max={9} min={6} value={shura} onChange={(e) => { setShura(e.target.value); sh = e.target.value; changePages() }} onKeyDown={(e) => e.preventDefault()} ></input> */}
            </p>

            {pages.map((a, index) => (
                <div key={index} className='a4' style={{
                    display: 'grid', gridTemplateColumns: `repeat(${tur},1fr)`, gridTemplateRows: `repeat(${shura},1fr)`, gridGap: '0px', fontSize: '80%',
                    lineHeight: `${tur == 2 ? '46px' : tur == 3 ? '34px' : tur == 5 ? '25px' : '29px'}`
                }}>
                    {comes.slice(index * itemsPerPage, (index + 1) * itemsPerPage).map((x, i) => (
                        <div key={i} style={{ fontWeight: '300' }}>
                            <p style={{ marginBottom: '-10%' }}>{x.description}</p>
                            <p style={{ marginBottom: '-10%' }}>{x.address1}</p>
                            <p style={{ marginBottom: `${tur == 2 ? '-30px' : tur == 3 ? '-25px' : tur == 5 ? '-15px' : '-20px'}` }}>{x.city}</p>
                        </div>
                    ))}
                </div>
            ))}

            <div className='buton' onClick={() => convertToPdf()} style={{ marginTop: '1%', width: 'fit-content' }}><ButtonRed text='שמור כקובץ PDF'></ButtonRed></div>
        </div>}



        {delAddress && <AlertDelete set={setDelAddress} func={DellAddress} text={'כתובת'}></AlertDelete>}
        {/* {updateAd && <AlertAddAddress set={setUpdateAd} func={setAllComes}></AlertAddAddress>} */}

        {login && <Login setLogin={setLogin}></Login>}
        {connection && <Connection set={setConnection}></Connection>}

        {openGlobal && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenGlobal(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
                    <div className='titl' style={{ marginTop: '4%', fontSize: '120%', marginBottom: '5%' }}>{openGlobal.text}</div>
                    <div style={{ marginTop: '-2%' }}>{openGlobal.text2}</div>
                    {openGlobal.input && <div>
                        <div>{openGlobal.input}</div>
                        {/* <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => isUpdatePaid()}><ButtonRed text='אישור'></ButtonRed></div> */}
                    </div>}
                    {!openGlobal.input && <div>
                        <div style={{ width: '10%', display: 'inline-block', marginTop: '4%' }} onClick={() => { setLogin(true); setOpenGlobal(false) }}><ButtonRed text='התחבר'></ButtonRed></div>
                        <div style={{ width: '10%', display: 'inline-block' }} onClick={() => { setConnection(true); setOpenGlobal(false) }}><ButtonRed text='הירשם'></ButtonRed></div>
                    </div>}
                </div>
            </div>
        </div>}


        <BottomMenu></BottomMenu>
    </div>
}

export default ListAddress


{/* <input type="reset" value="Reset"> */ }