import { CloseOutlined } from '@ant-design/icons';
import './ErrorPass.css'
import { useEffect, useRef, useState } from 'react';
import ButtonRed from '../../global/button/button';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import emailjs from 'emailjs-com';
import picError from '../../../pic/animations/113.gif'
import { SendEmailToBussiness } from '../../axios/api';


const ErrorPass = (prop) => {
    const [userCurrent, setUserCurrent] = useState(prop.userCurrent);
    const [name, setName] = useState();
    const [mail125, setMail125] = useState();
    const [bussiness, setBussiness] = useState(prop.bussiness);

    const [pass, setPass] = useState();
    const [temp, setTemp] = useState(false);

    //const [email, setEmail] = useState({name:'', pass:'', mail:''});
    var email = {};
    const [errorMessage, setErrorMessage] = useState('');
    const [first, setFirst] = useState(true);
    const [second, setSecond] = useState(false);

    const handlePassChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Numeric input and valid phone number format
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setPass(inputValue);
        }
    };

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Only letters allowed
        if (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') {
            setName(inputValue);
        }
    };

    const handleSubmit = (e) => {
        setErrorMessage('');
        if (first && !prop.bussiness) {
            userCurrent.sort((a, b) => a.id > b.id ? 1 : -1)
            if (name === '') {
                setErrorMessage('לא הוכנסו כל הפרטים');
            } else {
                setErrorMessage('');
                userCurrent.forEach(element => {
                    if (element.lastName === name) {
                        setTemp(true)
                        setFirst(false)
                        setSecond(true)
                        var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום למשפחת ${name} </p></div><br/>  <br/>   <b>סיסמתכם השמורה במערכת היא: </b> ${element.pass}.<br/> בשמחות תמיד <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                        SendEmailToBussiness({ name: "מיזם קול שמחה", emailTo: element.mail, emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - שחזור סיסמה', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                    }
                });
                if (temp)
                    setErrorMessage('הפרטים אינם נכונים, נסו להתחבר מחדש');
            }
        }

        else if (first && prop.bussiness) {
            if (mail125 === '') {
                setErrorMessage('לא הוכנסו כל הפרטים');
            } else {
                setErrorMessage('');
                if (bussiness.mail === mail125) {
                    setFirst(false)
                    setSecond(true)
                    var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 110% ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לעסק ${bussiness.name} </p></div><br/>  <br/>   <b>סיסמתכם השמורה במערכת היא: </b> ${bussiness.code}.<br/> <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="http://localhost:3000" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                    SendEmailToBussiness({ name: "מיזם קול שמחה", emailTo: bussiness.mail, emailFrom: 'r0527117663@gmail.com', subject: 'מיזם קול שמחה - שחזור סיסמה', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                }
                else
                    setErrorMessage('הפרטים אינם נכונים, נסו להתחבר מחדש');
            }
        }
        else {
            if (pass === '') {
                setErrorMessage('לא הוכנסה סיסמה');
            }
            else if (!prop.bussiness) {
                userCurrent.forEach(element => {
                    if (element.pass === pass) {
                        prop.good(element)
                        return;
                    }
                })
                setErrorMessage('סיסמה שגויה');
            }
            else {
                if (Number(pass) !== bussiness.code)
                    setErrorMessage('סיסמה שגויה');
                else {
                    prop.good(true)
                }
            }
        }
    };




    const ref2 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible3')
                }
            });
        });

        if (ref2.current) { observer.observe(ref2.current); }

        return () => {
            if (ref2.current) { observer.unobserve(ref2.current); }
        };
    }, []);



    return <div id='modal'>
        <div id='formCon111' className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close' onClick={() => prop.set(false)} style={{ cursor: 'pointer', position: 'absolute', left: '1.5%', marginTop: '1.5%' }} />
            <div style={{ fontSize: '150%', paddingTop: '3%' }}>שיחזור סיסמה</div>
            <div ref={ref2} style={{ transition: 'width 1s ease-in-out' }} className='underline3 bottom5'></div>

            <div>
                {first && !prop.bussiness && <><div style={{ fontSize: '120%', paddingTop: '4%' }}>מהו שם המשפחה שלכם?</div>
                    <input className='input111' autoComplete="on" type="text" value={name || ''} onChange={handleNameChange} /></>}

                {first && prop.bussiness && <><div style={{ fontSize: '120%', paddingTop: '4%' }}>מהי כתובת המייל של העסק הרשומה במערכת?</div>
                    <input className='input111' autoComplete="on" type="text" value={mail125 || ''} onChange={(e) => {setMail125(e.target.value)}} /></>}

                {second && <><div style={{ fontSize: '120%', paddingTop: '2%' }}>ברגעים אלו נשלחת אליכם הודעה למייל בה כתובה סיסמתכם</div>
                    <div style={{ fontSize: '110%', paddingTop: '2%' }}>הכנס סיסמה</div>
                    <input className='input111' autoComplete="on" type="text" value={pass || ''} onChange={handlePassChange} /></>}

            </div>
            {errorMessage && <p style={{ position: 'absolute', bottom: '10%', width: '20%', right: '40%' }}><img src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
            <div onClick={() => handleSubmit()} className='buttonRed' style={{ width: '20%', display: 'inline-block', position: 'absolute', bottom: '-5%', backgroundColor: 'white', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', left: '10%', border: '#FF7D7D 5px solid' }}><ButtonRed text={'אישור'}></ButtonRed></div>

        </div>
    </div>
}

export default ErrorPass

