
const thisUser = {
    
};

const userReducer = (state = thisUser, action) => {
  switch (action.type) {
    case "COMPLETEU":
        return action.payload;
    default:
      return state;
  }
};



export default userReducer;

