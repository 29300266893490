
const iconAndLogo = {
  icon: [],
  logo: []
};

const iconAndLogoReducer = (state = iconAndLogo, action) => {
  switch (action.type) {
    case "COMPLETEicon":
      return { ...state, icon: action.payload };
    case "COMPLETElogo":
      return { ...state, logo: action.payload };
    case "COMPLETEaddlogo":
      return { ...state, logo: [...state.logo, action.payload] };
    default:
      return state;
  }
};



export default iconAndLogoReducer;

