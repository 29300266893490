// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardImg {
    position: relative;
    margin: 5%;
    display: inline-block;
    transition: 1s;
}

.img {
    border-radius: 5px 5px 5px 5px;
    width: 200px;
    height: 240px;
    z-index: 1;
    background-size: cover;
    background-position: bottom left;
    transition: background-position 0.4s ease-in-out;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

}

.img:hover {
    background-size: cover;
    background-position: top right;
    box-shadow: 0 2px 10px rgba(1, 1, 1, 1);
}

#txt {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    padding: 5px;
    position: absolute;
    top: 20px;
    z-index: 2;
    font-weight: 400;
}

.img:hover #txt {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
}

/* .underlineRed {
    margin-top: -35px;
    margin-bottom: 40px;
    width: 80px;
    height: 4px;
    background-color: #C00000;
    margin-left: auto;
    margin-right: auto;
} */

.title789 {
    padding-top: 10%;
}

@media only screen and (max-width: 1300px) {
    .title789 {
        padding-top: 15%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/global/image/Image.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,aAAa;IACb,UAAU;IACV,sBAAsB;IACtB,gCAAgC;IAChC,gDAAgD;IAChD,yCAAyC;;AAE7C;;AAEA;IACI,sBAAsB;IACtB,8BAA8B;IAC9B,uCAAuC;AAC3C;;AAEA;IACI,0CAA0C;IAC1C,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,YAAY;AAChB;;AAEA;;;;;;;;GAQG;;AAEH;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".cardImg {\r\n    position: relative;\r\n    margin: 5%;\r\n    display: inline-block;\r\n    transition: 1s;\r\n}\r\n\r\n.img {\r\n    border-radius: 5px 5px 5px 5px;\r\n    width: 200px;\r\n    height: 240px;\r\n    z-index: 1;\r\n    background-size: cover;\r\n    background-position: bottom left;\r\n    transition: background-position 0.4s ease-in-out;\r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);\r\n\r\n}\r\n\r\n.img:hover {\r\n    background-size: cover;\r\n    background-position: top right;\r\n    box-shadow: 0 2px 10px rgba(1, 1, 1, 1);\r\n}\r\n\r\n#txt {\r\n    background-color: rgba(255, 255, 255, 0.8);\r\n    width: 100%;\r\n    padding: 5px;\r\n    position: absolute;\r\n    top: 20px;\r\n    z-index: 2;\r\n    font-weight: 400;\r\n}\r\n\r\n.img:hover #txt {\r\n    background-color: rgba(0, 0, 0, 0.6);\r\n    color: white;\r\n}\r\n\r\n/* .underlineRed {\r\n    margin-top: -35px;\r\n    margin-bottom: 40px;\r\n    width: 80px;\r\n    height: 4px;\r\n    background-color: #C00000;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n} */\r\n\r\n.title789 {\r\n    padding-top: 10%;\r\n}\r\n\r\n@media only screen and (max-width: 1300px) {\r\n    .title789 {\r\n        padding-top: 15%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
