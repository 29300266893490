// import logo from './logo.svg';
// import './App.css';
import React from 'react'
import { Provider } from 'react-redux'
import store from './components/redux/store/store';


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Menu from './components/pages/menu/Menu';
import HomePage2 from './components/pages/home/HomePage2';
import HowItWork from './components/pages/howItWork/HowItWork';
import TaskList from './components/pages/personalOrganizer/TaskList';
import AddBusiness from './components/pages/addBusiness/AddBusiness';
import Erusin from './components/pages/erusin/Erusin';
import FromWedding from './components/pages/fromWedding/FromWedding';
import ShabatChatan from './components/pages/shabatChatan/ShabatChatan';
import Wedding from './components/pages/wedding/Wedding';
import AllCards from './components/global/card/AllCards';
import ShevaBrachot from './components/pages/shevaBrachot/ShevaBrachot';
import Bussiness from './components/global/bussiness/Bussiness';
import HowManyToPay from './components/pages/personalOrganizer/HowManyToPay';
import ChoozedBussness from './components/pages/personalOrganizer/ChoozedBussness';
import ListAddress from './components/pages/personalOrganizer/ListAddress';
import PicForMenu from './components/global/image/PicForMenu';
import MenuManagger from './components/managger/MenuManagger';
import AddToDataBase from './components/pages/addBusiness/addToDataBase/AddToDataBase';
import OnScroll from './components/global/onScroll/OnScroll';
import PersonalArea from './components/pages/connection/PersonalArea';
import ComesFromPrevChild from './components/pages/login/ComesFromPrevChild';
import Down from './components/global/Down';
import Kochavit from './components/global/bussiness/Kochavit';
import EditBussiness from './components/global/bussiness/ZEditBussiness/EditBussiness';
import Categories from './components/managger/Categories';
import Options from './components/managger/OptionsManagger';
import Shopping from './components/pages/personalOrganizer/Shopping';
import Mehorasim from './components/pages/mehorasim/Mehorasim';


function App() {
  return (
    <div className="App" style={{ direction: 'rtl', fontFamily: 'IBM Plex Sans Hebrew', fontSize: '160%', fontWeight: '350' }}>

      <Provider store={store}>
        <BrowserRouter>
          <Menu></Menu>
          <OnScroll></OnScroll>
          <Down></Down>
          <Routes>
            <Route exact path='/menuManagger/:p' element={< MenuManagger />}></Route>

            <Route exact path='/' element={< HomePage2 />}></Route>
            <Route exact path='/איך זה עובד?/:now' element={< HowItWork />}></Route>
            <Route exact path='/ארגונית אישית/:userId' element={< TaskList />}></Route>
            <Route exact path='/מאורסים/:userId' element={< Mehorasim />}></Route>
            <Route exact path='/taskList' element={< TaskList />}></Route>
            <Route exact path='/הצטרפות לעסקים/:now' element={< AddBusiness />}></Route>
            <Route exact path='/אירוסין/:now' element={< Erusin />}></Route>
            <Route exact path='/קודם החתונה/:now' element={< FromWedding />}></Route>
            <Route exact path='/שבת חתן/:now' element={< ShabatChatan />}></Route>
            <Route exact path='/חתונה/:now' element={< Wedding />}></Route>
            <Route exact path='/שבע ברכות/:now' element={< ShevaBrachot />}></Route>
            <Route exact path='/picForMenu' element={< PicForMenu />}></Route>
            <Route exact path='/allCards/:categoryCode/:name/:categoryName' element={< AllCards />}></Route>
            <Route exact path='/bussiness/:menu/:categoryName/:codeCategory' element={< Bussiness />}></Route>
            <Route exact path='/howManyToPay' element={< HowManyToPay />}></Route>
            <Route exact path='/choozedBussness' element={< ChoozedBussness />}></Route>
            <Route exact path='/listAddress' element={< ListAddress />}></Route>
            <Route exact path='/AddToDataBase/:bool/:codeCategory' element={< AddToDataBase />}></Route>
            <Route exact path='/PersonalArea' element={< PersonalArea />}></Route>
            <Route exact path='/Kochavit/:categoryName/:cc' element={< Kochavit />}></Route>
            <Route exact path='/Shopping' element={< Shopping />}></Route>

            {/* <Route path="*" element={<NoPage />} /> */}
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
