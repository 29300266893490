import { CloseOutlined } from '@ant-design/icons';
import './Alert.css';
import ButtonRed from '../button/button';

const AlertDelete = (prop) => {
    return <div id='modal2'>
        <div className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close2' onClick={() => prop.set(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
            <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
                <div className='titl' style={{ marginTop: '8%', fontSize: '120%', marginBottom: '5%' }}>האם ברצונך למחוק {prop.text} זו לצמיתות?</div>


                <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => prop.func()}><ButtonRed text='אישור'></ButtonRed></div>

            </div>
        </div>
    </div>
};

export default AlertDelete
