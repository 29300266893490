
export const CompleteThisBussiness = (data) => {
    return({ type: "COMPLETET", payload: data });
};

export const CompleteBussinessData = (data) => {
    return({ type: "COMPLETETbussinessData", payload: data });
};

export const CompleteOptionsBussiness = (data) => {
    return({ type: "Options", payload: data });
};

export const COMPLETEthisBussinessWithoutBussinessData = (data) => {
    return({ type: "COMPLETEthisBussinessWithoutBussinessData", payload: data });
};