// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inp:focus {
    outline: none;
}

.a4 {
    width: 8.27in;
    height: 11.9in;
    border: black 1px solid;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1.5%;
}

.inpu {
    background-color: rgb(230, 227, 227);
    text-align: center;
    padding: 3%;
    margin: -2%;
}

.inpu:focus {
    outline-color: #c00000;
}

#example {
    transition: 2s;
}

#tableAddress {
    transition: 1.5s;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/personalOrganizer/ListAddress.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".inp:focus {\r\n    outline: none;\r\n}\r\n\r\n.a4 {\r\n    width: 8.27in;\r\n    height: 11.9in;\r\n    border: black 1px solid;\r\n    margin-right: auto;\r\n    margin-left: auto;\r\n    margin-top: 1.5%;\r\n}\r\n\r\n.inpu {\r\n    background-color: rgb(230, 227, 227);\r\n    text-align: center;\r\n    padding: 3%;\r\n    margin: -2%;\r\n}\r\n\r\n.inpu:focus {\r\n    outline-color: #c00000;\r\n}\r\n\r\n#example {\r\n    transition: 2s;\r\n}\r\n\r\n#tableAddress {\r\n    transition: 1.5s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
