import { CloseOutlined } from '@ant-design/icons';
import './Alert.css';
import SendMailFOrBussiness from '../forms/SendMailFOrBussiness';

const Alert = (prop) => {
    return <div id='modal2'>
        <div className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close2' onClick={() => prop.set(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', top: '20%', zIndex:'300' }} />
            <SendMailFOrBussiness name={prop.text} set={prop.set} text2={prop.text2} sendGood={prop.sendGood}></SendMailFOrBussiness>
        </div>
    </div>
};

export default Alert
