import { useEffect, useState } from 'react';
import ButtonRed from '../../button/button';
import RefReady from '../../ref';
import './Option.css'
import AlertDelete from '../../alert/AlertDelete';
import { DelOption } from '../../../axios/api';


const Options = (prop) => {
    const [del, setDel] = useState(false)


    const allKochavim = () => {
        var sum = 0, all = [];
        op.forEach(e => {
            sum = sum + e.kochvim
        });
        all = (sum / op.length + '.').split('.')
        all[1] = all[1].charAt('1')
        // var zero = 1;
        // for (let index = all[1].length - 1; index >= 0; index--) {
        //     zero = zero * 10;
        // }
        // all[1] = prop.options.length * all[1] / zero
        // all[1] = Math.floor(parseFloat(all[1]))
        return all;
    }

    const kochavim = (k) => {
        var tag = [];
        for (let index = 0; index < k; index++) {
            tag.push(<p>&#9733;</p>)
        }
        for (let index = 0; index < 5 - k; index++) {
            tag.push(<p>&#9734;</p>)
        }
        return tag;
    }

    const [op, setOp] = useState();
    const [more, setMore] = useState(4);

    useEffect(() => {
        setOp(prop.options.sort((a, b) => a.date < b.date ? 1 : -1))
    }, [])

    const delOp = () => {
        // var oo = op
        // setOp([])
        // setTimeout(() => {
            setOp(op.filter(a => a.id !== del))
        // }, 200);
        DelOption(del).then((data) => {}).catch((error) => { console.error(error); });
        setDel(false)

    }

    return <div style={{ paddingTop: '10vh' }} id='options'>
        <div style={{ position: 'relative' }}>
            {op !== undefined && op.length !== 0 && <div>
                <p className='pInDiv' style={{ position: 'absolute', left: '33%', top: '-15px', direction: 'ltr' }}>
                    {kochavim(allKochavim()[0]).map((a, b) => (
                        <span key={b}>{a}</span>
                    ))}
                    {!allKochavim()[1] && <span>({allKochavim()[0]})</span>}
                    {allKochavim()[1] && <span>({allKochavim()[0]}.{allKochavim()[1]})</span>}

                </p>
                <p style={{ fontSize: '130%', fontWeight: '500', marginBottom: '30px' }}>{op.length} חוות דעת</p>
                <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

                {op.map((x, i) => (
                    <div key={i}>
                        {i < more && <div style={{ width: '70%', marginRight: '15%', textAlign: 'right', position: 'relative' }}>
                            <div style={{ width: '4.5rem', height: '4.5rem', fontSize: '120%', lineHeight: '2.5', color: 'white', position: 'absolute', margin: '1%', marginTop: '2%', marginRight: '2%', borderRadius: '50%', display: 'inline-block', backgroundColor: `${i % 2 === 0 ? '#c00000' : 'black'}`, border: 'black 1.5px solid', userSelect: 'none', textAlign: 'center' }}>{x.name.charAt(0)}</div>
                            <div style={{ display: 'inline-block', marginRight: '8rem' }}>
                                <p style={{ fontWeight: '400', fontSize: '93%', display: 'inline-block' }}>{x.numDates === 0 ? 'היום' : x.numDates === 1 ? 'אתמול' : x.numDates === 2 ? 'לפני יומיים' : x.numDates === 7 ? 'לפני שבוע' : x.numDates === 30 ? 'לפני חודש' : x.numDates === 365 ? 'לפני שנה' : `לפני ${x.numDates} ימים`}</p>
                                <div className='pInDiv' style={{ display: 'inline-block', position: 'absolute', left: '20%', top: '20%', direction: 'ltr', color: '#c00000' }}>
                                    {kochavim(x.kochvim).map((a, b) => (
                                        <span key={b}>{a}</span>
                                    ))}
                                </div>
                                <p style={{ fontSize: '75%', marginBottom: '-6px', marginTop: '-8px' }}>{x.name}</p>
                                <p style={{ fontSize: '95%', }}>{x.description}</p>
                            </div>
                            {prop.managger && <div style={{ position: 'absolute', top: '3%', left: '2%' }}>
                                <div className='buton1' style={{ width: '15vh' }} onClick={() => { }}><ButtonRed text='עריכה'></ButtonRed></div>
                                <div className='buton1' style={{ width: '15vh' }} onClick={() => { setDel(x.id) }}><ButtonRed text='מחיקה'></ButtonRed></div>
                            </div>}
                            <div style={{ width: '100%', height: '1px', backgroundColor: '#c00000' }}></div>
                        </div>}
                    </div>
                ))}</div>}
            {op !== undefined && op.length === 0 && <p style={{ marginBottom: '-3%', marginTop: '-3%' }}>עדיין אין חוות דעת</p>}
            {!prop.managger && <div className='buton' style={{ width: '20%', display: 'inline-block', marginLeft: '1%', marginRight: '1%', marginBottom: '4%' }} onClick={() => prop.setAddOpinion(true)}><ButtonRed text='הוספת חוות דעת'></ButtonRed></div>}
            {op?.length > 4 && <div className='buton' style={{ width: '20%', display: 'inline-block', marginRight: '1%', marginLeft: '1%' }} onClick={() => { more > 4 ? setMore(4) : setMore(op.length) }}><ButtonRed text={more > 4 ? 'פחות חוות דעת' : 'צפיה בחוות דעת נוספות'}></ButtonRed></div>}

            {del && <AlertDelete set={setDel} func={delOp} text={'חוות דעת'}></AlertDelete>}

        </div>
    </div>
}

export default Options
