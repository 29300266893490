import { styled } from 'styled-components';
import './Image.css'
import { useNavigate } from 'react-router-dom';
import RefReady from '../ref';

const ImgDiv = styled.div`
        background-image: url(${props => props.pic}); 
    `;

const ImageStyle = (prop) => {

    const navigate = useNavigate()

    return <div style={{ display: 'inline-block', marginRight: '1%' }}>
        <RefReady tag={<div className='cardImg'>
            <ImgDiv pic={prop.pic} className='img' onClick={() => navigate(`/allCards/${prop.categoryId}/${prop.text}/${prop.categoryName}`)}></ImgDiv>
            <div id='txt'>{prop.text}</div>
        </div>} event={'animate__lightSpeedInRight'}></RefReady>
    </div>

}

export default ImageStyle