import { CloseOutlined, SearchOutlined } from "@ant-design/icons"

const Search = (props) => {


    return <div>
        <div className="searchRightBlack" onClick={()=>{document.getElementsByClassName("searchRightBlack")[0].style.right = '-2%'}} style={{width:'50%', height:'50px', backgroundColor:'black', position:'absolute', top:'10%', right:'-47%', borderRadius:'5px 5px 5px 5px', textAlign:'left', color:'white', position:'fixed'}}>
            <CloseOutlined onClick={()=>{document.getElementsByClassName("searchRightBlack")[0].style.marginRight = '-45%'; props.funcSearch('')}} style={{marginLeft:'2%'}}/>
            <input className="searchBlack" type="text" placeholder={`חיפוש ${props.txt !== undefined ? props.txt : ""}`} onChange={(e)=>{props.funcSearch(e.target.value);}} style={{width:'80%', backgroundColor:'black', marginLeft:'5%', border:'none', color:'white'}}></input>
            <SearchOutlined onClick={()=>{document.getElementsByClassName("searchRightBlack")[0].style.marginRight = '0%'}} style={{marginLeft:'2%', marginTop:'1.5%'}}/>
        </div>
    </div>
}

export default Search