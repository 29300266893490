// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#button {
    /* width: 100px; */
    /* display: inline-block; */
    height: 45px;
    z-index: 1000;
    /* border-radius: 20px 20px 20px 20px; */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    /* padding: 7px; */
    text-align: center;
    direction: rtl;
    color: black;
    cursor: pointer;
    /* transition: background-color 2s ease-in-out;
    transition: width 0.5s ease-in-out; */
    -webkit-user-select: none;
            user-select: none;
    /* line-height: 5vh; */
}

#buttonColor {
    background: linear-gradient(to left, #c00000, #FF7D7D);
    /* border-radius: 20px 20px 20px 20px; */
    height: 40px;
    width: 0;
    /* padding: 7px; */
}

#button:hover {
    color: white;
    font-weight: 400;
}


`, "",{"version":3,"sources":["webpack://./src/components/global/button/button.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,YAAY;IACZ,aAAa;IACb,wCAAwC;IACxC,yCAAyC;IACzC,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,eAAe;IACf;yCACqC;IACrC,yBAAiB;YAAjB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,sDAAsD;IACtD,wCAAwC;IACxC,YAAY;IACZ,QAAQ;IACR,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":["#button {\r\n    /* width: 100px; */\r\n    /* display: inline-block; */\r\n    height: 45px;\r\n    z-index: 1000;\r\n    /* border-radius: 20px 20px 20px 20px; */\r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);\r\n    /* padding: 7px; */\r\n    text-align: center;\r\n    direction: rtl;\r\n    color: black;\r\n    cursor: pointer;\r\n    /* transition: background-color 2s ease-in-out;\r\n    transition: width 0.5s ease-in-out; */\r\n    user-select: none;\r\n    /* line-height: 5vh; */\r\n}\r\n\r\n#buttonColor {\r\n    background: linear-gradient(to left, #c00000, #FF7D7D);\r\n    /* border-radius: 20px 20px 20px 20px; */\r\n    height: 40px;\r\n    width: 0;\r\n    /* padding: 7px; */\r\n}\r\n\r\n#button:hover {\r\n    color: white;\r\n    font-weight: 400;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
