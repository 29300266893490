import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RefReady from "../../global/ref";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import { GetOneBussiness, GetShopCartBussiness } from "../../axios/api";
import { IconButton } from "@mui/material";
import Alert from "../../global/alert/Alert";
import { CompleteThisBussiness } from "../../redux/actions/thisBussinessActions";
import { COMPLETEallShoppingCart, COMPLETEshoppingCartLotOfItems } from "../../redux/actions/shoppingCartAction";
import BottomMenu from "../../global/bottomMenu/BottomMenu";
import ButtonRed from "../../global/button/button";

const Shopping = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const s = useSelector(a => a.userReducer)
    const sShopping = useSelector(a => a.shoppingCartReducer.allShoppingCart)
    const navigate = useNavigate()
    const [weddingDate, setWeddingDate] = useState(false);
    const [shopping, setShopping] = useState([]);
    const [plus, setPlus] = useState([]);
    const [alert, setAlert] = useState(false)
    const d = useDispatch()

    useEffect(() => {
        if (s.id && sShopping.length <= 0) {
            GetShopCartBussiness(s.id).then((data) => {
                setShopping(data)
                d(COMPLETEallShoppingCart(data))
            }).catch((error) => { console.error(error); });
        }
        else {
            setShopping(sShopping)
        }
    }, [s])

    useEffect(() => {
        setWeddingDate(Math.ceil((new Date(s.weddingDate) - new Date()) / (24 * 60 * 60 * 1000)));
    }, [s]);

    return <div>
        {s.id && weddingDate > 0 && <div style={{ position: 'fixed', left: '5%', marginTop: '-5%', backgroundColor: 'white' }}>{s.chatanOrKala}, בעוד {weddingDate} ימים החתונה!</div>}

        <p style={{ fontSize: '150%', fontWeight: '500', paddingTop: '5%', width: '30%', marginRight: '35%' }}>כל הפריטים שבחרתם</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        <div onClick={() => { navigate(-1) }} style={{ backgroundColor: 'rgb(218, 166, 166)', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', borderRadius: '4px', cursor: 'pointer', width: '5%', height: '6vh', paddingTop: '10px', position: 'absolute', top: '10vh', right: '5vh' }}><ArrowRightOutlined /></div>
        
        <div style={{ width: 'max-content', position:'absolute', top:'15%', left:'10%' }} onClick={() => {navigate(`/choozedBussness`)}}><ButtonRed text='עבור להזמנות שבוצעו'></ButtonRed></div>


        <p>לחץ על נותן השירות כדי לעבור אליו להמשך ביצוע ההזמנה</p>
        <table style={{ marginTop: '2%', width: '80%', marginRight: '10%' }}>
            <thead>
                <tr style={{ textAlign: 'center' }}>
                    <th style={{ width: '15%' }}>תאריך שינוי אחרון</th>
                    <th style={{ width: '20%' }}>נותן השירות</th>
                    <th style={{ width: '35%' }}>המוצרים שנבחרו</th>
                    <th style={{ width: '15%' }}>יצירת קשר</th>
                    <th style={{ width: '15%' }}>סכום נוכחי לתשלום</th>
                </tr>
            </thead>
            {shopping && <tbody>
                {shopping.map((x, i) => (
                    <Fragment key={i}><tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        <td>{x.date.split('T')[0]}</td>
                        <td className='kishur' style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => { GetOneBussiness(x.categoryCode, x.bussinessId).then((data) => { d(CompleteThisBussiness(data)); d(COMPLETEshoppingCartLotOfItems(x.shoppingCartDetails)); navigate(`/bussiness/${'ההזמנות שלי'}/${'סל קניות'}/${x.categoryCode}`) }).catch((error) => { console.error(error); }); }}>{x.nameBussiness}</td>
                        {x.shoppingCartDetails.length > 0 && <td>
                            <IconButton align="left" className='IconButton' size="big" onClick={() => { if (plus.includes(i)) { setPlus(plus.filter((x) => x != i)); } else setPlus([...plus, i]) }}>
                                {plus.includes(i) ? <CloseOutlined /> : 'הצג פרטים'}
                            </IconButton>
                            {plus.includes(i) && <div style={{ marginTop: '5%' }}>{x.shoppingCartDetails.map((y, j) => (
                                <div key={j} className='proAll' style={{ border: 'rgb(128, 14, 14) 2px solid', marginTop: '1%', position: 'relative', width: '100%', padding: '0', minHeight: 'fit-content' }}>
                                    {/* <div style={{ height: '11.6vh', display: 'inline-block', width: '25%', textAlign: 'center', paddingTop: '4%', float: 'right', cursor:'auto' }}>{y.name}</div> */}
                                    <div style={{ height: '12vh', display: 'inline-block', marginRight: '5%', textAlign: 'right', width: '75%', lineHeight: '5px', fontWeight: '300' }} className='prudu2'><p>{y.name}</p><b>מחיר: </b>{y.price} ש"ח <p><b>כמות: </b>{y.amount}</p></div>
                                    <img src={`data:image/jpeg;base64,${y.picture}`} className='proPic2'></img>
                                </div>
                            ))}</div>}
                        </td>}
                        {x.shoppingCartDetails.length === 0 && <td>
                            אין פרטים
                        </td>}
                        <td className='kishur' style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => setAlert(x)}>שלח הודעה</td>
                        <td>{x.sum} ש"ח</td>
                    </tr>
                    </Fragment>
                ))}
            </tbody>}
        </table>

        {shopping.length <= 0 && <p style={{ fontSize: '120%', marginTop: '10%' }}>לא נבחרו פריטים</p>}

        {alert && <Alert set={setAlert} text={alert.nameBussiness} mail={alert.mailBussiness}></Alert>}

        <BottomMenu></BottomMenu>
    </div>
};

export default Shopping