import { combineReducers, createStore } from 'redux';
import menuReducer from '../reducers/menuReducer';
import bussinessReducer from '../reducers/bussinessReducer';
import thisBussinessReducer from '../reducers/thisBussinessReducer';
import userReducer from '../reducers/userReducer';
import devitionReducer from '../reducers/devitionReducer';
import shoppingCartReducer from '../reducers/shoppingCartReducer';
import iconAndLogoReducer from '../reducers/iconsAndLogoReducer';
import tasksReducer from '../reducers/tasksReducer';
import ordersReducer from '../reducers/ordersReducer';
import paysReducer from '../reducers/paysReducer';
import addressReducer from '../reducers/addressReducer';
import managgerReducer from '../reducers/managgerReducer';
import mehorasimReducer from '../reducers/mehorasimReducer';


const reducers =  combineReducers({mehorasimReducer, managgerReducer, addressReducer, paysReducer, ordersReducer, tasksReducer, iconAndLogoReducer, devitionReducer, menuReducer, bussinessReducer, thisBussinessReducer, userReducer, shoppingCartReducer})
const store = createStore(reducers);
export default store;