import React, { useEffect, useState } from 'react';
import './Menu.css';
import { BiUser } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom';
import Login from '../login/Login';
import Connection from '../connection/Connection';
import { GetAllCategoryWithDevition, GetAllPicForMenu, GetShopCartBussiness, getAllBigMenu } from '../../axios/api';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteMenu } from '../../redux/actions/menuActions';
import { CloseOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { IconButton } from '@mui/material';
import { CompleteDevition } from '../../redux/actions/devitionActions';
import EnterManagger from '../../managger/enterManagger';
import ButtonRed from '../../global/button/button';
import Alert from '../../global/alert/Alert';
import Wait from '../../global/Wait';
import { COMPLETEallShoppingCart } from '../../redux/actions/shoppingCartAction';

const Menu = () => {

    // log.info('Message one');
    // log.warn('Message two');

    const navigate = useNavigate()
    const d = useDispatch()
    const user = useSelector((state) => state.userReducer)

    const [getMenu, setGetMenu] = useState(null);
    const [name, setName] = useState();
    const [wait, setWait] = useState();
    const [isBuild, setIsBuild] = useState(false);
    const [isBuildFinish, setIsBuildFinish] = useState(false);

    const [stateNow, setStateNow] = useState(0);
    var now = 0;
    const [isHovered, setIsHovered] = useState(false);
    var arr = [];
    var sum = 0;
    var num = -1;

    const sShopping = useSelector(a => a.shoppingCartReducer.allShoppingCart)
    const [shoppingLength, setShoppingLength] = useState(0);

    useEffect(() => {
        if (!isBuildFinish) {
            setTimeout(() => {
                setIsBuildFinish(true)
                setIsBuild(true)
            }, 120 * 1000);
        }
    }, []);

    useEffect(() => {
        if (user.id) {
            setName(user.lastName)
            if (sShopping.length <= 0) {
                GetShopCartBussiness(user.id).then((data) => {
                    setShoppingLength(data.length)
                    d(COMPLETEallShoppingCart(data))
                }).catch((error) => { console.error(error); });
            } else {
                setShoppingLength(sShopping.length)
            }
        }
    }, [user, sShopping]);


    useEffect(() => {
        setWait(true)
        getAllBigMenu().then((data) => {
            GetAllPicForMenu().then((dataPic) => {
                var numA = 0, numB = 0;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].titles != null) {
                        data[i].titles = data[i].titles.split(',')
                        data[i].categories = data[i].categories.split(',')
                        data[i].howMany = data[i].howMany.split(',')
                        data[i].categoryId = data[i].categoryId.split(',')
                        numB += data[i].categories.length
                        data[i].categoryPic = dataPic.slice(numA, numB)
                        numA += data[i].categories.length
                        arr = [];
                        sum = 0;
                        num = -1;
                        for (let index = 0; index < data[i].howMany.length; index++) {
                            if (index == 0)
                                num = data[i].howMany[index]
                            if (num == data[i].howMany[index])
                                sum = sum + 1;
                            else {
                                arr.push(sum)
                                sum = 1
                                num = data[i].howMany[index];
                            }
                            if (index + 1 == data[i].howMany.length)
                                arr.push(sum)
                        }
                        data[i].howMany = arr;
                    }
                }
                setGetMenu(data);
                setWait(false)
                window.scrollTo(0, 0);
                d(CompleteMenu(data));
            }).catch((error) => { console.error(error); });
        }).catch((error) => { console.error(error); });
    }, []);


    useEffect(() => {
        GetAllCategoryWithDevition().then((data) => {
            d(CompleteDevition(data))
        }).catch((error) => { console.error(error); });
    }, []);

    const [current, setCurrent] = useState(0);
    const [biUser, setBiUser] = useState(false);
    const [login, setLogin] = useState(false);
    const [connection, setConnection] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [alert, setAlert] = useState(false)

    const choozeMe = () => {
        for (let index = 0; index < document.getElementsByClassName('chooze1').length; index++) {
            document.getElementsByClassName('chooze1')[index].classList.remove('chooze1')
        }
        for (let index = 0; index < document.getElementsByClassName('chooze2').length; index++) {
            document.getElementsByClassName('chooze2')[index].classList.remove('chooze2')
        }
        current.classList.add('chooze1')
        current.getElementsByClassName('mainunderline')[0].classList.add('chooze2')
    }

    var timeBegin = 0, timeEnd = 0;
    const abc = 78 * 65 + 54;
    const [resultSearch, setResultSearch] = useState([])
    const [resultSearchNow, setResultSearchNow] = useState()
    const searchData = (search) => {
        var result = []
        for (let i = 0; i < getMenu.length; i++) {
            if (getMenu[i].categories) {
                for (let index = 0; index < getMenu[i].categories.length; index++) {
                    if (!result.some(c => c.categoryCode === getMenu[i].categoryId[index]))
                        result.push({ categoryName: getMenu[i].categories[index], categoryCode: getMenu[i].categoryId[index], menu: getMenu[i].name })
                }
            }
        }
        result.sort((a, b) => a.categoryName > b.categoryName ? 1 : -1)
        setResultSearch(result.filter(c => c.categoryName && c.categoryName.includes(search)) || []);
    }

    const [managger, setManagger] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const passManaggerSubmit = () => {
        if (Number(document.getElementById('passManag').value) !== 214382038)
            setErrorMessage('סיסמה שגויה')
        else {
            setManagger(false)
            navigate(`menuManagger/${abc}`)
        }
    }

    useEffect(() => {
        const handleContextMenu = (event) => {
            if (event.target.id == 'modal2')
                event.preventDefault(); // Cancels the default right-click behavior
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const [send, setSend] = useState(false)

    const sendGood = () => {
        setAlert(false)
        setSend("ההודעה נשלחה בהצלחה !!!")
        setTimeout(() => {
            setSend(false)
        }, 40 * 100);
    }

    const clickUseFull = () => {
        if (document.getElementsByClassName('usefull')[0].style.display !== "block") { document.getElementsByClassName('usefull')[0].style.display = "block"; document.getElementsByClassName('usefull')[1].style.display = "block"; document.getElementsByClassName('usefull')[2].style.display = "block"; document.getElementsByClassName('usefull')[3].style.display = "block"; } else { document.getElementsByClassName('usefull')[0].style.display = "none"; document.getElementsByClassName('usefull')[1].style.display = "none"; document.getElementsByClassName('usefull')[2].style.display = "none"; document.getElementsByClassName('usefull')[3].style.display = "none"; }
    }

    return (<>
        {getMenu !== null && <div id='userMenu'>
            {/* --------------התפריט למעלה------------- */}
            {/* <BrowserRouter> */}
            {!isSearch && document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth > 1000 && <div className='SearchOutlined' id='SearchOutlined' onClick={() => { setIsSearch(true); searchData('') }}><SearchOutlined /></div>}
            {isSearch && <div className='SearchRight' onClick={() => { setIsSearch(false); navigate(`/allCards/${resultSearchNow.categoryCode}/${resultSearchNow.categoryName}/${resultSearchNow.menu}`) }}>חפש</div>}


            {isSearch && <div className='mainNav'>
                <IconButton style={{ position: 'absolute', left: '5%', top: '0.5%', position: 'fixed' }} onClick={() => setIsSearch(false)} className='IconButton' size="large">
                    <CloseOutlined />
                </IconButton>
                <input id='search' type='text' placeholder='חפש קטגוריה' autocomplete='off' onChange={(e) => searchData(e.target.value)}></input>
                {resultSearch[0] && <div className='resultSearchRound animate__animated animate__fadeInDown'>{resultSearch.map((x, i) => (
                    <div className='resultSearch' key={i} onClick={() => { document.getElementById('search').value = x.categoryName; setResultSearchNow(x); setResultSearch([]) }}>{x.categoryName}</div>
                ))}</div>}
            </div>}



            {!isSearch && <div className='mainNav' onMouseLeave={() => { setBiUser(false) }}>
                {!isSearch && document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth <= 1000 && <>
                    <div className='SearchOutlined1' id='SearchOutlined' onClick={() => { setIsSearch(true); searchData('') }}><SearchOutlined /></div>
                    <BiUser onMouseEnter={(event) => { setBiUser(true); setCurrent(event.currentTarget) }} className='mainNav-item' style={{ fontWeight: '20%', paddingTop: '3px', width: '25px', position: 'absolute', marginRight: '20%' }}></BiUser>
                </>}


                <Link onAuxClick={(e) => { setManagger(true) }} to="/" className="mainNav-item chooze1 animate__animated animate__rubberBand animate__infinite animate__slower kol89"
                    onClick={(event) => {
                        for (let index = 0; index < document.getElementsByClassName('chooze1').length; index++) {
                            document.getElementsByClassName('chooze1')[index].classList.remove('chooze1')
                        }
                        for (let index = 0; index < document.getElementsByClassName('chooze2').length; index++) {
                            document.getElementsByClassName('chooze2')[index].classList.remove('chooze2')
                        }
                        event.currentTarget.classList.add('chooze1')
                    }}
                    style={{ fontWeight: '400', paddingLeft: '2%', minWidth: 'max-content' }}>
                    קול שמחה</Link>

                {getMenu.map((item, index) => (<span key={index}>
                    {((document.getElementsByClassName("mainNav")[0] && (document.getElementsByClassName("mainNav")[0].offsetWidth > 1300 || document.getElementsByClassName("mainNav")[0].offsetWidth < 1000) && (item.name === 'הצטרפות לעסקים' || item.name === 'ארגונית אישית' || item.name === 'איך זה עובד?')) || (item.name !== 'הצטרפות לעסקים' && item.name !== 'ארגונית אישית' && item.name !== 'איך זה עובד?')) && <Link to={`/${item.name}/${stateNow == getMenu.length - 1 ? user.id : stateNow}`}
                        className="mainNav-item"
                        onClick={() => {
                            setIsHovered(false)
                            choozeMe()
                        }}
                        onMouseEnter={(event) => {
                            setStateNow(index);
                            setBiUser(false);
                            setCurrent(event.currentTarget);
                            now = index; getMenu[now].titles !== null ?
                                setTimeout(() => { setIsHovered(true); }, 2) : setIsHovered(true);
                        }}
                        onMouseLeave={() => setIsHovered(false)} >
                        {item.name}
                        <div className="mainunderline"></div>
                    </Link>}
                    {(document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth < 1300 && (item.name === 'הצטרפות לעסקים' || item.name === 'ארגונית אישית' || item.name === 'איך זה עובד?')) && <Link to={`/${item.name}/${stateNow == getMenu.length - 1 ? user.id : stateNow}`} className="usefull" style={{ top: `${item.name === 'הצטרפות לעסקים' ? '130px' : item.name === 'ארגונית אישית' ? '200px' : '60px'}` }} onClick={() => { clickUseFull() }}>{item.name}<hr></hr></Link>}
                </span>))}

                {document.getElementsByClassName("mainNav")[0] && (document.getElementsByClassName("mainNav")[0].offsetWidth < 1300 && document.getElementsByClassName("mainNav")[0].offsetWidth > 1000) && <span><span className="mainNav-item" onClick={() => { clickUseFull() }}>כלים שימושיים<div className="mainunderline"></div></span>
                    <div className="usefull" style={{ top: '280px', paddingBottom: '20px' }} onClick={() => { setAlert(true) }}>יצירת קשר</div>
                </span>}

                {/* <BiUser onMouseEnter={(event) => { setBiUser(true); setCurrent(event.currentTarget) }} className='mainNav-item' style={{ top: '8px', paddingBottom: '0%', fontWeight: '20%', position: 'absolute' }}></BiUser> */}
                {/* <BiUser onMouseEnter={(event) => { setBiUser(true); setCurrent(event.currentTarget) }} className='mainNav-item' style={{ top: '-4px', fontWeight: '20%', position: 'absolute' }}></BiUser> */}

                {user.id && <span className='mainNav-item notification' onClick={() => navigate(`/Shopping`)} style={{ marginRight: '0%', fontSize: '115%', marginTop: '0' }} onMouseEnter={() => { setBiUser(false) }}>
                    <span><ShoppingCartOutlined /></span>
                    <span className="badge">{shoppingLength}</span>
                </span>}
                {name && <p className="mainNav-item" style={{ marginTop: '0px', marginBottom: '0', paddingBottom: '0', cursor: 'pointer' }} onClick={() => navigate(`/PersonalArea`)}>{name}</p>}

                <div style={{ display: 'inline-block' }} onMouseEnter={() => setBiUser(false)}>{login && <Login setLogin={setLogin}></Login>}</div>
                {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth > 1000 && <div style={{ position: 'absolute', left: '1%', top: '0', height: '100%' }}>
                    <BiUser onMouseEnter={(event) => { setBiUser(true); setCurrent(event.currentTarget) }} className='mainNav-item' style={{ fontWeight: '20%', display: 'inline-block', position: 'absolute', top: '0', paddingTop: '3px', right: '-40px' }}></BiUser>

                    {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth > 1300 && <div style={{ width: '20vh', display: 'inline-block', marginTop: '6px' }} onClick={() => {
                        setAlert(true)
                    }}><ButtonRed text='יצירת קשר'></ButtonRed></div>}
                </div>}

                {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth <= 1000 && <div style={{ top: '0', height: '100%', width: '300px' }}>
                    {/* <BiUser onMouseEnter={(event) => { setBiUser(true); setCurrent(event.currentTarget) }} className='mainNav-item' style={{ fontWeight: '20%', display: 'inline-block', paddingTop: '3px', marginLeft:'20px', width:'25px', marginRight:'10px', float:'right' }}></BiUser> */}

                    <div style={{ width: '120px', display: 'inline-block', marginTop: '6px', marginRight: '20px', float: 'left' }} onClick={() => {
                        setAlert(true)
                    }}><ButtonRed text='יצירת קשר'></ButtonRed></div>
                </div>}

            </div>}

            {biUser && <div className="biUser" onMouseEnter={() => { setBiUser(true) }} onMouseLeave={() => { setBiUser(false) }}>
                <div className="menuButtons" style={{ paddingTop: '20px', paddingLeft: '50px', paddingRight: '50px' }} onClick={() => { setBiUser(false); setLogin(!login); }}>התחבר</div><br></br>
                <div className="menuButtons" style={{ paddingBottom: '20px', paddingLeft: '50px', paddingRight: '50px' }} onClick={() => { setBiUser(false); setLogin(false); setConnection(true) }}>הירשם</div>
            </div>}
            {connection && <Connection set={setConnection}></Connection>}

            {/* --------------התפריט המשני------------- */}

            {isHovered && getMenu[stateNow].titles !== null &&
                <div className="menu animate__animated animate__fadeInDown" style={{ animationDelay: '0s' }}
                    onMouseEnter={() => {
                        setIsHovered(true);
                        current.classList.add('chooze1')
                        current.getElementsByClassName('mainunderline')[0].classList.add('chooze2')
                    }}
                    onMouseLeave={() => {
                        setIsHovered(false);
                        current.classList.remove('chooze1')
                        current.getElementsByClassName('mainunderline')[0].classList.remove('chooze2')
                    }}>
                    <div className='container'>
                        {getMenu[stateNow].titles.map((title, titleIndex) => (
                            <div key={titleIndex} className='title'>{title}
                                <div className={`mainunderline2 ${isHovered ? "visible10" : ""}`}></div>
                            </div>
                        ))}
                    </div>

                    <div className="menu-column container">
                        {getMenu[stateNow].howMany.map((column, columnIndex) => (
                            <div key={columnIndex}>
                                <div style={{ display: 'none' }}>{timeEnd = timeEnd + column}</div>
                                {getMenu[stateNow].categories.map((item, itemIndex) => (
                                    itemIndex >= timeBegin && itemIndex < timeEnd && <div key={itemIndex} className="menu-item">
                                        <div onClick={() => { navigate(`/allCards/${getMenu[stateNow].categoryId[itemIndex]}/${item}/${getMenu[stateNow].name}`); setIsHovered(false); choozeMe() }} className="menuButtons">
                                            {item}
                                            {item !== null && <div style={{ backgroundColor: '#FF7D7D', bottom: '-5%' }} className="mainunderline"></div>}
                                        </div>
                                    </div>
                                ))}
                                <div style={{ display: 'none' }}>{timeBegin = timeBegin + column}</div>
                            </div>
                        ))}
                    </div>
                </div>}


        </div>}

        {managger && <EnterManagger name={'קול שמחה'} errorMessage={errorMessage} set={setManagger} submit={passManaggerSubmit}></EnterManagger>}
        {alert && <Alert set={setAlert} sendGood={sendGood} text={"קול שמחה"} mail={"r0527117663@gmail.com"}></Alert>}
        {wait && <Wait></Wait>}

        {send && <div className="send animate__animated animate__bounceInUp">
            <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send}</div>
        </div>}

        {isBuild && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ position: 'relative', width: '500px', marginRight: 'auto', marginLeft: 'auto' }}>
                    <CloseOutlined className='close2' onClick={() => setIsBuild(false)} style={{ top:'3%', left:'5%' }} />
                    <p className='titl' style={{backgroundColor:'#820909', padding:'5%', marginTop:'0', color:'white', borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>ההמערכת בהקמה</p>
                    <p className='titl' style={{ marginTop: '13%' }}>מזמינים אתכם לבקר שוב <br/>ולראות את מאות העסקים שהצטרפו.<br/><br/> בכל חודש מצטרפים<br/> מאות עסקים חדשים.</p>
                    <div id='submit30' onClick={() => setIsBuild(false)}><ButtonRed text={'אישור'}></ButtonRed></div>
                    <br></br>
                </div>
            </div>
        </div>}
    </>
    );
};

export default Menu;  