const orders = {
    orders: [],
};

const ordersReducer = (state = orders, action) => {
    switch (action.type) {
        case "COMPLETEorders":
            return { ...state, orders: action.payload };
        case "COMPLETEAddorders":
            if (state.orders.length > 0) {
                return { ...state, orders: [...state.orders, action.payload] };}
        default:
            return state;
    }
};



export default ordersReducer;
