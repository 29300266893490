// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backButton {
    cursor: pointer;
    margin-top: -16%;
    font-size: 250%;
    margin-right: 11%;
    z-index: 200;
    -webkit-user-select: none;
            user-select: none;
}

.nextButton {
    cursor: pointer;
    margin-top: -16%;
    font-size: 250%;
    margin-left: 11%;
    z-index: 200;
    -webkit-user-select: none;
            user-select: none;
}

.nextButton:hover,
.backButton:hover {
    color: #C00000;
}

.k1 {
    position: absolute;
    left: 19%;
    width: 0%;
    /* margin-left: 20%; */
    height: 1px;
    background-color: #C00000;
    top: 70%;
    transition: 2s;
}

.k11 {
    width: 61%;
}

.k2 {
    width: 1px;
    margin-right: 81%;
    height: 0px;
    background-color: #C00000;
    margin-top: -1.1%;
}

.k22 {
    transition: 1s;
    height: 430px;
}`, "",{"version":3,"sources":["webpack://./src/components/global/bussiness/Carousel/Carousel.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;;IAEI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,sBAAsB;IACtB,WAAW;IACX,yBAAyB;IACzB,QAAQ;IACR,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,WAAW;IACX,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,aAAa;AACjB","sourcesContent":[".backButton {\r\n    cursor: pointer;\r\n    margin-top: -16%;\r\n    font-size: 250%;\r\n    margin-right: 11%;\r\n    z-index: 200;\r\n    user-select: none;\r\n}\r\n\r\n.nextButton {\r\n    cursor: pointer;\r\n    margin-top: -16%;\r\n    font-size: 250%;\r\n    margin-left: 11%;\r\n    z-index: 200;\r\n    user-select: none;\r\n}\r\n\r\n.nextButton:hover,\r\n.backButton:hover {\r\n    color: #C00000;\r\n}\r\n\r\n.k1 {\r\n    position: absolute;\r\n    left: 19%;\r\n    width: 0%;\r\n    /* margin-left: 20%; */\r\n    height: 1px;\r\n    background-color: #C00000;\r\n    top: 70%;\r\n    transition: 2s;\r\n}\r\n\r\n.k11 {\r\n    width: 61%;\r\n}\r\n\r\n.k2 {\r\n    width: 1px;\r\n    margin-right: 81%;\r\n    height: 0px;\r\n    background-color: #C00000;\r\n    margin-top: -1.1%;\r\n}\r\n\r\n.k22 {\r\n    transition: 1s;\r\n    height: 430px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
