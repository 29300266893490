import { useParams } from 'react-router-dom';
import PicForMenu from '../../global/image/PicForMenu';

const FromWedding = () => {
    const p = useParams()

    return <div>
        <PicForMenu now={p.now}></PicForMenu>
    </div>
}

export default FromWedding