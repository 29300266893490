import BottomMenu from "../../global/bottomMenu/BottomMenu"
import FormNewBusiness from "../../global/forms/FormNewBusiness"
import OnScroll from "../../global/onScroll/OnScroll"
import { useNavigate } from "react-router-dom"
import './AddBusiness.css'
import RefReady from "../../global/ref"
import p1 from "../../../pic/35.jpg"
import p2 from "../../../pic/56.jpg"
import p3 from "../../../pic/animations/128.gif"
import p5 from "../../../pic/animations/45.gif"
import p6 from "../../../pic/animations/46.gif"
import p4 from "../../../pic/47.jpg"
import p7 from "../../../pic/animations/118.gif"
import p8 from "../../../pic/animations/119.gif"
import p9 from "../../../pic/animations/120.gif"
import p10 from "../../../pic/animations/31.gif"
import p11 from "../../../pic/animations/30.gif"
import p13 from "../../../pic/1.png"
import p14 from "../../../pic/2.png"
import p15 from "../../../pic/animations/200.gif"

import picKol from "../../../pic/logo/logo.jpg"

import gift from "../../../pic/animations/17.gif"
import { useEffect, useRef, useState } from "react"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import ButtonRed from "../../global/button/button"
import { useInView } from "react-intersection-observer"


const AddBusiness = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    // ------------------------start ref-------------------------
    const [isVisible, setIsVisible] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: true, // Triggers the animation only once
        threshold: 0.1, // Percentage of visibility needed to trigger the animation
    });

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    // ------------------------end ref-------------------------

    // ------------------------start second ref-------------------------
    const divRef1 = useRef(null);
    const divRef2 = useRef(null);
    const divRef3 = useRef(null);
    const divRef4 = useRef(null);
    const divRef5 = useRef(null);
    const divRef6 = useRef(null);
    const divRef7 = useRef(null);
    const divRef8 = useRef(null);
    const divRef9 = useRef(null);
    const divRef10 = useRef(null);
    const divRef11 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (Array.from(document.getElementsByClassName("animate")).indexOf(entry.target) % 2)
                        entry.target.classList.add('animate__bounceInRight')
                    else
                        entry.target.classList.add('animate__bounceInLeft')
                }
            });
        });
        // if (picRef.current) { observer.observe(picRef.current); }
        if (divRef1.current) { observer.observe(divRef1.current); }
        if (divRef2.current) { observer.observe(divRef2.current); }
        if (divRef3.current) { observer.observe(divRef3.current); }
        if (divRef4.current) { observer.observe(divRef4.current); }
        if (divRef5.current) { observer.observe(divRef5.current); }
        if (divRef6.current) { observer.observe(divRef6.current); }
        if (divRef7.current) { observer.observe(divRef7.current); }
        if (divRef8.current) { observer.observe(divRef8.current); }
        if (divRef9.current) { observer.observe(divRef9.current); }
        if (divRef10.current) { observer.observe(divRef10.current); }
        if (divRef11.current) { observer.observe(divRef11.current); }


        return () => {
            if (divRef1.current) { observer.unobserve(divRef1.current); }
            if (divRef2.current) { observer.unobserve(divRef2.current); }
            if (divRef3.current) { observer.unobserve(divRef3.current); }
            if (divRef4.current) { observer.unobserve(divRef4.current); }
            if (divRef5.current) { observer.unobserve(divRef5.current); }
            if (divRef6.current) { observer.unobserve(divRef6.current); }
            if (divRef7.current) { observer.unobserve(divRef7.current); }
            if (divRef8.current) { observer.unobserve(divRef8.current); }
            if (divRef9.current) { observer.unobserve(divRef9.current); }
            if (divRef10.current) { observer.unobserve(divRef10.current); }
            if (divRef11.current) { observer.unobserve(divRef11.current); }
        };
    }, []);
    // ------------------------end second ref-------------------------


    const n = useNavigate()
    return <div>
        <OnScroll></OnScroll>

        <div className="first1">
            <p style={{ marginTop: '10%', fontSize: '170%' }}>נותן שירות בתחום האירועים?</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            <p className="animate__animated animate__heartBeat animate__delay-1s animate__slower" style={{ marginTop: '5%', fontSize: '220%', fontWeight: '400' }}>יש דרך פשוטה שתטיס את העסק שלך למעלה</p>
            <a href="#come"><div className='buton1' style={{ width: '20%', position: 'absolute', top: '10%', left: '3%', position: "fixed", zIndex: '450' }} onClick={() => { }}><ButtonRed text='הצטרפות'></ButtonRed></div></a>

        </div>
        <div>
            <br></br>
            <div style={{ width: '100%', height: '30vh', marginBottom: '30vh' }}></div>
            <img src={p1} style={{ marginLeft: '5%', marginRight: '-40%', WebkitUserDrag: 'none' }}></img>
            <p style={{ fontSize: '150%', display: 'inline-block' }}>רוצה להזרים כסף חדש לעסק?</p>
            <p style={{ fontSize: '190%', fontWeight: '400', marginTop: '-4%', zIndex: '104' }}>רוצה להכפיל את כמות הלקוחות בקלות?</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '80px', height: '5px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-35px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            <RefReady tag={<img className="animate__animated" src={p4} style={{ marginRight: '10%', marginBottom: '-1vh', WebkitUserDrag: 'none', marginTop: '-2%', zIndex: '1' }}></img>} event={'animate__bounceInRight'}></RefReady>
            <div className="second2" style={{ position: 'relative' }}>
                <div style={{ fontSize: '150%', height: '14vh', zIndex: '50', fontWeight: '400', position: 'absolute', backgroundColor: 'white', top: '40%', width: '100%', lineHeight: '100%', marginTop: '0' }}><RefReady tag={<p className="animate__animated animate__delay-3s animate__slower">קול שמחה - הדרך הקלה להגיע ללקוחות פוטנציאלים שנרשמו במיוחד לאתר וצורכים בדיוק עכשיו את המוצר / השירות שלכם.</p>} event={'animate__fadeInDownBig'}></RefReady></div>
                {/* אנחנו מגיעים ל-1,000 מתחתנים במגזר החרדי בכל חודש */}
                <img src={p2} className="second1"></img>
            </div>
            <p style={{ lineHeight: '20vh', fontSize: '150%', fontWeight: '400', zIndex: '999' }}>רוצה שהיומן יתמלא לך מעצמו - ולא ישארו חורים?</p>
            <RefReady tag={<img className="animate__animated animate__delay-3s" src={p3} style={{ zIndex: '1', animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-3%' }}></img>} event={'animate__bounceInDown'}></RefReady>

            <RefReady tag={<p style={{ marginTop: '10vh', fontSize: '250%', fontWeight: '400' }}>הצטרף למאגר המרכזי  של אלפי נותני השירות בתחום האירועים</p>} event={'animate__bounceInLeft'}></RefReady>
            <RefReady tag={<div className='animate__animated' style={{ width: '100px', height: '6px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-55px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            <p style={{ marginTop: '10vh', fontSize: '150%', fontWeight: '400' }}>ההזמנות יכנסו לך לבד, ותתחיל לספור כסף...</p>
        </div>

        <RefReady tag={<div style={{ marginTop: '10vh', backgroundColor: 'rgb(231, 217, 217)', padding: '5%', color: 'rgb(106, 26, 26)' }}><p style={{ fontSize: '180%', fontWeight: '300' }}>הפרסום כאן לא דומה לשום מקום אחר שהכרתם</p><p style={{ fontSize: '120%', fontWeight: '400' }}>קול שמחה הוא לא עוד פרסום / אתר - אלא כלי שימושי על כל צעד ושעל לכל חתן, כלה ומחותנים</p></div>} event={'animate__fadeInUpBig'}></RefReady>

        {/* -------------------------------------------------------------------------------------------------------------------
                                    יתרונות משתמשים
------------------------------------------------------------------------------------------------------------------- */}
        <p id='get' style={{ fontSize: '150%', fontWeight: '500', paddingTop: '4%', zIndex: '10' }}>למה כל המתחתנים מעדיפים להשתמש ב<img src={picKol} style={{ width: '10%', userSelect: 'none', marginTop: '-2.7%', objectFit: 'cover', transform: 'scale(1.4)', zIndex: '2' }}></img>?</p>
        <div ref={ref} style={{ transition: 'width 0.5s ease-in-out' }} className={`underline3 ${isVisible ? "visible3" : ""} `}></div>

        <div style={{ position: 'relative', marginTop: '3%' }}>
            <div id='long' style={{ position: 'absolute', backgroundColor: '#c00000', width: '5px', height: '100%', right: '50%', top: '0px', zIndex: '0' }}></div>

            <div style={{ fontSize: '110%' }}>
                <div ref={divRef1} className='animate' style={{ backgroundColor: 'black', color: 'white' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextStrokeWidth: '0.7px', WebkitTextStrokeColor: '#c00000' }}>כל המידע על מגש של זהב</p>
                    <p>חסל סדר חיפושים, בירורים וטלפונים.<br /> כל המידע לחתנים וכלות מרוכז ומפורט ביעילות במקום אחד.</p>
                </div>
                <div style={{ position: 'absolute', left: '49%', marginTop: '-10%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>


                <div ref={divRef2} className='animate p1' style={{ marginTop: '-2%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >מחירון מדויק של כל הספקים</p>
                    <p style={{ fontWeight: '400', }}>מחירונים מפורטים בכל תחום כולל תמונות. <br /> לדוגמה: באולמות - מחירון מפורט לפי כמות וסוגי מנות,<br /> צלמים - פירוט חבילות + שעות נוספות. וכדו'</p>
                </div>
                <div className='p1' style={{ position: 'absolute', left: '47%', marginTop: '-5%', border: '#c00000 6px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%', fontWeight: 'bolder' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ paddingLeft: '40%', paddingTop: '30%' }} /></div>


                <div ref={divRef3} className='animate p2' style={{ marginTop: '4%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', color: '#c00000', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >מגוון עצום של נותני שירות</p>
                    <p style={{ fontWeight: '400', }}>אולמות אירועים, צלמים, צלמי וידאו, קייטרינג, תזמורת לחתונה, נדוניה, מצעים, ביגוד לחתן ולכלה, מתנות לחתן ולכלה ועוד... - הכל במקום אחד</p>
                </div>
                <div className='p2' style={{ position: 'absolute', left: '48%', marginTop: '-9.5%', border: 'black 4px solid', width: '90px', height: '90px', borderRadius: '50%', color: 'black', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' style={{ marginLeft: '-42%', paddingTop: '30%' }} /></div>


                <div ref={divRef4} className='animate' style={{ backgroundColor: 'black', color: 'white', marginTop: '1%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextStrokeWidth: '0.7px', WebkitTextStrokeColor: '#c00000' }} >קניות ישירות אונליין מתוך המערכת</p>
                    <p>ניתן בקלות להזמין ולשלם ישיר, בלי לצאת מהבית -<br /> מתוך מבחר העסקים שבחרו באופציה זו.</p>
                </div>
                <div style={{ position: 'absolute', left: '47%', marginTop: '-11%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ marginLeft: '-20%', paddingTop: '10%' }} /></div>


                <div ref={divRef5} className='animate p1' style={{ marginTop: '-2%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >יומן תאריכים פנויים באולמות ועוד</p>
                    <p style={{ fontWeight: '400', }}>יומן חכם שמסונכרן עם בתי העסק: האולמות הזמרים ונותני השירות. <br />ניתן לראות את בעלי המקצוע הפנויים בתאריך שלכם, ולתפוס תאריך פנוי אונליין. <br />חסל סדר בירורים ושאלות "האם אתם פנויים בתאריך..."</p>
                </div>
                <div className='p1' style={{ position: 'absolute', left: '49%', marginTop: '-6%', backgroundColor: 'black', border: '#c00000 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>


                <div ref={divRef6} className='animate p2' style={{ marginTop: '3%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', color: '#c00000', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }}>חוסכים זמן</p>
                    <p style={{ fontWeight: '400', }}>כל המידע הנצרך מונגש בבהירות וחוסך את זמן הבירור</p>
                </div>
                <div className='p2' style={{ position: 'absolute', left: '47%', marginTop: '-6%', border: 'black 4px solid', width: '90px', height: '90px', borderRadius: '50%', color: 'black', fontSize: '200%' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ marginLeft: '-42%', paddingTop: '30%' }} /></div>


                <div ref={divRef7} className='animate' style={{ backgroundColor: 'black', color: 'white' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextStrokeWidth: '0.7px', WebkitTextStrokeColor: '#c00000' }}>חוסכים אנרגיה כח וטרחה</p>
                    <p>כל צרכי החתונה במקום אחד ובלחיצת כפתור, בלי להתרוצץ ולהזיע</p>
                </div>
                <div style={{ position: 'absolute', left: '49%', marginTop: '-10%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>


                <div ref={divRef8} className='animate p1' style={{ marginTop: '-2%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >סדר בראש</p>
                    <p style={{ fontWeight: '400', }}>המשתמשים מקבלים רשימת מטלות מתאימה לצרכים המדויקים שלהם.<br />המכילה את כל המטלות הדרושות לארגון חתונה בצורה הכי טובה שיש.<br />רשימה נפרדת מותאמת לצד החתן / לצד הכלה.</p>
                </div>
                <div className='p1' style={{ position: 'absolute', left: '47%', marginTop: '-5%', border: '#c00000 6px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%', fontWeight: 'bolder' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ paddingLeft: '40%', paddingTop: '30%' }} /></div>


                <div ref={divRef9} className='animate p2' style={{ marginTop: '4%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', color: '#c00000', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >מקבלים תזכורות</p>
                    <p style={{ fontWeight: '400', }}>המתחתנים מקבלים למייל תזכורות בזמן אמת לפני החתונה <br />על כל שלב שצריך להתכונן.</p>
                </div>
                <div className='p2' style={{ position: 'absolute', left: '48%', marginTop: '-9.5%', border: 'black 4px solid', width: '90px', height: '90px', borderRadius: '50%', color: 'black', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' style={{ marginLeft: '-42%', paddingTop: '30%' }} /></div>


                <div ref={divRef10} className='animate' style={{ backgroundColor: 'black', color: 'white', marginTop: '1%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', position: 'relative', WebkitTextFillColor: 'white', WebkitTextStrokeWidth: '0.5px', WebkitTextStrokeColor: '#c00000' }} >פירסום ומידע על מאורסים חינם</p>
                    <p>פירסום מודעות מאורסים חינם.<br /> אפשרות להירשם לקבלת רשימת מאורסים בקביעות למייל.</p>
                </div>
                <div style={{ position: 'absolute', left: '47%', marginTop: '-11%', backgroundColor: 'black', border: '#FF7D7D 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#FF7D7D', fontSize: '200%' }}><LeftOutlined className='animate__animated animate__fadeOutLeft animate__infinite animate__delay-3s' style={{ marginLeft: '-20%', paddingTop: '10%' }} /></div>


                <div ref={divRef11} className='animate p1' style={{ marginTop: '-2%' }}>
                    <p style={{ fontSize: '150%', fontWeight: '400', color: '#FF7D7D', WebkitTextStrokeWidth: '0.8px', WebkitTextStrokeColor: 'black' }} >מקבלים כלים רוחניים להגיע לשמחה האמיתית "כמשוש חתן על כלה"</p>
                    <p style={{ fontWeight: '400', }}>שמות ספרים ומאמרים מומלצים לקריאה לחתן, לכלה ולמחותנים.<br />כולל מידע היכן ניתן להשיג כל ספר.</p>
                </div>
                <div className='p1' style={{ position: 'absolute', left: '49%', marginTop: '-6%', backgroundColor: 'black', border: '#c00000 7px solid', width: '90px', height: '90px', borderRadius: '50%', color: '#c00000', fontSize: '200%' }}><RightOutlined className='animate__animated animate__fadeOutRight animate__infinite animate__delay-3s' /></div>

            </div>

        </div>

        {/* -------------------------------------------------------------------------------------------------------------------
                                    יתרונות עסקים
------------------------------------------------------------------------------------------------------------------- */}
        <p id='get' style={{ fontSize: '150%', fontWeight: '500', paddingTop: '4%', zIndex: '10' }}>למה שווה לבעלי עסקים להשתמש דווקא ב<img src={picKol} style={{ width: '10%', userSelect: 'none', marginTop: '-2.7%', objectFit: 'cover', transform: 'scale(1.4)', zIndex: '2' }}></img>?</p>
        <div ref={ref} style={{ transition: 'width 0.5s ease-in-out' }} className={`underline3 ${isVisible ? "visible3" : ""} `}></div>

        <div style={{ userSelect: 'none' }}>
            <RefReady tag={<div style={{ marginTop: '7vh', backgroundColor: 'rgb(241, 233, 233)', padding: '3%', color: 'rgb(106, 26, 26)' }}><p style={{ fontSize: '180%', fontWeight: '300' }}>חשיפה עצומה לעסק שלכם</p><div class="zoom"><img className="animate__animated animate__delay-3s" src={p5} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-30%', width: '30%', height: 'auto', display: 'inline-block', paddingLeft: '4%' }}></img><p style={{ width: '70%', marginTop: '-4%', display: 'inline-block' }}>המיזם מתפרסם במקומונים, עיתונים ארציים, מדיה, רשימות תפוצה, רשימות ומאגרים של חתנים וכלות.</p></div><div class="zoom"><img className="animate__animated animate__delay-3s" src={p6} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-20%', width: '30%', height: 'auto', display: 'inline-block', paddingLeft: '4%' }}></img><p style={{ width: '70%', marginTop: '-1%', display: 'inline-block' }}>מגיע לכל המתחתנים - <br />דפי מידע מחולקים בסמינרים וישיבות בכל הארץ.</p></div><p style={{ fontSize: '120%', fontWeight: '400' }}>קול שמחה - הדרך הקלה להגיע ללקוחות פוטנציאלים שנרשמו במיוחד לאתר וצורכים בדיוק עכשיו את המוצר / השירות שלכם.</p></div>} event={'animate__bounceInDown'}></RefReady>

            <RefReady tag={<div style={{ marginTop: '0', color: 'rgb(106, 26, 26)' }} className="oneTWOtree"><div style={{ width: '70%', marginTop: '3%', marginRight: '15%' }}><img className="animate__animated animate__delay-3s" src={p7} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-12%', width: '17%', height: 'auto', display: 'inline-block', paddingLeft: '1%' }}></img><div style={{ width: '80%', display: 'inline-block', textAlign: 'right' }}><p style={{ fontSize: '120%', fontWeight: '400' }}>נגיש לכל המגזרים</p><p>המידע נגיש לכלל הציבור החרדי, ע"י קו טלפון שמספרו - <b>02-3137663</b> (נסו להתקשר...)<br /> הקו מכיל לפי שלוחות את רשימת בעלי העסקים, וכל המידע הדרוש למתחתנים. <br /> <span style={{ fontWeight: '400' }}>ניתן בלחיצה בטלפון להזמין ולקבל את המידע לפקס או לכל מייל שיוזמן.</span></p></div></div></div>} event={'animate__fadeInLeft'}></RefReady>
            <RefReady tag={<div style={{ marginTop: '-3%', color: 'rgb(106, 26, 26)' }} className="oneTWOtree"><div style={{ width: '70%', marginTop: '0%', marginRight: '30%' }}><img className="animate__animated animate__delay-3s" src={p8} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-12%', width: '17%', height: 'auto', display: 'inline-block', paddingLeft: '1%' }}></img><div style={{ width: '80%', display: 'inline-block', textAlign: 'right' }}><p style={{ fontSize: '120%', fontWeight: '400' }}>נוחות השימוש</p><p>האתר נבנה עם מחשבה רבה על נגישות וקלות השימוש לעסקים ולמשתמשים,<br /> <span style={{ fontWeight: '400' }}>וזה מה שהופך את קול שמחה לכלי צובר תאוצה -<br /> שכל מי שנחשף אליו נשאר מחובר, משתמש בו, וממליץ הלאה. </span></p></div></div></div>} event={'animate__fadeInRight'}></RefReady>
            <RefReady tag={<div style={{ marginTop: '-3%', color: 'rgb(106, 26, 26)' }} className="oneTWOtree"><div style={{ width: '70%', marginTop: '0%', marginRight: '15%', marginBottom: '2%' }}><img className="animate__animated animate__delay-3s" src={p9} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-6%', width: '17%', height: 'auto', display: 'inline-block', paddingLeft: '1%' }}></img><div style={{ width: '80%', display: 'inline-block', textAlign: 'right' }}><p style={{ fontSize: '120%', fontWeight: '400' }}>מנגנון חיפוש משוכלל</p><p>ניתן לחפש לפי מילות מפתח את המוצרים / השירות שאתם מספקים.</p></div></div></div>} event={'animate__fadeInLeft'}></RefReady>

            <RefReady tag={<div style={{ marginTop: '0', backgroundColor: 'black', padding: '3%', color: '#f9b2b2' }}><p style={{ fontSize: '180%', fontWeight: '300' }}>האתר עובד בשבילכם</p><div class="zoom" style={{ borderColor: '#f9b2b2',width:'40%' }}><img className="animate__animated animate__delay-3s" src={p10} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-15%', width: '30%', height: 'auto', display: 'inline-block', paddingLeft: '4%' }}></img><p style={{ width: '70%', marginTop: '-1%', display: 'inline-block' }}>הירשמו וקבלו עמוד עסקי שישווק את העסק שלכם ביעילות. <br /> האתר מובנה ומותאם לכל סוג של שירות.</p></div><div class="zoom" style={{ borderColor: '#f9b2b2', width: '40%', paddingTop: '32px' }}><img className="animate__animated animate__delay-3s" src={p11} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-15%', width: '30%', height: 'auto', display: 'inline-block', paddingLeft: '4%' }}></img><p style={{ width: '70%', marginTop: '0', display: 'inline-block', fontSize: '90%', paddingTop: '0' }}>אתם מזינים פעם אחת ובקלות - בתבנית מובנית,<br />את המידע המדויק שאתם מעוניינים להציג דווקא בתחום שלכם,<br /> <b>והאתר מתחיל לעבוד בשבילכם</b> </p></div><p style={{  fontSize: '140%', fontWeight: '400', letterSpacing: '1.2px', marginTop:'4%' }}>עסק חכם מצטרף לקול שמחה - משלם קצת, עובד קצת ומרוויח הרבה.</p></div>} event={'animate__fadeInDown'}></RefReady>

            <RefReady tag={<div style={{ marginTop: '0', backgroundColor: 'rgb(241, 233, 233)', padding: '3%', color: 'rgb(106, 26, 26)' }}><div><p style={{ fontSize: '180%', fontWeight: '300' }}>ניהול יומן - מרוויחים זמן וכסף</p><p className="inNiulYoman">תקבלו יומן אישי<br /> בו העסק יוכל לסמן<br />  את התאריכים התפוסים / הפנויים.<br />.</p><p className="inNiulYoman">חיסכו זמן, טלפונים ופניות מיותרות,<br /> לא תצטרכו לשאול בכל שיחה<br /> "מתי תאריך החתונה שלכם?"<br /> כי יפנו אליכם רק על התאריכים הפנויים.</p><p className="inNiulYoman"><b>והכי מדהים - </b><br />המערכת עובדת בשבילכם לבד.<br /> הלקוחות קובעים תאריכים פנויים <br />כשאתם בכלל לא מול היומן.</p></div><p style={{ fontSize: '120%', fontWeight: '400', marginTop:'0%', backgroundColor:'white', border: '#c00000 1px solid', width:'max-content', padding:'2%', marginRight:'auto', marginLeft:'auto' }}>עסק חכם מצטרף לקול שמחה - מכפיל מכירות, סוגר עסקאות, בכל השעות.</p></div>} event={'animate__bounceInDown'}></RefReady>

            <RefReady tag={<div style={{ marginTop: '0', backgroundColor: 'white', padding: '3%', color: 'rgb(106, 26, 26)', paddingBottom:'6%' }}><p style={{ fontSize: '180%', fontWeight: '300' }}>אתר מכירות / קניות ישירות אונליין מתוך המערכת</p><div class="zoom"><img className="animate__animated animate__delay-3s" src={p13} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-20%', width: '30%', height: 'auto', display: 'inline-block', paddingLeft: '4%' }}></img><p style={{ width: '70%', marginTop: '0%', display: 'inline-block' }}>בלחיצת כפתור תוכלו להעלות תמונות, מחירים, חבילות לחתנים וכלות.</p></div><div class="zoom"><img className="animate__animated animate__delay-3s" src={p14} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-20%', width: '30%', height: 'auto', display: 'inline-block', paddingLeft: '4%' }}></img><p style={{ width: '70%', marginTop: '-2%', display: 'inline-block', fontSize: '115%' }}>מכירות ישירות ללקוח <br />כולל חיבור למסוף סליקה.</p></div></div>} event={'animate__fadeInDown'}></RefReady>

            <RefReady tag={<div style={{ marginTop: '0', backgroundColor: 'black', padding: '3%', color: '#f9b2b2' }}><p style={{ fontSize: '180%', fontWeight: '300' }}>יודעים על מה משלמים</p>
            <div class="zoom" style={{ borderColor: '#f9b2b2', width:'70%', padding:'2%' }}><img className="animate__animated animate__delay-3s" src={p10} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-6%', width: '15%', height: 'auto', display: 'inline-block', paddingLeft: '4%' }}></img><p style={{ width: '70%', marginTop: '1%', display: 'inline-block', textAlign:'right' }}><b>מערכת ניהול וסטטיסטיקה</b><br/>תוכלו לדעת בסוף כל חודש, כמה לקוחות פנו אליכם במייל וכמה בטלפון,<br /> כמה חתנים וכלות צפו בדף שלכם באתר / קיבלו עליכם מידע מהמערכת הטלפונית.</p></div>
            <div class="zoom" style={{ borderColor: '#f9b2b2', width:'75%', padding:'1%', marginTop:'2%', paddingLeft:'0', paddingRight:'2%' }}><img className="animate__animated animate__delay-3s" src={p15} style={{ animationDuration: '2s', WebkitUserDrag: 'none', marginTop: '-8%', width: '15%', height: 'auto', display: 'inline-block', paddingLeft: '3%' }}></img><p style={{ width: '85%', marginTop: '1%', display: 'inline-block', textAlign:'right' }}><b>תתחילו לקבל המלצות על העסק</b><br/>כל לקוח שנרשם לקול שמחה מתחייב לתת משוב על חווית השירות של כל אחד מנותני השירות שהשתמש בו.<br /> בתום התקופה, הלקוח מקבל אוטומטית טופס משוב לדרג ולהמליץ,<br />וכך תקבלו המלצות אמיתיות ובשפע, שיגדילו לכם את המכירות.</p></div>
            <p style={{ fontSize: '120%', fontWeight: '400', marginTop:'3%', letterSpacing: '1.5px' }}>קול שמחה - כך אתם מתייעלים. חוסכים אנרגיה, זמן וכסף, חוסכים במזכירות, חוסכים בטרטורי טלפונים.</p></div>} event={'animate__fadeInDown'}></RefReady>
            
        </div>
















        <p id="come" style={{ fontSize: '170%', fontWeight: '500', paddingTop: '5%', width: '30%', marginRight: '35%' }}>בחר חבילה</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-40px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        <table style={{ width: '85%', marginRight: 'auto', marginLeft: 'auto', marginTop: '3%' }}>
            <thead>
                <tr>
                    <th style={{ width: '34%', textAlign: 'center', height: '10vh' }}></th>
                    <th style={{ width: '22%', textAlign: 'center', height: '10vh' }}>חבילת קול ששון</th>
                    <th style={{ width: '22%', textAlign: 'center', height: '10vh' }}>חבילת קול שמחה</th>
                    <th style={{ width: '22%', textAlign: 'center', height: '10vh' }}>חבילת קול מצהלות</th>
                </tr>
            </thead>
            <tbody>
                <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td style={{ textAlign: 'center', height: '10vh' }}>פירסום ברשימת נותני השירות</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                </tr>
                <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td style={{ textAlign: 'center', height: '10vh' }}>עמוד שיווק - מכיל הסבר, תמונות ופרטים על העסק</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                </tr>
                {/* <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        <td style={{ textAlign: 'center', height: '10vh' }}>אינפורמציה על כמות הפניות לעסק בערוצים השונים</td>
                        <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                        <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                        <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                    </tr> */}
                <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td style={{ textAlign: 'center', height: '10vh' }}>הצטרפות למערכת הטלפונית</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>-</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                </tr>
                <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td style={{ textAlign: 'center', height: '10vh' }}>בנית מערכת להזמנות דרך האתר- התחילו להכניס מוצרים...</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>-</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>-</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>✔</td>
                </tr>
                <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid', backgroundColor: 'rgb(245, 238, 238)' }}>
                    <td style={{ textAlign: 'center', height: '10vh' }}><b>קבלו מתנה</b><br></br>ניהול יומן חדשני - הלקוחות יסמנו את הימים המלאים</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}><img src={gift} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img></td>
                    <td style={{ textAlign: 'center', height: '10vh' }}><img src={gift} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img></td>
                    <td style={{ textAlign: 'center', height: '10vh' }}><img src={gift} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img></td>
                </tr>
                <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td style={{ textAlign: 'center', height: '10vh' }}>מחיר חודשי</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>250 ש"ח</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>320 ש"ח</td>
                    <td style={{ textAlign: 'center', height: '10vh' }}>820 ש"ח</td>
                </tr>
                <tr className="container58">
                    <td id="free1" style={{ textAlign: 'center', height: '17vh', fontWeight: '400', borderTop: '#c00000 1px solid', lineHeight: '13vh', backgroundColor: 'rgb(245, 238, 238)', fontSize: '170%', display: 'inline-block' }}>כל נותני השירות מצטרפים עכשיו לחודשיים <b style={{ color: '#c00000' }}>חינם</b> - ללא התחייבות</td>
                    <td id="free2" style={{ backgroundColor: 'white', borderTop: '#c00000 1px solid', fontWeight: '400', paddingTop: '10%', textAlign: 'center' }}>הצטרפו לחבילת קול מצהלות<br /> ב-150 ש"ח <b style={{ color: '#c00000' }}>הנחה</b></td>
                </tr>
                <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td style={{ textAlign: 'center', height: '13vh' }}></td>
                    <td style={{ textAlign: 'center', height: '13vh' }}><div className='buton1' style={{ width: '100%' }} onClick={() => { window.open(`/AddToDataBase/${false}/-1`) }}><ButtonRed text='הצטרפות'></ButtonRed></div><span style={{ fontSize: '90%' }}>לחבילת קול ששון</span></td>
                    <td style={{ textAlign: 'center', height: '13vh' }}><div className='buton1' style={{ width: '100%' }} onClick={() => { window.open(`/AddToDataBase/${false}/-2`) }}><ButtonRed text='הצטרפות'></ButtonRed></div><span style={{ fontSize: '90%' }}>לחבילת קול שמחה</span></td>
                    <td style={{ textAlign: 'center', height: '13vh' }}><div className='buton1' style={{ width: '100%' }} onClick={() => { window.open(`/AddToDataBase/${false}/-3`) }}><ButtonRed text='הצטרפות'></ButtonRed></div><span style={{ fontSize: '90%' }}>לחבילת קול מצהלות</span></td>
                </tr>
            </tbody>
        </table>



        <FormNewBusiness></FormNewBusiness>

        {/* <div style={{width:'500px', height:'100px', backgroundColor:'black', color:'white', marginRight:'auto', marginLeft:'auto'}} onClick={()=>n('/AddToDataBase')}>הצטרפו עכשיו לכל ספקי האירועים</div> */}
        <BottomMenu></BottomMenu>
    </div>
}

export default AddBusiness