import { Link, useParams } from "react-router-dom";
import Categories from "./Categories";
import { useEffect, useState } from "react";
import BottomMenu from "../global/bottomMenu/BottomMenu";
import OptionsManagger from "./OptionsManagger";
import TasksManag from "./organizer/TasksManag";
import KavInPhone from "./KavInPhone";
import pic2 from '../../pic/logo/444.jpg'
import ButtonRed from "../global/button/button";
import pic3 from '../../pic/logo/11.jpg'
import Statistics from "./Statistics";
import Organizer from "./organizer/Organizer";

const MenuManagger = () => {

    const p = useParams()

    useEffect(() => {
        if (document.getElementById('userMenu'))
            document.getElementById('userMenu').style.display = 'none'
    }, [])

    const [current, setCurrent] = useState(0);

    const choozeMe = () => {
        for (let index = 0; index < document.getElementsByClassName('chooze1').length; index++) {
            document.getElementsByClassName('chooze1')[index].classList.remove('chooze1')
        }
        for (let index = 0; index < document.getElementsByClassName('chooze2').length; index++) {
            document.getElementsByClassName('chooze2')[index].classList.remove('chooze2')
        }
        current.classList.add('chooze1')
        current.getElementsByClassName('mainunderline')[0].classList.add('chooze2')
    }

    const [nav, setNav] = useState(-1)

    return <div>
        <div className='mainNav'>
            <Link to="/" className="mainNav-item chooze1 animate__animated animate__rubberBand animate__infinite animate__slower"
                onClick={(event) => {
                    for (let index = 0; index < document.getElementsByClassName('chooze1').length; index++) {
                        document.getElementsByClassName('chooze1')[index].classList.remove('chooze1')
                    }
                    for (let index = 0; index < document.getElementsByClassName('chooze2').length; index++) {
                        document.getElementsByClassName('chooze2')[index].classList.remove('chooze2')
                    }
                    if (document.getElementById('userMenu'))
                        document.getElementById('userMenu').style.display = 'block'
                    event.currentTarget.classList.add('chooze1')
                }}
                style={{ fontWeight: '400', paddingLeft: '5%' }}>
                קול שמחה</Link>

            <div className="mainNav-item" onClick={() => { choozeMe(); setNav(1) }} onMouseEnter={(event) => { setCurrent(event.currentTarget); }}>
                רשימת הקטגוריות
                <div className="mainunderline"></div>
            </div>

            <div className="mainNav-item" onClick={() => { choozeMe(); setNav(2) }} onMouseEnter={(event) => { setCurrent(event.currentTarget); }}>
                חוות דעת על עסקים
                <div className="mainunderline"></div>
            </div>

            <div className="mainNav-item" onClick={() => { choozeMe(); setNav(3) }} onMouseEnter={(event) => { setCurrent(event.currentTarget); }}>
                ארגונית אישית
                <div className="mainunderline"></div>
            </div>

            {/* <div className="mainNav-item" onClick={() => { choozeMe(); setNav(4) }} onMouseEnter={(event) => { setCurrent(event.currentTarget); }}>
                log
                <div className="mainunderline"></div>
            </div> */}

            <div className="mainNav-item" onClick={() => { choozeMe(); setNav(5) }} onMouseEnter={(event) => { setCurrent(event.currentTarget); }}>
                ניהול המערכת הטלפונית
                <div className="mainunderline"></div>
            </div>
            
            <div className="mainNav-item" onClick={() => { choozeMe(); setNav(6) }} onMouseEnter={(event) => { setCurrent(event.currentTarget); }}>
                סטטיסטיקה
                <div className="mainunderline"></div>
            </div>

        </div>

        {nav === -1 && <>
            <img style={{ position: 'absolute', top: '0', right: '0', width: '100%', zIndex: '800' }} src={pic2}></img>
            <div id='modal2'>
                <div className='animate__animated animate__zoomIn'>
                    <div className='glow' style={{width:'30%'}}>
                        <p className='titl' style={{backgroundColor:'#820909', color:'white', marginTop:'0', paddingTop:'6%', paddingBottom:'6%', borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>כניסה זו מיועדת לבעל העסק קול שמחה</p>
                        <p className='titl' style={{marginTop:'20%'}}>מהו קוד העסק?</p>
                        <input className='input20' id='passManag' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder="סיסמה" type="password" />
                        <div id='submit30' onClick={() => { if(Number(document.getElementById('passManag').value) === p.p*30) {setNav(0)}}}><ButtonRed text={'אישור'}></ButtonRed></div>
                        {/* {prop.errorMessage && <p>{prop.errorMessage}</p>} */}
                    </div>
                </div>
            </div>
        </>}

        {nav === 0 && <img style={{ marginTop: '7%', width: '50%' }} src={pic3}></img>}


        {nav === 1 && <Categories></Categories>}
        {nav === 2 && <OptionsManagger></OptionsManagger>}
        {nav === 3 && <Organizer></Organizer>}
        {/* {nav === 4 && <LogViewer></LogViewer>} */}
        {nav === 5 && <KavInPhone></KavInPhone>}
        {nav === 6 && <Statistics></Statistics>}





        <BottomMenu></BottomMenu>
    </div>
};

export default MenuManagger