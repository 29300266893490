const pays = [];

const paysReducer = (state = pays, action) => {
    switch (action.type) {
        case "COMPLETEpays":
            return action.payload;
        case "COMPLETEaddpays":
            return [...state, action.payload];
        default:
            return state;
    }
};



export default paysReducer;
