
const thisBussiness = {

  bussinessData: {}
};

const thisBussinessReducer = (state = thisBussiness, action) => {
  switch (action.type) {
    case "COMPLETET":
      return action.payload;
    case "COMPLETETbussinessData":
      return { ...state, bussinessData: action.payload };
    case "Options":
      return state.options = action.payload;
    case "COMPLETEthisBussinessWithoutBussinessData":
      return {...action.payload, bussinessData: state.bussinessData}
    default:
      return state;
  }
};



export default thisBussinessReducer;

