import ImageStyle from './Image';
import ppic from '../../../pic/flowers.jpg'
import { createRef, useEffect, useRef, useState } from 'react';
import BottomMenu from '../bottomMenu/BottomMenu';
import { useSelector } from 'react-redux';

const PicForMenu = (props) => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const s = useSelector((state) => state.menuReducer)[props.now]

    // ----------------------start ref--------------------------
    const lineRefs = useRef([]);
    lineRefs.current = s.titles.map((_, i) => lineRefs.current[i]?.current ?? createRef());

    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible3')
                }
            });
        });

        for (let index = 0; index < s.titles.length; index++) {
            if (lineRefs.current[index]) { observer.observe(lineRefs.current[index].current) }
        }

        return () => {
            for (let index = 0; index < s.titles.length; index++) {
                if (lineRefs.current[index] && lineRefs.current[index].current) {
                    observer.unobserve(lineRefs.current[index].current)
                }
            }
        };
    }, []);
    // ----------------------end ref--------------------------


    var timeBegin = 0, timeEnd = 0;

    return <div>

        <p style={{ fontSize: '90%', fontWeight: '300', marginTop: '0', color: '#c00000', float: 'right', marginRight: '12.1%', cursor:'pointer' }}>{s.name} &#8592;</p>
        {s.titles.length > 1 && <div style={{textAlign:'center', width:'98%', marginRight:'1%', position:'fixed', backgroundColor:'white', zIndex:'400'}}>{s.titles.map((item, i) => (
            <a key={i} className='buttonKishur' href={`#tit${i}`} style={{ cursor:'pointer', border:'#c00000 1px solid', display:'inline-block', width:`${100/s.titles.length-1}%`, margin:'0.5%', marginTop:'60px', padding:'5px', textDecoration:'none'}}>{item}</a>
        ))}</div>}

        {s.titles.map((item, i) => (
            <div key={i}>
                <div style={{ display: 'none' }}>{timeEnd = timeEnd + s.howMany[i]}</div>
                <p className='title789' id={`tit${i}`} style={{ fontSize: '150%', fontWeight: '500' }}>{item}</p>
                <div ref={lineRefs.current[i]} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3'></div>
                {s.categories.map((x, index) => (
                    index >= timeBegin && index < timeEnd && <ImageStyle key={index} text={x} pic={s.categoryPic[index] !== null ? `data:image/jpeg;base64,${s.categoryPic[index]}`: `${ppic}`} categoryId={s.categoryId[index]} categoryName={s.name}></ImageStyle>
                ))}
                <div style={{ display: 'none' }}>{timeBegin = timeBegin + s.howMany[i]}</div>
            </div>
        ))}

        <div style={{ width: '100%', height: '5%', marginTop: '15%' }}></div>

        <BottomMenu></BottomMenu>
    </div>
}

export default PicForMenu