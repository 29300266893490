import { CloseOutlined } from '@ant-design/icons';
import './TaskList.css';
import { useState } from 'react';
import ButtonRed from '../../global/button/button';
import { AddPersonalTask, ChangeReminders } from '../../axios/api';
import { useDispatch, useSelector } from 'react-redux';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { CompleteUser } from '../../redux/actions/userActions';
import picError from '../../../pic/animations/113.gif'

const AlertAddPersonalTask = (prop) => {

    const s = useSelector(a => a.userReducer)
    const [personalTask, setPersonalTask] = useState({ name: "", explanation: "", erusinOrWedding: "", date: '' })
    const [errorMessage, setErrorMessage] = useState('');
    const d = useDispatch()

    const dinamicWidth = (num) => {
        const input = document.getElementsByClassName("inp")[num];
        input.style.width = '28%'
        setTimeout(() => {
            input.style.width = "auto"; // Reset to auto width to measure the content
            input.style.width = (input.scrollWidth + 5) + "px"; // Add some padding or margin if needed
        }, 1000);
    }

    const submit = () => {
        if (personalTask.name == '' || personalTask.explanation == '' || personalTask.date == '')
            setErrorMessage('כל השדות הן חובה');
        else {
            if (s.reminders == 0 && document.getElementById('checkboxx').checked == 1) {
                ChangeReminders(s.id, 1).then((data) => { d(CompleteUser({ ...s, reminders: 1 })) }).catch((error) => { console.error(error); });
            }
            var task = { name: personalTask.name, date: '', explanation: personalTask.explanation, erusinOrWedding: "", userId: s.id }
            const options = document.getElementsByName("radio")
            if (options[0].checked)
                task.erusinOrWedding = 'אירוסין'
            else if (options[1].checked)
                task.erusinOrWedding = 'חתונה'

            task.date = Math.ceil((new Date(s.weddingDate) - new Date(personalTask.date)) / (24 * 60 * 60 * 1000))

            AddPersonalTask(s.erusinDate, s.weddingDate, task).then((data) => {
                prop.setAllTasks(data)
            }).catch((error) => { console.error(error); });
            prop.set(false)
        }
    }

    return <div id='modal2'>
        <div className='animate__animated animate__zoomIn form78'>
            <CloseOutlined className='close2' onClick={() => prop.set(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />

            <div className='glow glow78' style={{ backgroundColor: 'white', width: '80%', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%' }}>
                <div className='titl' style={{ marginTop: '3%', fontSize: '120%', marginBottom: '5%' }}>הוספת מטלה אישית לרשימת המטלות</div>

                <input className='inp tit78' placeholder="נושא המטלה" onClick={(e) => e.target.style.width = '80%'} onBlur={() => dinamicWidth(0)} type="text" value={personalTask.name} onChange={(e) => {if (e.target.value.length <= 50) {setPersonalTask({ ...personalTask, name: e.target.value })}}} />
                <input className='inp' placeholder='פירוט' onClick={(e) => e.target.style.width = '80%'} onBlur={() => dinamicWidth(1)} type="text" value={personalTask.explanation} onChange={(e) => {if (e.target.value.length <= 800) {setPersonalTask({ ...personalTask, explanation: e.target.value })}}} />
                <div className='inp' style={{ width: '70%', marginTop: '2%' }} >המטלה היא: <label className="checkbox-container0"><input type="radio" name='radio' className="checkbox0" /><span className="checkbox-custom0">&#10003;</span></label> לקראת האירוסין <label className="checkbox-container0"><input type="radio" name='radio' className="checkbox00" defaultChecked /><span className="checkbox-custom0">&#10003;</span></label> לקראת החתונה</div>
                {prop.reminder === 1 && <label style={{ width: '70%', marginTop: '2%', marginBottom:'2%' }} className='inp'>תאריך התזכורת: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input style={{ width: '40%', display: 'inline-block', border: 'transparent', outline: 'none', marginBottom: '1px' }} autoComplete="on" type="date" value={personalTask.date} onChange={(e) => setPersonalTask({ ...personalTask, date: e.target.value })} /></label>}
                {!prop.reminder && <div>
                    <label style={{ width: '70%', marginTop: '2%' }} className='inp'>תאריך לביצוע המטלה: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input style={{ width: '35%', display: 'inline-block', border: 'transparent', outline: 'none', marginBottom: '1px' }} autoComplete="on" type="date" value={personalTask.date} onChange={(e) => setPersonalTask({ ...personalTask, date: e.target.value })} /></label>
                    <p>מכיון שלא אישרתם קבלת תזכורות לא תקבלו תזכורת</p>
                    <div style={{ position: 'absolute', right: '32%', width: '50%', marginTop: '-1%' }}><label className="checkbox-container"><input type="checkbox" className="checkbox1" id='checkboxx' /><span className="checkbox-custom">&#10003;</span> &nbsp; הננו מאשרים קבלת תזכורות למייל.</label></div><br></br>
                </div>}
                <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '2%' }} onClick={() => submit()}><ButtonRed text='הוסף'></ButtonRed></div>
                {errorMessage && <p><img src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}

            </div>
        </div>
    </div>
};

export default AlertAddPersonalTask
