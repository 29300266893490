import { Fragment, useEffect, useState } from "react";
import { AddTaskManagger, DeleteTaskManagger, EditTaskManagger, GetFinallTasks } from "../../axios/api";
import { IconButton } from "@mui/material";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import ButtonRed from "../../global/button/button";
import RefReady from "../../global/ref";
import Search from "../Search";
import Wait from "../../global/Wait";
import { useDispatch, useSelector } from "react-redux";
import { COMmanaggerTASKS } from "../../redux/actions/managgerActions";

const TasksManag = () => {

    const [allTasks, setAllTasks] = useState([]);
    const [tempAllTasks, setTempAllTasks] = useState([]);
    const [openErusin, setOpenErusin] = useState(false)
    const [openWedding, setOpenWedding] = useState(false)
    const [taskAddErusin, setTaskAddErusin] = useState(false)
    const [taskAddWedding, setTaskAddWedding] = useState(false)


    const [wait, setWait] = useState(true);
    const [edit, setEdit] = useState(false);
    const [taskEdit, setTaskEdit] = useState({ id: '', name: '', date: '', date2: '', explanation: '', button: '', erusinOrWedding: '', paysId: '', isDid: '', isPersonal: '', categoryCode: '', isChatanOrKalaOr2: '', tasks: [] });
    const d = useDispatch()
    const tasksSelector = useSelector(a => a.managgerReducer.tasks)

    useEffect(() => {
        if (tasksSelector.length > 0) {
            setAllTasks(tasksSelector)
            setTempAllTasks(tasksSelector)
            setWait(false)
        }
        else {
            GetFinallTasks(0).then((data) => {
                setAllTasks(data)
                setTempAllTasks(data)
                setWait(false)
                d(COMmanaggerTASKS(data))
            }).catch((error) => { console.error(error); });
        }
    }, []);


    const funcSearch = (inp) => {
        setAllTasks(tempAllTasks.filter(a => a.name && a.name.includes(inp) || a.explanation && a.explanation.includes(inp) || a.button && a.button.includes(inp)))
        window.scrollTo(0, 0);
    }

    const table = (tassksForTable, whichTable) => {
        return <div>
            {tassksForTable && <><table style={{ marginTop: '3%', width: '90%', marginRight: '5%', textAlign: 'center' }} className="animate__animated animate__bounceInUp">
                <thead>
                    <tr>
                        <th style={{ fontWeight: '400', width: `${tassksForTable[0].erusinOrWedding === "חתונה" ? '15%' : '20%'}`, textAlign: 'center' }}>שם המטלה</th>
                        <th style={{ fontWeight: '400', maxWidth: '20px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: `${tassksForTable[0].erusinOrWedding === "חתונה" ? '20%' : '25%'}`, textAlign: 'center' }}>תיאור</th>
                        <th style={{ fontWeight: '400', width: '14%', textAlign: 'center' }}>מה כתוב בכפתור</th>
                        <th style={{ fontWeight: '400', width: '14%', textAlign: 'center' }}>לאיזה קטגוריה שייך</th>
                        <th style={{ fontWeight: '400', width: '14%', textAlign: 'center' }}>לאיזה צד שייכת המטלה</th>
                        {tassksForTable[0].erusinOrWedding === "חתונה" && <th style={{ fontWeight: '400', width: '6%', textAlign: 'center', color: 'orange' }}>כמה ימים אחרי האירוסין</th>}
                        {tassksForTable[0].erusinOrWedding === "חתונה" && <th style={{ fontWeight: '400', width: '6%', textAlign: 'center', color: 'blue' }}>כמה ימים לפני החתונה</th>}
                        <th style={{ fontWeight: '400', width: '15%', textAlign: 'center' }}>לאיזה הוצאה שייך</th>
                    </tr>
                </thead>
                <tbody>
                    {tassksForTable.map((task, i) => (
                        <tr style={{ fontWeight: '300', textAlign: 'center', borderTop: '#c00000 1px solid' }} key={i}>
                            {edit.id !== task.id && <Fragment>
                                <td style={{ textAlign: 'center' }}>{task.name}</td>
                                <td style={{ textAlign: 'center', width: `${tassksForTable[0].erusinOrWedding === "חתונה" ? '20%' : '30%'}`, maxWidth: '20px' }}>{task.explanation}</td>
                                <td style={{ textAlign: 'center' }}>{task.button}</td>
                                <td style={{ textAlign: 'center' }}>{task.categoryCode}</td>
                                <td style={{ textAlign: 'center' }}>{task.isChatanOrKalaOr2 === 1 ? "כלה" : task.isChatanOrKalaOr2 === 0 ? "חתן" : "שני הצדדים"}</td>
                                {task.erusinOrWedding === "חתונה" && <td style={{ textAlign: 'center', color: 'orange' }}>{task.date}</td>}
                                {task.erusinOrWedding === "חתונה" && <td style={{ textAlign: 'center', color: 'blue' }}>{task.date2}</td>}
                                <td style={{ textAlign: 'center' }}>{task.paysId}</td>
                                <td><IconButton onClick={() => { setEdit(task); setTaskEdit(task) }} className='IconButton' size="medium">
                                    <EditOutlined />
                                </IconButton></td>
                                <td><IconButton onClick={() => { DeleteTaskManagger(task.id).then((data) => { setAllTasks(data); d(COMmanaggerTASKS(data)); }).catch((error) => { console.error(error); }); }} className='IconButton' size="medium">
                                    <CloseOutlined />
                                </IconButton></td>
                            </Fragment>}
                            {edit.id === task.id && <Fragment>
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskEdit.name} onChange={(e) => { if (e.target.value.length <= 50) { setTaskEdit({ ...taskEdit, name: e.target.value }) } }} defaultValue={task.name}></input></td>
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskEdit.explanation} onChange={(e) => { if (e.target.value.length <= 800) { setTaskEdit({ ...taskEdit, explanation: e.target.value }) } }} defaultValue={task.explanation}></input></td>
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskEdit.button} onChange={(e) => { if (e.target.value.length <= 40) { setTaskEdit({ ...taskEdit, button: e.target.value }) } }} defaultValue={task.button}></input></td>
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskEdit.categoryCode} onChange={(e) => { if (e.target.value <= 32767 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setTaskEdit({ ...taskEdit, categoryCode: e.target.value }) } }} defaultValue={task.categoryCode}></input></td>
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskEdit.isChatanOrKalaOr2} onChange={(e) => { if (e.target.value <= 32767 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setTaskEdit({ ...taskEdit, isChatanOrKalaOr2: e.target.value }) } }} defaultValue={task.isChatanOrKalaOr2}></input></td>
                                {task.erusinOrWedding === "חתונה" && <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskEdit.date} onChange={(e) => { if (e.target.value <= 32767 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setTaskEdit({ ...taskEdit, date: e.target.value }) } }} defaultValue={task.date}></input></td>}
                                {task.erusinOrWedding === "חתונה" && <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskEdit.date2} onChange={(e) => { if (e.target.value <= 32767 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setTaskEdit({ ...taskEdit, date2: e.target.value }) } }} defaultValue={task.date2}></input></td>}
                                <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskEdit.paysId} onChange={(e) => { if (e.target.value <= 32767 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setTaskEdit({ ...taskEdit, paysId: e.target.value }) } }} defaultValue={task.paysId}></input></td>
                                <div onClick={() => { EditTaskManagger(taskEdit).then(data => { setAllTasks(data); setTempAllTasks(data); d(COMmanaggerTASKS(data)) }).catch(error => console.error(error)); setTaskEdit({ id: '', name: '', date: '', date2: '', explanation: '', button: '', erusinOrWedding: '', paysId: '', isDid: '', isPersonal: '', categoryCode: '', isChatanOrKalaOr2: '', tasks: [] }); setEdit(false) }} style={{ width: '150%', marginTop: '18%', marginRight: '10%' }}><ButtonRed text='אישור'></ButtonRed></div>
                            </Fragment>}
                        </tr>
                    ))}
                    {openErusin && whichTable === 'erusin' && <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddErusin.name} onChange={(e) => { if (e.target.value.length <= 50) { setTaskAddErusin({ ...taskAddErusin, name: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddErusin.explanation} onChange={(e) => { if (e.target.value.length <= 800) { setTaskAddErusin({ ...taskAddErusin, explanation: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddErusin.button} onChange={(e) => { if (e.target.value.length <= 40) { setTaskAddErusin({ ...taskAddErusin, button: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddErusin.categoryCode} onChange={(e) => { if (e.target.value <= 32767) { setTaskAddErusin({ ...taskAddErusin, categoryCode: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddErusin.isChatanOrKalaOr2} onChange={(e) => { if (e.target.value <= 2) { setTaskAddErusin({ ...taskAddErusin, isChatanOrKalaOr2: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddErusin.paysId} onChange={(e) => { if (e.target.value <= 32767) { setTaskAddErusin({ ...taskAddErusin, paysId: e.target.value }) } }}></input></td>
                        <td><IconButton onClick={() => { AddTaskManagger({ ...taskAddErusin, erusinOrWedding: 'אירוסין' }).then((data) => { setAllTasks(data); d(COMmanaggerTASKS(data)); setOpenErusin(false); setTaskAddErusin({}) }).catch((error) => { console.error(error); }); }} className='IconButton' size="medium">
                            <CheckOutlined />
                        </IconButton></td>
                        <td><IconButton onClick={() => { setOpenErusin(false) }} className='IconButton' size="medium">
                            <CloseOutlined />
                        </IconButton></td>
                    </tr>}
                    {openWedding && whichTable === 'wedding' && <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddWedding.name} onChange={(e) => { if (e.target.value.length <= 50) { setTaskAddWedding({ ...taskAddWedding, name: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddWedding.explanation} onChange={(e) => { if (e.target.value.length <= 800) { setTaskAddWedding({ ...taskAddWedding, explanation: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddWedding.button} onChange={(e) => { if (e.target.value.length <= 40) { setTaskAddWedding({ ...taskAddWedding, button: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddWedding.categoryCode} onChange={(e) => { if (e.target.value <= 32767) { setTaskAddWedding({ ...taskAddWedding, categoryCode: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddWedding.isChatanOrKalaOr2} onChange={(e) => { if (e.target.value <= 32767) { setTaskAddWedding({ ...taskAddWedding, isChatanOrKalaOr2: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddWedding.date} onChange={(e) => { if (e.target.value <= 32767) { setTaskAddWedding({ ...taskAddWedding, date: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddWedding.date2} onChange={(e) => { if (e.target.value <= 32767) { setTaskAddWedding({ ...taskAddWedding, date2: e.target.value }) } }}></input></td>
                        <td><input className='inp' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={taskAddWedding.paysId} onChange={(e) => { if (e.target.value <= 32767) { setTaskAddWedding({ ...taskAddWedding, paysId: e.target.value }) } }}></input></td>
                        <td><IconButton onClick={() => { AddTaskManagger({ ...taskAddWedding, erusinOrWedding: 'חתונה' }).then((data) => { setAllTasks(data); d(COMmanaggerTASKS(data)); setOpenWedding(false); setTaskAddWedding({}) }).catch((error) => { console.error(error); }); }} className='IconButton' size="medium">
                            <CheckOutlined />
                        </IconButton></td>
                        <td><IconButton onClick={() => { setOpenWedding(false) }} className='IconButton' size="medium">
                            <CloseOutlined />
                        </IconButton></td>
                    </tr>}
                </tbody>
            </table>
            </>}

        </div>
    }


    return <div>

        <a href="#buttonAddErusin" className='buton1' style={{ width: '20%', position: 'absolute', top: '14%', left: '4.8%', textDecoration: 'none' }}><ButtonRed text='הוספת מטלה לקראת האירוסין'></ButtonRed></a>
        <a href="#buttonAddWedding" className='buton1' style={{ width: '20%', position: 'absolute', top: '21%', left: '4.8%', textDecoration: 'none' }}><ButtonRed text='הוספת מטלה לקראת החתונה'></ButtonRed></a>


        {wait && <Wait></Wait>}
        <Search funcSearch={funcSearch} txt={"ברשימת המטלות"}></Search>

        {allTasks.length === 0 && <>
            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>רשימת מטלות</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            <div style={{ padding: '7%' }}>לא נמצאו תוצאות</div>
        </>}


        {/* ---------------------------------------------------------------------------------------------------------
                                        begin table
--------------------------------------------------------------------------------------------------------- */}
        {allTasks.filter(a => a.erusinOrWedding === 'אירוסין').length > 0 && <><p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>רשימת מטלות לקראת האירוסין</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            <div style={{ marginBottom: '3%' }}></div>
            <div style={{}}> {table(allTasks.filter(a => a.erusinOrWedding === 'אירוסין'), 'erusin')} </div>
            <div id="buttonAddErusin" className='buton' style={{ marginTop: '0', float: 'left', marginLeft: '5%', width: '15%', scrollMarginTop: '200px' }} onClick={() => { setOpenErusin(true) }}><ButtonRed text='הוספת מטלה'></ButtonRed></div>
        </>}

        {allTasks.filter(a => a.erusinOrWedding === 'חתונה').length > 0 && <>
            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '5%', marginBottom: '30px' }}>רשימת מטלות לקראת החתונה</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            <div style={{ marginBottom: '3%' }}></div>

            <div> {table(allTasks.filter(a => a.erusinOrWedding === 'חתונה'), 'wedding')} </div>
            <div id="buttonAddWedding" className='buton' style={{ marginTop: '0', float: 'left', marginLeft: '5%', width: '15%', scrollMarginTop: '200px' }} onClick={() => { setOpenWedding(true) }}><ButtonRed text='הוספת מטלה'></ButtonRed></div>
        </>}
        {/* ---------------------------------------------------------------------------------------------------------
                                        end table
--------------------------------------------------------------------------------------------------------- */}

    </div>
}

export default TasksManag