
export const COMPLETEallShoppingCart = (data) => {
    return({ type: "COMPLETEallShoppingCart", payload: data });
};

export const COMPLETEshoppingCartLotOfItems = (data) => {
    return({ type: "COMPLETEshoppingCartLotOfItems", payload: data });
};

export const COMPLETEDelCart = (data) => {
    return({ type: "COMPLETEDelCart", payload: data });
};

// export const GETallShoppingCart = (data) => {
//     return({ type: "GETallShoppingCart", payload: data });
// };