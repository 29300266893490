// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal2 {
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.5);
}

#formCon2 {
    position: relative;
    width: 60%;
    background-color: white;
    height: 60%;
    margin-top: 7%;
    color: black;
    margin-left: auto;
    margin-right: auto;
    border: #c00000 2px solid;
    border-radius: 20px 20px 20px 20px;
}

.close2 {
    cursor: pointer;
    position: absolute;
    left: 12%;
    margin-top: 1.5%;
}

.close2:hover {
    color: #c00000;
    font-size: large;
}

.glow {
    background-color: white;
    width: 80%;
    height: 60vh;
    border-radius: 20px 20px 20px 20px;
    border: #c00000 3px solid;
    margin-right: 10%;
    margin-top: 20vh;
}

.titl {
    margin-top: 3%;
    font-size: 120%;
    margin-bottom: 5%;
}
`, "",{"version":3,"sources":["webpack://./src/components/global/alert/Alert.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,kCAAkC;IAClC,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB","sourcesContent":["#modal2 {\r\n    position: fixed;\r\n    z-index: 800;\r\n    left: 0;\r\n    top: 0;\r\n    width: 100%;\r\n    height: 120%;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n#formCon2 {\r\n    position: relative;\r\n    width: 60%;\r\n    background-color: white;\r\n    height: 60%;\r\n    margin-top: 7%;\r\n    color: black;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    border: #c00000 2px solid;\r\n    border-radius: 20px 20px 20px 20px;\r\n}\r\n\r\n.close2 {\r\n    cursor: pointer;\r\n    position: absolute;\r\n    left: 12%;\r\n    margin-top: 1.5%;\r\n}\r\n\r\n.close2:hover {\r\n    color: #c00000;\r\n    font-size: large;\r\n}\r\n\r\n.glow {\r\n    background-color: white;\r\n    width: 80%;\r\n    height: 60vh;\r\n    border-radius: 20px 20px 20px 20px;\r\n    border: #c00000 3px solid;\r\n    margin-right: 10%;\r\n    margin-top: 20vh;\r\n}\r\n\r\n.titl {\r\n    margin-top: 3%;\r\n    font-size: 120%;\r\n    margin-bottom: 5%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
