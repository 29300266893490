import './AllCards.css'
import './CardPic.css'
// import { Checkbox } from 'semantic-ui-react'
import BottomMenu from '../bottomMenu/BottomMenu';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GetBussinessForCategory, GetCatchDate } from '../../axios/api';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteBussiness } from '../../redux/actions/bussinessActions';
import CardPic from './CardPic';
import { ArrowRightOutlined, CheckOutlined, SearchOutlined } from '@ant-design/icons';
import RefReady from '../ref';
import { IconButton } from '@mui/material';
import { TroubleshootTwoTone } from '@mui/icons-material';


// import chair from '../../../pic/chair.png'
import globe from '../../../pic/animations/12.gif'
import or from '../../../pic/animations/122.gif'
import chair from '../../../pic/animations/114.gif'
import catering from '../../../pic/animations/130.gif'

import price3 from '../../../pic/animations/127.gif'
import music from '../../../pic/animations/20.gif'
import des from '../../../pic/animations/14.gif'
import more from '../../../pic/animations/18.gif'
import price from '../../../pic/animations/116.gif'
import include from '../../../pic/animations/128.gif'
// import gift from '../../../pic/gift.webp'
// import catering from '../../../pic/food.jpg'
import is from '../../../pic/animations/111.gif'
import clock from '../../../pic/animations/11.gif'
import Wait from '../Wait';



const AllCards = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    var p = useParams()
    var navigate = useNavigate()
    var categoryCode = p.categoryCode, categoryName = p.categoryName, name = p.name;

    const devitions = useSelector(s => s.devitionReducer)
    const thisDevition = devitions.length > 0 ? devitions.filter(d => d.id == categoryCode) : {}
    const [bussiness, setBussiness] = useState();
    const [bussinessTemp, setBussinessTemp] = useState();
    const [bAll, setBAll] = useState({ city: '', date: [], price: [], size: [], signon: '', area: '' });
    var bAll2 = { city: '', date: [], price: [], size: [], signon: '', area: '' };

    const d = useDispatch()
    const [isTrue, setIsTrue] = useState(false)
    const [menu, setMenu] = useState(false)
    const [city, setCity] = useState([])
    const [wait, setWait] = useState(true)

    useEffect(() => {
        GetBussinessForCategory(categoryCode).then((data) => {
            setWait(false)
            window.scrollTo(0, 0);
            setBussiness(data);
            setBussinessTemp(data);
            setIsTrue(true)
            var cities = data.map(c => c.city)
            var cities2 = []
            for (let i = 0; i < cities.length; i++) {
                var c = cities[i].split(',.')
                for (let j = 0; j < c.length; j++) {
                    cities2.push(c[j])
                }
            }
            setCity(cities2)
            d(CompleteBussiness(data));
        }).catch((error) => { console.error(error); });
    }, [name]);


    function openNav() {
        setMenu(TroubleshootTwoTone)
        document.getElementById('mySidenav').style.width = "320px";
        document.getElementById("main1").style.marginRight = "10%";
        document.getElementById("main1").style.width = "88%";
        document.getElementById("BottomMenu").style.width = "105%";
        if (isSticky) {
            // document.getElementById("firstDiv").style.paddingRight = "15%";
            document.getElementById("firstDiv").style.width = "95%";
        }
        // var card100 = document.getElementsByClassName("card100")
        // for (let i = 0; i < card100.length; i++) {
        //     card100[i].style.width = "89%"
        // }
    }

    function closeNav() {
        setMenu(false)
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main1").style.marginRight = "0";
        document.getElementById("main1").style.width = "100%";
        if (isSticky)
            document.getElementById("firstDiv").style.width = "100%";
        // var card100 = document.getElementsByClassName("card100")
        // for (let i = 0; i < card100.length; i++) {
        //     card100[i].style.width = "80%"
        // }
    }

    //----------------------------------------filters--------------------------------------------
    const [search, setSearch] = useState();

    function searchData(x) {
        setBussiness(bussinessTemp)
        setIsTrue(false)
        setBussiness(bussiness.filter(a => a.name.includes(x)));
        setTimeout(() => { setIsTrue(true); }, 2)
        setResultSearch([])
    }

    const searchAB = () => {
        setIsTrue(false)
        var arrB = filterAll(bAll)
        setBussiness(arrB.sort((a, b) => a.name > b.name ? 1 : -1));
        var sort = document.getElementsByClassName('sort')
        for (let i = 0; i < sort.length; i++) {
            const element = sort[i];
            if (i == 0)
                element.style.color = "#ccc"
            else
                element.style.color = "black"
        }
        setTimeout(() => { setIsTrue(true); }, 2)
    }

    const searchPrice = () => {
        setIsTrue(false)
        var arrB = filterAll(bAll)
        setBussiness(arrB.sort((a, b) => Number(a.price) > Number(b.price) ? 1 : -1));
        var sort = document.getElementsByClassName('sort')
        for (let i = 0; i < sort.length; i++) {
            const element = sort[i];
            if (i == 1)
                element.style.color = "#ccc"
            else
                element.style.color = "black"
        }
        setTimeout(() => { setIsTrue(true); }, 2)
    }

    const searchPriceCatering = () => {
        debugger
        setIsTrue(false)
        var arrB = filterAll(bAll)
        setBussiness(arrB.sort((a, b) => Number(a.rangePrice.split('-')[0]) > Number(b.rangePrice.split('-')[0]) ? 1 : -1));
        var sort = document.getElementsByClassName('sort')
        for (let i = 0; i < sort.length; i++) {
            const element = sort[i];
            if (i == 1)
                element.style.color = "#ccc"
            else
                element.style.color = "black"
        }
        setTimeout(() => { setIsTrue(true); }, 2)
    }

    const searchSize = () => {
        setIsTrue(false)
        var arrB = filterAll(bAll)
        setBussiness(arrB.sort((a, b) => a.size.split(' ')[0] > b.size.split(' ')[0] ? 1 : -1));
        var sort = document.getElementsByClassName('sort')
        for (let i = 0; i < sort.length; i++) {
            const element = sort[i];
            if (i == 2)
                element.style.color = "#ccc"
            else
                element.style.color = "black"
        }
        setTimeout(() => { setIsTrue(true); }, 2)
    }

    const searchDishes = (num) => {
        setIsTrue(false)
        var arrB = filterAll(bAll)
        setBussiness(arrB.sort((a, b) => num === 2 ? Number(a.rangeDishes.split('-')[0]) > Number(b.rangeDishes.split('-')[0]) ? 1 : -1 : Number(a.rangeDishesKabalat.split('-')[0]) > Number(b.rangeDishesKabalat.split('-')[0]) ? 1 : -1));
        var sort = document.getElementsByClassName('sort')
        for (let i = 0; i < sort.length; i++) {
            const element = sort[i];
            if (i == num)
                element.style.color = "#ccc"
            else
                element.style.color = "black"
        }
        setTimeout(() => { setIsTrue(true); }, 2)
    }

    const searchNumCome = (code) => {
        setIsTrue(false)
        bAll2 = bAll
        bAll2.size = []
        var arrB = filterAll(bAll2)
        var sh = [], f, is = false, size = bAll.size;
        const check = document.getElementsByClassName("checkbox1")
        if (check[0].checked) {
            is = true;
            sh = code === "4" ? arrB.filter(a => a.rangeDishes.split('-')[0] < 150) : code === "82" ? arrB.filter(a => a.size.split(' ')[0] <= 100) : arrB.filter(a => a.size.split(' ')[0] <= 150);
            if (code !== "82")
                size.push({ a: '', b: 150 })
            else
                size.push({ a: '', b: 100 })
            // b = arrB;
        }
        if (check[1].checked) {
            is = true;
            f = code === "4" ? arrB.filter(a => a.rangeDishes.split('-')[0] < 400 && a.rangeDishes.split('-')[1]?.split(' ')[0] > 150) : code === "82" ? arrB.filter(a => a.size.split(' ')[0] > 100 && a.size.split(' ')[0] <= 200) : arrB.filter(a => a.size.split(' ')[0] > 150 && a.size.split(' ')[0] <= 400)
            if (code !== "82")
                size.push({ a: 150, b: 400 })
            else
                size.push({ a: 100, b: 200 })
            f.forEach(element => { sh.push(element) });
            // b = arrB;
        }
        if (check[2].checked) {
            is = true;
            f = code === "4" ? arrB.filter(a => a.rangeDishes.split('-')[0] < 600 && a.rangeDishes.split('-')[1]?.split(' ')[0] > 400) : code === "82" ? arrB.filter(a => a.size.split(' ')[0] > 200) : arrB.filter(a => a.size.split(' ')[0] > 400 && a.size.split(' ')[0] <= 600)
            if (code !== "82")
                size.push({ a: 400, b: 600 })
            else
                size.push({ a: 200, b: '' })
            f.forEach(element => { sh.push(element) });
            // b = arrB;
        }
        if (code !== "82" && check[3].checked) {
            is = true;
            f = code === "4" ? arrB.filter(a => a.rangeDishes.split('-')[1]?.split(' ')[0] > 600) : arrB.filter(a => a.size.split(' ')[0] > 600);
            size.push({ a: 600, b: '' })
            f.forEach(element => { sh.push(element) });
            // b = arrB;
        }
        setBAll({ ...bAll, size: size })
        if (is) { setBussiness(sh) }
        else { setBussiness(arrB) }
        setTimeout(() => { setIsTrue(true); }, 2)
    }

    const filterPrice = () => {
        setIsTrue(false)
        bAll2 = bAll
        bAll2.price = []
        var arrB = filterAll(bAll2)
        var sh = [], f, is = false, price = bAll.price;
        const check = document.getElementsByClassName("checkbox2")
        if (check[0].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? sh = arrB.filter(a => a.rangePrice < 5000) : sh = arrB.filter(a => a.price <= 5000);
            price.push({ a: '', b: 5000 })
        }
        if (check[1].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? f = arrB.filter(a => a.rangePrice > 5000 && a.rangePrice < 10000) : f = arrB.filter(a => a.price > 5000 && a.price <= 10000)
            price.push({ a: 5000, b: 10000 })
            f.forEach(element => { sh.push(element) });
        }
        if (check[2].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? f = arrB.filter(a => a.rangePrice > 10000 && a.rangePrice < 15000) : f = arrB.filter(a => a.price > 10000 && a.price <= 15000)
            price.push({ a: 10000, b: 15000 })
            f.forEach(element => { sh.push(element) });
        }
        if (check[3].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? f = arrB.filter(a => a.rangePrice > 15000 && a.rangePrice < 20000) : f = arrB.filter(a => a.price > 15000 && a.price <= 20000);
            price.push({ a: 15000, b: 20000 })
            f.forEach(element => { sh.push(element) });
        }
        if (check[4].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? f = arrB.filter(a => a.rangePrice > 20000) : f = arrB.filter(a => a.price > 20000);
            price.push({ a: 20000, b: '' })
            f.forEach(element => { sh.push(element) });
        }
        setBAll({ ...bAll, price: price })
        if (is) { setBussiness(sh) }
        else { setBussiness(arrB) }
        setTimeout(() => { setIsTrue(true); }, 2)
    }

    const filterPriceAll = (sum) => {
        setIsTrue(false)
        bAll2 = bAll
        bAll2.price = []
        var arrB = filterAll(bAll2)
        var sh = [], f, is = false, price = bAll.price;
        const check = document.getElementsByClassName("checkbox2")
        if (check[0].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? sh = arrB.filter(a => a.rangePrice <= sum[0]) : sh = arrB.filter(a => a.price <= sum[0]);
            price.push({ a: '', b: sum[0] })
        }
        if (check[1].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? f = arrB.filter(a => a.rangePrice > sum[0] && a.rangePrice <= sum[1]) : f = arrB.filter(a => a.price > sum[0] && a.price <= sum[1])
            price.push({ a: sum[0], b: sum[1] })
            f.forEach(element => { sh.push(element) });
        }
        if (check[2].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? f = arrB.filter(a => a.rangePrice > sum[1] && a.rangePrice <= sum[2]) : f = arrB.filter(a => a.price > sum[1] && a.price <= sum[2])
            price.push({ a: sum[1], b: sum[2] })
            f.forEach(element => { sh.push(element) });
        }
        if (check[3].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? f = arrB.filter(a => a.rangePrice > sum[2] && a.rangePrice <= sum[3]) : f = arrB.filter(a => a.price > sum[2] && a.price <= sum[3]);
            price.push({ a: sum[2], b: sum[3] })
            f.forEach(element => { sh.push(element) });
        }
        if (check[4].checked) {
            is = true;
            (categoryCode == "4" || categoryCode == "3") ? f = arrB.filter(a => a.rangePrice > sum[3]) : f = arrB.filter(a => a.price > sum[3]);
            price.push({ a: sum[3], b: '' })
            f.forEach(element => { sh.push(element) });
        }
        setBAll({ ...bAll, price: price })
        if (is) { setBussiness(sh) }
        else { setBussiness(arrB) }
        setTimeout(() => { setIsTrue(true); }, 2)
    }

    const filterPriceCatering = () => {
        setIsTrue(false)
        bAll2 = bAll
        bAll2.price = []
        var arrB = filterAll(bAll2)
        var sh = [], f, is = false, price = bAll.price;
        const check = document.getElementsByClassName("checkbox2")
        if (check[0].checked) {
            is = true;
            categoryCode == "4" ? sh = arrB.filter(a => a.rangePrice.split('-')[0] < 70) : sh = arrB.filter(a => a.price <= 70);
            price.push({ a: '', b: 70 })
        }
        if (check[1].checked) {
            is = true;
            categoryCode == "4" ? f = arrB.filter(a => a.rangePrice.split('-')[1]?.split(' ')[0] > 70 && a.rangePrice.split('-')[0] < 100) : f = arrB.filter(a => a.price > 70 && a.price <= 100)
            price.push({ a: 70, b: 100 })
            f.forEach(element => { sh.push(element) });
        }
        if (check[2].checked) {
            is = true;
            categoryCode == "4" ? f = arrB.filter(a => a.rangePrice.split('-')[1]?.split(' ')[0] > 100 && a.rangePrice.split('-')[0] < 130) : f = arrB.filter(a => a.price > 100 && a.price <= 130)
            price.push({ a: 100, b: 130 })
            f.forEach(element => { sh.push(element) });
        }
        if (check[3].checked) {
            is = true;
            categoryCode == "4" ? f = arrB.filter(a => a.rangePrice.split('-')[1]?.split(' ')[0] > 130 && a.rangePrice.split('-')[0] < 200) : f = arrB.filter(a => a.price > 130 && a.price <= 200);
            price.push({ a: 130, b: 200 })
            f.forEach(element => { sh.push(element) });
        }
        if (check[4].checked) {
            is = true;
            categoryCode == "4" ? f = arrB.filter(a => a.rangePrice.split('-')[1]?.split(' ')[0] > 200) : f = arrB.filter(a => a.price > 200);
            price.push({ a: 200, b: '' })
            f.forEach(element => { sh.push(element) });
        }
        setBAll({ ...bAll, price: price })
        if (is) { setBussiness(sh) }
        else { setBussiness(arrB) }
        setTimeout(() => { setIsTrue(true); }, 2)
    }


    const [resultSearch, setResultSearch] = useState([])
    const searchD = (search1) => {
        setSearch(search1)
        setBussiness(bussinessTemp)
        setResultSearch(bussiness.filter(c => c.name.includes(search1)));
    }

    const filterDevition = (devitionId) => {
        setIsTrue(false)
        if (devitionId == -1)
            setBussiness(bussinessTemp)
        else
            setBussiness(bussinessTemp.filter(b => b.categoryCode == devitionId))
        setTimeout(() => { setIsTrue(true); }, 2)
        return bussinessTemp.filter(b => b.categoryCode == devitionId);
    }

    const FilterDate = (date) => {
        bAll2 = bAll
        bAll2.date = []
        var arrB = filterAll(bAll2)
        if (arrB.length > 0) {
            setIsTrue(false)
            GetCatchDate(date, Number(arrB[0].categoryCode)).then((data) => {
                setBAll({ ...bAll, date: data })
                data.forEach(element => {
                    setBussiness(arrB.filter(b => b.id !== element))
                });
                if (data.length == 0) {
                    setBAll({ ...bAll, date: [] })
                    setBussiness(arrB)
                }
            }).catch((error) => { console.error(error); });

            setTimeout(() => { setIsTrue(true); }, 2)
        }
    }

    const [dateSearch, setDateSearch] = useState(false)

    function searchCity(x) {
        bAll2 = bAll
        bAll2.city = ''
        setIsTrue(false)
        setBussiness(filterAll(bAll2).filter(a => a.city.includes(x)));
        setBAll({ ...bAll, city: x })
        setTimeout(() => { setIsTrue(true); }, 2)
        setResultSearchCity([])
    }
    const [resultSearchCity, setResultSearchCity] = useState([])
    var sssCity = false;

    const searchDCity = (search1) => {
        debugger
        bAll2 = bAll
        bAll2.city = ''
        var arrB = filterAll(bAll2)
        setBAll({ ...bAll, city: search1 })
        setBussiness(arrB.filter(c => c.city.includes(search1)))
        // var uniqueCities = Array.from(new Set(bussinessTemp.filter(c => c.city.includes(search1)).map(c => c.city)));
        var uniqueCities = Array.from(new Set(city.filter(c => c.includes(search1))));
        setResultSearchCity(uniqueCities);
    }

    const filterAll = (bAll2) => {
        debugger

        var ArrB = bussinessTemp

        if (chooze >= 0)
            ArrB = filterDevition(thisDevition[0].divisionsId[chooze]);

        // if (ArrB.length > 0 && ArrB[0].city)
        if (ArrB[0]?.city !== undefined)
            ArrB = ArrB.filter(a => a.city.includes(bAll2.city))
        bAll2.date?.forEach(element => {
            ArrB = ArrB.filter(b => b.id !== element)
        });

        if (ArrB[0]?.area !== undefined && bAll2.area !== '')
            ArrB = ArrB.filter(a => a.area !== null && a.area.includes(bAll2.area))

        if (ArrB[0]?.signon !== undefined && bAll2.signon !== '')
            ArrB = ArrB.filter(a => a.signon !== null && a.signon.includes(bAll2.signon))

        // ArrB = ArrB.filter(a => bAll2.date.includes(a.id))
        if (bAll2.price.length > 0) {
            var arrC = [], tempArr = []
            bAll2.price.forEach(element => {
                if (element.a !== '' && element.b !== '')
                    tempArr = (categoryCode == "4" || categoryCode == "3") ? ArrB.filter(a => a.rangePrice > element.a && a.rangePrice <= element.b) : ArrB.filter(a => a.price > element.a && a.price <= element.b)
                else if (element.a !== '' && element.b === '')
                    tempArr = (categoryCode == "4" || categoryCode == "3") ? ArrB.filter(a => a.rangePrice < element.a) : ArrB.filter(a => a.price < element.a)
                else
                    tempArr = (categoryCode == "4" || categoryCode == "3") ? ArrB.filter(a => a.rangePrice > element.b) : ArrB.filter(a => a.price > element.b)
                tempArr.forEach(element => { arrC.push(element) });
            });
            ArrB = arrC
        }

        if (bAll2.size.length > 0) {
            var arrC = [], tempArr = []
            bAll2.size.forEach(element => {
                if (element.a !== '' && element.b !== '')
                    tempArr = categoryCode === "4" ? ArrB.filter(a => a.rangeDishes.split('-')[0] < element.b && a.rangeDishes.split('-')[1].split(' ')[0] > element.a) : ArrB.filter(a => a.size > element.a && a.size <= element.b)
                else if (element.a !== '' && element.b === '')
                    tempArr = categoryCode === "4" ? ArrB.filter(a => a.rangeDishes.split('-')[1].split(' ')[0] > element.a) : ArrB.filter(a => a.size > element.a)
                else
                    tempArr = categoryCode === "4" ? ArrB.filter(a => a.rangeDishes.split('-')[0] < element.b) : ArrB.filter(a => a.size < element.b)
                tempArr.forEach(element => { arrC.push(element) });
            });
            ArrB = arrC
        }

        return ArrB;
    }

    var sss = false;
    //----------------------------------------end filters--------------------------------------------


    // --------------------------------------------scroll------------------------------------------------
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        if (isSticky) {
            if (menu !== false)
                document.getElementById("firstDiv").style.width = "95%";
            else
                document.getElementById("firstDiv").style.width = "100%";
        }
        else if (document.getElementById("firstDiv")) {
            document.getElementById("firstDiv").style.width = "100%";
        }
    }, [isSticky])

    useEffect(() => {
        const handleScroll = () => {
            const div = document.getElementById('firstDiv');
            const divTitleHead = document.getElementById('titleHead');
            if (div !== null) {
                const rect1 = div.getBoundingClientRect();
                setIsSticky(rect1.top <= 120);
                if (rect1.top <= 120) {
                    const divTitleHead = document.getElementById('titleHead');
                    const rect2 = divTitleHead.getBoundingClientRect();
                    setIsSticky(rect2.top <= 0);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    // --------------------------------------------end scroll------------------------------------------------


    const [chooze, setChooze] = useState(-1)

    return <div>
        <div id="mySidenav" className="sidenav">

            <div className="closebtn" onClick={() => closeNav()}>&times;</div>

            {(categoryCode == "1" || categoryCode == "2" || categoryCode == "3" || categoryCode == "4" || categoryCode == "82") && <div>
                <input style={{ display: 'inline-block', width: '65%' }} id='search' type='text' placeholder='חפש שם עסק' onChange={(e) => searchD(e.target.value)} onClick={(e) => searchD(e.target.value)} onBlur={() => { setTimeout(() => { if (!sss) { setResultSearch([]) } }, 300) }}></input>
                <span onClick={() => { searchData(search) }}><SearchOutlined /></span>
                {resultSearch[0] && <div style={{ width: '90%', marginRight: '5%' }} className='resultSearchRound animate__animated animate__fadeInDown'>{resultSearch.map((x, i) => (
                    <div className='resultSearch' key={i} onClick={() => { sss = true; document.getElementById('search').value = x.name; searchD(x.name); setResultSearch([]); searchData(x.name) }}>{x.name}</div>
                ))}</div>}

                <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>מיון לפי:</div>
                <div className='hr'></div>
                <div className='sidenavBtn sort' onClick={() => searchAB()}>א-ב</div>
                <div className='hr'></div>
                <div className='sidenavBtn sort' onClick={() => categoryCode == "4" ? searchPriceCatering() : searchPrice()}>מחיר מזול ליקר</div>
                <div className='hr'></div>
                {(categoryCode == "1" || categoryCode == "82") && <div className='sidenavBtn sort' onClick={() => searchSize()}>מספר מקומות</div>}
                {categoryCode == "4" && <><div className='sidenavBtn sort' onClick={() => searchDishes(2)}>מספר מנות סעודה</div>
                    <div className='hr'></div>
                    <div className='sidenavBtn sort' onClick={() => searchDishes(3)}>מספר מנות קבלת פנים</div>
                </>}
                {(categoryCode == "1" || categoryCode == "4" || categoryCode == "82") && <div className='hr'></div>}

                <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>סינון לפי:</div>
                <div className='hr'></div>
                {categoryCode === "2" && <>
                    <select className="custom-select1 sort" style={{ border: 'none', marginRight: '0', marginTop: '0', width: '60%' }} onChange={(e) => { bAll2 = bAll; bAll2.signon = e.target.value; var arr5 = filterAll(bAll2); setIsTrue(false); setBussiness(arr5); setTimeout(() => { setIsTrue(true); }, 2); setBAll(bAll2) }}>
                        <option style={{ fontWeight: '400' }} value="">סגנון מוזיקה</option>
                        <option value="חסידי אותנטי">חסידי אותנטי</option>
                        <option value="חסידי ישיבתי">חסידי ישיבתי</option>
                        <option value="מוזיקה עדכנית וחדשנית">מוזיקה עדכנית וחדשנית</option>
                        <option value="ליטאי">ליטאי</option>
                        <option value='חבד"י'>חבד"י</option>
                        <option value="מזרחי">מזרחי</option>
                    </select>
                    <div className='hr'></div>
                </>}
                {(categoryCode === "2" || categoryCode === "3" || categoryCode === "4") && <>
                    <select className="custom-select1 sort" style={{ border: 'none', marginRight: '0', marginTop: '0', width: '60%' }} onChange={(e) => { bAll2 = bAll; bAll2.area = e.target.value; var arr5 = filterAll(bAll2); setIsTrue(false); setBussiness(arr5); setTimeout(() => { setIsTrue(true); }, 2); setBAll(bAll2) }}>
                        <option style={{ fontWeight: '400' }} value="" >איזור פעילות</option>
                        <option value="כל הארץ" >כל הארץ</option>
                        <option value="צפון">צפון</option>
                        <option value="מרכז">מרכז</option>
                        <option value="דרום">דרום</option>
                    </select>
                    <div className='hr'></div>
                </>}
                {/* {categoryCode == "2" && <div>
                    <select className="custom-select1 sidenavBtn" style={{border:'none', marginRight:'10%', marginTop:'0'}} onChange={(e) => {}}>
                        <option style={{ fontWeight: '400' }} value="">סגנון מוזיקה</option>
                        <option value="חסידי אותנטי">חסידי אותנטי</option>
                        <option value="חסידי ישיבתי">חסידי ישיבתי</option>
                        <option value="מוזיקה עדכנית וחדשנית">מוזיקה עדכנית וחדשנית</option>
                        <option value="ליטאי">ליטאי</option>
                        <option value='חבד"י'>חבד"י</option>
                        <option value="מזרחי">מזרחי</option>
                    </select>
                    <div className='hr'></div>
                </div>} */}

                {bussinessTemp?.length > 0 && bussinessTemp[0].city && <div><div className='sidenavBtn'>
                    <input id='search48' style={{ textAlign: 'center', border: 'none', outline: 'none' }} type='text' placeholder='עיר' onChange={(e) => searchDCity(e.target.value)} onClick={(e) => searchDCity(e.target.value)} onBlur={() => { setTimeout(() => { if (!sssCity) { setResultSearchCity([]) } }, 300) }}></input>
                    {resultSearchCity[0] && <div style={{ width: '90%', marginRight: '5%' }} className='resultSearchRound animate__animated animate__fadeInDown'>{resultSearchCity.map((x, i) => (
                        <div className='resultSearch' key={i} onClick={() => { sssCity = true; document.getElementById('search48').value = x; searchDCity(x); searchCity(x) }}>{x}</div>
                    ))}</div>}</div>
                    <div className='hr'></div>
                </div>}




                {!dateSearch && <div className='sidenavBtn' onClick={() => setDateSearch(true)}>תאריך</div>}
                {dateSearch && <div className='sidenavBtn'>
                    <IconButton align="right" className='IconButton' size="small" style={{ marginLeft: '4%', marginRight: '5%' }} onClick={() => { setDateSearch(false); bAll2 = bAll; bAll2.date = []; var arrB = filterAll(bAll2); setBAll({ ...bAll, date: [] }); setBussiness(arrB) }}><ArrowRightOutlined /></IconButton>
                    <input type='date' style={{ border: 'none', width: '65%' }} className='dateSearch' onBlur={(e) => FilterDate(e.target.value)}></input>
                    <IconButton align="left" className='IconButton' size="small" style={{ marginRight: '4%' }}><CheckOutlined /></IconButton>
                </div>}


                {(categoryCode == "1" || categoryCode == "4") && <div><div className='hr'></div>
                    <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>מספר מוזמנים לסעודה</div>
                    <div className='hr'></div>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox1" onClick={() => searchNumCome(categoryCode)} /><span className="checkbox-custom">&#10003;</span> &nbsp;עד 150 איש</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox1" onClick={() => searchNumCome(categoryCode)} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 150 ל-400 איש</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox1" onClick={() => searchNumCome(categoryCode)} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 400 ל-600 איש</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox1" onClick={() => searchNumCome(categoryCode)} /><span className="checkbox-custom">&#10003;</span> &nbsp;מעל 600 איש</label><br></br>
                </div>}

                {categoryCode == "82" && <div><div className='hr'></div>
                    <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>מספר מוזמנים לסעודה</div>
                    <div className='hr'></div>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox1" onClick={() => searchNumCome(categoryCode)} /><span className="checkbox-custom">&#10003;</span> &nbsp;עד 100 איש</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox1" onClick={() => searchNumCome(categoryCode)} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 100 ל-200 איש</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox1" onClick={() => searchNumCome(categoryCode)} /><span className="checkbox-custom">&#10003;</span> &nbsp;מעל 200 איש</label><br></br>
                </div>}

                {categoryCode !== "4" && categoryCode !== "3" && categoryCode !== "82" && categoryCode !== "2" && <>
                    <div className='hr'></div>
                    <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>טווח מחירים</div>
                    <div className='hr'></div>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPrice()} /><span className="checkbox-custom">&#10003;</span> &nbsp;עד 5,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPrice()} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 5,000 ל-10,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPrice()} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 10,000 ל-15,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPrice()} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 15,000 ל-20,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPrice()} /><span className="checkbox-custom">&#10003;</span> &nbsp;מעל 20,000</label><br></br>
                </>}

                {categoryCode === "2" && <>
                    <div className='hr'></div>
                    <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>טווח מחירים</div>
                    <div className='hr'></div>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 2000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;עד 1,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 2000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 1,000 ל-2,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 2000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 2,000 ל-4,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 2000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 4,000 ל-6,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 2000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;מעל 6,000</label><br></br>
                </>}

                {categoryCode === "82" && <>
                    <div className='hr'></div>
                    <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>טווח מחירים</div>
                    <div className='hr'></div>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 1500, 2000, 2500])} /><span className="checkbox-custom">&#10003;</span> &nbsp;עד 1,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 1500, 2000, 2500])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 1,000 ל-1,500</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 1500, 2000, 2500])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 1,500 ל-2,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 1500, 2000, 2500])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 2,000 ל-2,500</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([1000, 1500, 2000, 2500])} /><span className="checkbox-custom">&#10003;</span> &nbsp;מעל 2,500</label><br></br>
                </>}

                {categoryCode === "3" && <>
                    <div className='hr'></div>
                    <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>טווח מחירים</div>
                    <div className='hr'></div>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([2000, 3000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;עד 2,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([2000, 3000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 2,000 ל-3,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([2000, 3000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 3,000 ל-4,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([2000, 3000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 4,000 ל-6,000</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceAll([2000, 3000, 4000, 6000])} /><span className="checkbox-custom">&#10003;</span> &nbsp;מעל 6,000</label><br></br>
                </>}
                {categoryCode === "4" && <>
                    <div className='hr'></div>
                    <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>טווח מחירים למנה</div>
                    <div className='hr'></div>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceCatering()} /><span className="checkbox-custom">&#10003;</span> &nbsp;עד 70</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceCatering()} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 70 ל-100</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceCatering()} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 100 ל-130</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceCatering()} /><span className="checkbox-custom">&#10003;</span> &nbsp;בין 130 ל-200</label><br></br>
                    <label className="checkbox-container"><input type="checkbox" className="checkbox2" onClick={() => filterPriceCatering()} /><span className="checkbox-custom">&#10003;</span> &nbsp;מעל 200</label><br></br>
                </>}
                {/* <div className='sidenavBtn'>&#9617; &#9733; &#9734; &#10219; &#10218;</div> */}
            </div>}

            {categoryCode !== "1" && categoryCode !== "2" && categoryCode !== "3" && categoryCode !== "4" && categoryCode !== "82" && <div>
                <input style={{ display: 'inline-block', marginTop: '10%' }} id='search' type='text' placeholder='חפש שם עסק' onChange={(e) => searchD(e.target.value)} onClick={(e) => searchD(e.target.value)} onBlur={() => { setTimeout(() => { if (!sss) { setResultSearch([]) } }, 300) }}></input>
                <div style={{ position: 'absolute', top: '15.5%', left: '0' }} className='sidenavBtn' onClick={() => { searchData(search) }}><SearchOutlined /></div>
                {resultSearch[0] && <div style={{ width: '90%', marginRight: '5%' }} className='resultSearchRound animate__animated animate__fadeInDown'>{resultSearch.map((x, i) => (
                    <div className='resultSearch' key={i} onClick={() => { sss = true; document.getElementById('search').value = x.name; searchD(x.name); setResultSearch([]); searchData(x.name) }}>{x.name}</div>
                ))}</div>}

                <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%', marginTop: '13%' }}>מיון לפי:</div>
                <div className='hr'></div>
                <div className='sidenavBtn sort' onClick={() => searchAB()}>א-ב</div>
                <div className='hr'></div>
                <div className='sidenavBtn' style={{ color: '#c00000', textAlign: 'right', marginRight: '10%' }}>סינון לפי:</div>
                <div className='hr'></div>

                <div className='sidenavBtn'>
                    <input id='search48' style={{ textAlign: 'center', border: 'none', outline: 'none' }} type='text' placeholder='עיר' onChange={(e) => searchDCity(e.target.value)} onClick={(e) => searchDCity(e.target.value)} onBlur={() => { setTimeout(() => { if (!sssCity) { setResultSearchCity([]) } }, 300) }}></input>
                    {resultSearchCity[0] && <div style={{ width: '90%', marginRight: '5%' }} className='resultSearchRound animate__animated animate__fadeInDown'>{resultSearchCity.map((x, i) => (
                        <div className='resultSearch' key={i} onClick={() => { sssCity = true; document.getElementById('search48').value = x; searchDCity(x); searchCity(x) }}>{x}</div>
                    ))}</div>}
                </div>
                <div className='hr'></div>
            </div>}

            <div style={{ marginBottom: '30%' }}></div>
        </div>


        <div id='sortAndFilter' onClick={() => openNav()} style={{ marginRight: '0', zIndex: '30', marginLeft: '10%' }}><div style={{ display: 'inline-block', color: '#c00000' }}>&#9776;</div> מיונים וסינונים</div>

        {/* ------------------------------------------------------------------------------------------- */}

        {thisDevition[0]?.division.length > 0 && <ul>
            <li><div className='liDiv' style={{ backgroundColor: '#9a6a6a', color: 'white' }} onClick={(e) => {
                setChooze(-1)
                filterDevition(-1);
                for (let i = 0; i < document.getElementsByClassName('liDiv').length; i++) { document.getElementsByClassName('liDiv')[i].style.backgroundColor = 'rgb(245, 238, 238)'; document.getElementsByClassName('liDiv')[i].style.color = 'black' };
                e.target.style.backgroundColor = '#9a6a6a'; e.target.style.color = 'white'
            }} onMouseOver={(e) => { e.target.style.backgroundColor = '#9a6a6a'; e.target.style.color = 'white' }}
                onMouseLeave={(e) => { if (chooze !== -1) { e.target.style.backgroundColor = 'rgb(245, 238, 238)'; e.target.style.color = 'black' } }}>הכל</div></li>
            {/* id="mySidenav1" className="sidenav1"> */}
            {thisDevition[0].division.map((x, i) => (
                <li key={i}><div className='liDiv' onClick={(e) => {
                    setChooze(i)
                    filterDevition(thisDevition[0].divisionsId[i]);
                    for (let i = 0; i < document.getElementsByClassName('liDiv').length; i++) { document.getElementsByClassName('liDiv')[i].style.backgroundColor = 'rgb(245, 238, 238)'; document.getElementsByClassName('liDiv')[i].style.color = 'black' };
                    // document.getElementsByTagName('ul')[0].style.backgroundColor = 'green';
                    e.target.style.backgroundColor = '#9a6a6a'; e.target.style.color = 'white'
                }} onMouseOver={(e) => { e.target.style.backgroundColor = '#9a6a6a'; e.target.style.color = 'white' }}
                    onMouseLeave={(e) => { if (chooze !== i) { e.target.style.backgroundColor = 'rgb(245, 238, 238)'; e.target.style.color = 'black' } }}>{x}</div></li>
            ))}
        </ul>}

        {/* ------------------------------------------------------------------------------------------- */}

        <div id='main1'>

            <p style={{ fontSize: '90%', fontWeight: '300', marginTop: '-6%', color: '#c00000', float: 'right', marginRight: '15%' }}> <span style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => navigate(-1)}>{categoryName} &#8592;</span> <span style={{ display: 'inline-block', cursor: 'pointer' }}>{name} &#8592;</span> </p>
            {/* { isTrue && <Cards name={name}></Cards>} */}
            {isTrue && <div>
                <p id='titleHead' style={{ fontSize: '150%', fontWeight: '400', marginTop: '10%', textAlign: 'center', width: '100%' }}>{name} <span style={{ fontSize: '60%' }}>({bussiness.length})</span></p>
                <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>


                {bussinessTemp?.length !== 0 && bussiness?.length !== 0 && <div><div id='firstDiv' className={isSticky ? 'sticky containeNew0' : 'containeNew0'} style={{ marginBottom: '-339px' }}>
                    {/* ---------------------------------------------אולם------------------------------------------------ */}
                    {(categoryCode == '1' || categoryCode == '82') && <RefReady tag={<div>
                        <div className='center'><img src={chair} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מספר מקומות</p></div>
                        <div className='center'><img src={globe} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מיקום</p></div>
                        <div className='center'><img src={price} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מחיר האולם</p></div>
                        <div className='center'><img src={or} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>יתרון</p></div>
                    </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

                    {/* ---------------------------------------------חנויות------------------------------------------------ */}
                    {categoryCode !== '1' && categoryCode !== '3' && categoryCode !== '82' && categoryCode !== '2' && categoryCode !== '4' && <RefReady tag={<div>
                        <div className='center'><img src={is} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>בונוס ללקוחות קול שמחה</p></div>
                        <div className='center'><img src={globe} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>עיר</p></div>
                        <div className='center'><img src={clock} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>שעות פתיחה</p></div>
                        <div className='center'><img src={des} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>יתרון</p></div>
                    </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

                    {/* ---------------------------------------------תזמורת------------------------------------------------ */}
                    {categoryCode == '2' && <RefReady tag={<div>
                        <span className='center'><img src={price} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מחיר</p></span>
                        <span className='center'><img src={include} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מה כולל המחיר</p></span>
                        <span className='center'><img src={globe} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>איזור פעילות</p></span>
                        <span className='center'><img src={music} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>סיגנון מוזיקה</p></span>
                    </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

                    {/* ---------------------------------------------קייטרינג------------------------------------------------ */}
                    {categoryCode == '4' && <RefReady tag={<div>
                        <div className='center'><img src={chair} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מנות סעודה</p></div>
                        <div className='center'><img src={catering} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מנות קבלת פנים</p></div>
                        <div className='center'><img src={price} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>טווח מחיר לסעודה</p></div>
                        <div className='center'><img src={globe} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>איזור פעילות</p></div>
                    </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}

                    {/* ---------------------------------------------צילום------------------------------------------------ */}
                    {categoryCode == '3' && <RefReady tag={<div>
                        <div className='center'><img src={price} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מחיר</p></div>
                        <div className='center'><img src={price3} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>מה כוללת החבילה?</p></div>
                        <div className='center'><img src={globe} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>איזור פעילות</p></div>
                        <div className='center'><img src={des} style={{ marginRight: 'auto', marginLeft: 'auto', width: '50px', height: '50px', display: 'block' }}></img><p style={{ fontWeight: '400', marginTop: '30px', marginBottom: '10px', whiteSpace: 'nowrap' }}>יתרון</p></div>
                    </div>} event={'animate__fadeInRight'} event2={'animate__delay-3s'}></RefReady>}


                </div>
                    <div style={{ marginBottom: '339px' }}></div>
                </div>}









                <div style={{ marginTop: '-150px', marginBottom: '20%' }}>
                    {bussiness.map((x, i) => (<span key={i} onMouseOver={() => { const all = document.getElementsByClassName('containeNew'); for (let a = 0; a < all.length; a++) { all[a].style.zIndex = '1' }; all[i].style.zIndex = '30' }}>
                        <CardPic i={i} menu={menu} bussiness={x} pic={x.mainImage} categoryName={p.categoryName} categoryCode={categoryCode} name={p.name} payId={p.payId}></CardPic>
                    </span>))}</div>
            </div>}

            {bussinessTemp?.length !== 0 && bussiness?.length == 0 && <div style={{ marginTop: '-12%', marginBottom: '10%' }}>לא נמצאו תוצאות...</div>}

            {bussinessTemp?.length == 0 && <div style={{ marginTop: '-12%' }}>עדיין לא הוכנסו עסקים לקטגוריה זו. <br></br> אנחנו עובדים על זה...</div>}
            <div id='BottomMenu'><BottomMenu></BottomMenu></div>
        </div>

        {wait && <Wait></Wait>}
    </div>
}

export default AllCards