import './TaskList.css';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ButtonRed from '../../global/button/button';
import BottomMenu from '../../global/bottomMenu/BottomMenu';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { AddPersonalPaid, DelPersonalPay, GetPays, GetPaysInit, GetpersonalPriceIdBy, UpdatePersonalPaid } from '../../axios/api';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import AlertAddPersonalPay from './AlertAddPersonalPay';
import AlertDelete from '../../global/alert/AlertDelete';
import Connection from '../../pages/connection/Connection';
import Login from '../../pages/login/Login';
import Alert from '../../global/alert/Alert';
import Search from '../../managger/Search';
import { COMPLETEpays } from '../../redux/actions/paysActions';


const HowManyToPay = () => {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  const navigate = useNavigate()
  const s = useSelector(a => a.userReducer)
  const [weddingDate, setWeddingDate] = useState(false);
  const [open, setOpen] = useState(false)
  const [alert, setAlert] = useState(false)
  const d = useDispatch()
  const paysSelector = useSelector(a => a.paysReducer)

  useEffect(() => {
    setWeddingDate(Math.ceil((new Date(s.weddingDate) - new Date()) / (24 * 60 * 60 * 1000)));
  }, [s]);

  const [pays, setPays] = useState([])
  const [tempPays, setTempPays] = useState([])
  const [sumG, setsumG] = useState(0)
  const [sumP, setsumP] = useState(0)

  const setAllPays = (data) => {
    setPays(data)
    setTempPays(data)
    var xnum = 0, ynum = 0;
    data.forEach(element => {
      xnum += element.adventagePrice;
      ynum += element.pricePaid
      // setsumG((prevSumG) => prevSumG + element.adventagePrice);
      // setsumP((prevSumP) => prevSumP + element.pricePaid);
    });
    setsumG(xnum)
    setsumP(ynum)
  }

  useEffect(() => {
    if(paysSelector.length > 0) {
      setAllPays(paysSelector)
    }
    else if (s.id) {
      GetPays(s.id).then((data) => {
        setAllPays(data)
        d(COMPLETEpays(data))
      }).catch((error) => { console.error(error); });
    }
  }, [s]);


  // -----------------------start ref-----------------------------
  const [isVisible, setIsVisible] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, // Triggers the animation only once
    threshold: 0.1, // Percentage of visibility needed to trigger the animation
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  // -----------------------end ref-----------------------------

  const [del, setDel] = useState(false)
  const delPay = () => {
    DelPersonalPay(del, s.id).then(data => { setAllPays(data); d(COMPLETEpays(data)) }).catch(error => console.error(error))
    setDel(false)
  }


  const [openGlobal, setOpenGlobal] = useState(false)

  const [login, setLogin] = useState(false);
  const [connection, setConnection] = useState(false);

  const addPaid = () => {
    debugger
    if (!s.id) {
      var xtemp = openGlobal
      xtemp.text2 = "לא ניתן להשלים את הפעולה כיון שלא התחברתם למערכת!"
      xtemp.input = false
      setOpenGlobal(false)
      setTimeout(() => {
        setOpenGlobal(xtemp)
      }, 2);
    }
    else if (document.getElementsByClassName('inp')[0].value == '') {
      var xtemp = openGlobal
      xtemp.text2 = "לא הוכנס ערך!"
      setOpenGlobal(false)
      setTimeout(() => {
        setOpenGlobal(xtemp)
      }, 2);
    }
    else if (!/^[0-9]*$/.test(document.getElementsByClassName('inp')[0].value)) {
      var xtemp = openGlobal
      xtemp.text2 = "ניתן להכניס רק ספרות!"
      setOpenGlobal(false)
      setTimeout(() => {
        setOpenGlobal(xtemp)
      }, 2);
    }
    else if (Number(document.getElementsByClassName('inp')[0].value) > 922337203685470) {
      var xtemp = openGlobal
      xtemp.text2 = "לא ניתן להכניס סכום כה גבוה!"
      setOpenGlobal(false)
      setTimeout(() => {
        setOpenGlobal(xtemp)
      }, 2);
    }
    else {
      AddPersonalPaid({ pricePaid: document.getElementsByClassName('inp')[0].value, paysId: openGlobal.x.id, userId: s.id })
        .then(data => {setAllPays(data); d(COMPLETEpays(data))}).catch(error => console.error(error));
      setOpenGlobal(false)
    }
  }


  const updatePaid = () => {
    if (document.getElementsByClassName('inp')[0].value == '') {
      var xtemp = openGlobal
      xtemp.text2 = "לא הוכנס ערך!"
      setOpenGlobal(false)
      setTimeout(() => {
        setOpenGlobal(xtemp)
      }, 2);
    }
    else if (!/^[0-9]*$/.test(document.getElementsByClassName('inp')[0].value)) {
      var xtemp = openGlobal
      xtemp.text2 = "ניתן להכניס רק ספרות!"
      setOpenGlobal(false)
      setTimeout(() => {
        setOpenGlobal(xtemp)
      }, 2);
    }
    else if (Number(document.getElementsByClassName('inp')[0].value) > 922337203685470) {
      debugger
      var xtemp = openGlobal
      xtemp.text2 = "לא ניתן להכניס סכום כה גבוה!"
      setOpenGlobal(false)
      setTimeout(() => {
        setOpenGlobal(xtemp)
      }, 2);
    }
    else {
      UpdatePersonalPaid(openGlobal.x.id, document.getElementsByClassName('inp')[0].value, s.id)
        .then(data => {setAllPays(data); d(COMPLETEpays(data))}).catch(error => console.error(error));
      setOpenGlobal(false)
    }
  }

  const isUpdatePaid = () => {
    if (openGlobal.update) {
      updatePaid()
    }
    else {
      GetpersonalPriceIdBy(openGlobal.x.id, s.id).then((data) => {
        if (data >= 0) {
          updatePaid()
        }
        else
          addPaid()
      }).catch((error) => console.error(error))
    }
  }


  const [send, setSend] = useState(false)

  const sendGood = () => {
    setAlert(false)
    setSend("ההודעה נשלחה בהצלחה !!!")
    setTimeout(() => {
      setSend(false)
    }, 40 * 100);
  }

  const funcSearch = (inp) => {
    setPays(tempPays.filter(a => a.name.includes(inp)))
    window.scrollTo(0, 0);
  }


  return <div>
    {tempPays.length > 0 && <Search funcSearch={funcSearch} txt={'ברשימת ההוצאות'}></Search>}

    <p ref={ref}
      style={{
        fontSize: '150%', fontWeight: '500', marginTop: '8%',
        transform: isVisible ? 'translateY(0)' : 'translateY(500px)', // Animating the 'Y' position of the div
        opacity: isVisible ? 1 : 0, // Fading in the div
        transition: 'transform 0.5s, opacity 0.5s', // Adding smooth transition
      }}>ארגון הוצאות האירוסין והחתונה</p>

    <div ref={ref} style={{ transition: 'width 0.5s ease-in-out' }} className={`underline3 ${isVisible ? "visible3" : ""} `}></div>

    {pays.length === 0 && tempPays.length > 0 && <div style={{ margin: '7%' }}>לא נמצאו תוצאות</div>}


    {s.id && weddingDate > 0 && <div style={{ position: 'fixed', left: '5%', marginTop: '-5%', backgroundColor: 'white' }}>{s.chatanOrKala}, בעוד {weddingDate} ימים החתונה!</div>}

    <div style={{ width: '17%', position: 'fixed', marginTop: '0' }}>
      <div style={{ width: '90%', marginRight: '10%' }}>
        <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/taskList`)}><ButtonRed text='רשימת מטלות'></ButtonRed></div>
        <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/choozedBussness`)}><ButtonRed text='ההזמנות שלי'></ButtonRed></div>
        <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/howManyToPay`)}><ButtonRed is='this' text='ארגון הוצאות'></ButtonRed></div>
        {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth <= 1300 && <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed text='רשימת כתובות'></ButtonRed></div>}
        {document.getElementsByClassName("mainNav")[0] && document.getElementsByClassName("mainNav")[0].offsetWidth > 1300 && <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed text='רשימת מוזמנים וכתובות'></ButtonRed></div>}
        <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/PersonalArea`)}><ButtonRed text='איזור אישי'></ButtonRed></div>
      </div>

      <div style={{ width: '90%', marginRight: '10%', marginTop: '5%' }}>
        <p>המחירים להמלצה בלבד ללא אחריות.</p>
        <p>ההוצאות האלה דינמיות ומשתנות מעונה לעונה, לתועלת המשתמשים נשמח לקבל עידכונים שוטפים.</p>
        <div className='buttonKishur' style={{ cursor: 'pointer', border: '#c00000 1px solid', marginTop: '-20px' }} onClick={() => { setAlert(true) }}>עדכנו אותנו</div>
      </div>
    </div>

    {pays.length > 0 && <table style={{ marginTop: '2%', width: '70%' }}>
      <thead>
        <tr style={{ textAlign: 'center' }}>
          <th style={{ width: '30%' }}>סוג ההוצאה</th>
          <th style={{ width: '25%' }}>טווח מחירים מקובל בשוק</th>
          <th style={{ width: '25%' }}>ממוצע מחיר מקובל בשוק</th>
          <th style={{ width: '20%' }}>סכום ההוצאה שלי</th>

        </tr>
      </thead>
      {pays && <tbody>
        {pays.map((x, i) => (
          <tr key={i} style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
            <td>{x.name}</td>
            <td style={{ direction: 'ltr' }}>{x.priceRange}</td>
            {x.adventagePrice && <td>{x.adventagePrice} ש"ח</td>}
            {!x.adventagePrice && <td></td>}
            {x.pricePaid > 0 && <td>{x.pricePaid} ש"ח</td>}
            {!(x.pricePaid > 0) && <td><div className='divButton' onClick={() => s.id ? setOpenGlobal({ x, text: `מהו סכום ההוצאה שלכם עבור ${x.name}?`, input: <input className='inp' onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'}></input> }) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' })}>מהו סכום הוצאתכם?</div></td>}
            {!x.priceRange && <td><IconButton onClick={() => setDel(x.id)} className='IconButton' size="small">
              <CloseOutlined />
            </IconButton></td>}
            {x.priceRange && x.pricePaid > 0 && <td><IconButton onClick={() => setOpenGlobal({ update: true, x, text: `מהו סכום ההוצאה שלכם עבור ${x.name}?`, input: <input className='inp' onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'}></input> })} className='IconButton' size="small">
              <EditOutlined />
            </IconButton></td>}
            {x.priceRange && !(x.pricePaid) > 0 && <td></td>}
          </tr>
        ))}

        <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>

          <td></td>
          <td></td>
          <td><div style={{ fontWeight: '400' }}>סה"כ: </div>{sumG} ש"ח</td>
          <td style={{ color: `${sumP < sumG ? 'black' : '#c00000'}` }}><div style={{ fontWeight: '400' }}>סה"כ: </div>{sumP} ש"ח</td>
        </tr>
      </tbody>}
    </table>}
    {pays.length > 0 && <div onClick={() => s.id ? setOpen(true) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' })} className='buton' style={{ marginTop: '0', float: 'left', marginLeft: '10%', width: '13%' }}><ButtonRed text='הוספת הוצאה אישית'></ButtonRed></div>}

    {tempPays.length <= 0 && <div style={{ width: '40%', marginTop: '10vh', marginRight: '30%', position: 'center' }}>
      <div className='buton1' style={{ width: '49%', marginTop: '2%', display: 'inline-block' }} onClick={() => { GetPaysInit(0).then((data) => { setPays(data); setTempPays(data); var xnum = 0; data.forEach(element => { xnum += element.adventagePrice }); setsumG(xnum); window.scrollTo(0, 0); }).catch((error) => { console.error(error); }); }}><ButtonRed text='למשפחת החתן'></ButtonRed></div>
      <div className='buton1' style={{ width: '49%', marginTop: '2%', marginRight: '2%', display: 'inline-block' }} onClick={() => { GetPaysInit(1).then((data) => { setPays(data); setTempPays(data); var xnum = 0; data.forEach(element => { xnum += element.adventagePrice }); setsumG(xnum); window.scrollTo(0, 0); }).catch((error) => { console.error(error); }); }}><ButtonRed text='למשפחת הכלה'></ButtonRed></div>
    </div>}


    {open && <AlertAddPersonalPay set={setOpen} setAllPays={setAllPays}></AlertAddPersonalPay>}
    {del && <AlertDelete set={setDel} func={delPay} text={'הוצאה אישית'} id={del}></AlertDelete>}
    {openGlobal && <div id='modal2'>
      <div className='animate__animated animate__zoomIn'>
        <CloseOutlined className='close2' onClick={() => setOpenGlobal(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
        <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
          <div className='titl' style={{ marginTop: '4%', fontSize: '120%', marginBottom: '5%' }}>{openGlobal.text}</div>
          <div style={{ marginTop: '-2%' }}>{openGlobal.text2}</div>
          {openGlobal.input && <div>
            <div>{openGlobal.input}</div>
            <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => isUpdatePaid()}><ButtonRed text='אישור'></ButtonRed></div>
          </div>}
          {!openGlobal.input && <div>
            <div style={{ width: '10%', display: 'inline-block', marginTop: '4%' }} onClick={() => { setLogin(true); setOpenGlobal(false) }}><ButtonRed text='התחבר'></ButtonRed></div>
            <div style={{ width: '10%', display: 'inline-block' }} onClick={() => { setConnection(true); setOpenGlobal(false) }}><ButtonRed text='הירשם'></ButtonRed></div>
          </div>}
        </div>
      </div>
    </div>}

    {login && <Login setLogin={setLogin}></Login>}
    {connection && <Connection set={setConnection}></Connection>}

    {/* <AlertGlobal text2={openGlobal.text2} func={addPaid} set={setOpenGlobal} text={openGlobal.text} more={openGlobal.input}></AlertGlobal> */}

    {alert && <Alert set={setAlert} sendGood={sendGood} text={"קול שמחה"} mail={"r0527117663@gmail.com"}></Alert>}

    {send && <div className="send animate__animated animate__bounceInUp">
      <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send}</div>
    </div>}

    {tempPays.length <= 0 && <div style={{ width: '100%', height: '800px' }}></div>}



    <BottomMenu></BottomMenu>
  </div>
}

export default HowManyToPay