// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div555 {
    transition: transform 6s;
    /* background-color: black; */
    background-color: rgb(98, 96, 96);
}

.App:hover .div555 {
    /* background-color: #cccbcb; */
    transform: translateY(-100%);
}`, "",{"version":3,"sources":["webpack://./src/components/global/Wait.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,6BAA6B;IAC7B,iCAAiC;AACrC;;AAEA;IACI,+BAA+B;IAC/B,4BAA4B;AAChC","sourcesContent":[".div555 {\r\n    transition: transform 6s;\r\n    /* background-color: black; */\r\n    background-color: rgb(98, 96, 96);\r\n}\r\n\r\n.App:hover .div555 {\r\n    /* background-color: #cccbcb; */\r\n    transform: translateY(-100%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
